import React from "react";
import RouteURLs from "../../../actions/routesURL";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import "./ServiceListComponentStyle.scss";
import {Link} from "react-router-dom";
import {Growl} from "primereact/growl";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {connect} from "react-redux";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {
    IService,
    DeliveryService,
    ServiceType,
    LuggageService,
    StorageService,
    OtherService,
    IMonthStorageCost,
    ServicePaymentType,
    requestServices,
    deleteServiceRequest,
    requestDestinations,
    AuthorizedRequestData,
    ICountableResponse,
    CountableResponse,
    requestDirections,
    Destination,
    IDestination,
    Direction,
    IDirection, Bind
} from "@shift-mono/common";

interface IServiceListComponentProps {
    getToken: () => Promise<string>;
}

interface IServiceListComponentState {
    delete_dialog_visible: boolean;
    deleting_service_id: string | undefined;
    token: string | undefined;
    services: IService[];
    current_service_type: IServiceTypeDropdownItem;
    available_service_types: IServiceTypeDropdownItem[];
    available_delivery_direction: IDirection[];
    available_destinations: IDestination[];
}

interface IServiceTypeDropdownItem {
    title: string;
    type: ServiceType;
}

class ServiceListComponent extends React.Component<IServiceListComponentProps,
    IServiceListComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IServiceListComponentProps) {
        super(props);
        this.state = {
            delete_dialog_visible: false,
            deleting_service_id: undefined,
            token: undefined,
            services: [],
            current_service_type: {title: "Прочее", type: ServiceType.Other},
            available_service_types: [
                {title: "Перемещение багажа", type: ServiceType.Luggage},
                {
                    title: "Перемещение горнолыжного снаряжения",
                    type: ServiceType.Move
                },
                {title: "Хранение", type: ServiceType.Store},
                {title: "Прочее", type: ServiceType.Other}
            ],
            available_delivery_direction: [],
            available_destinations: []
        };
    }
    @Bind()
    async contentRequest() {
        const token = await this.props.getToken();
        this.setState({token: token});
        const request_data = new AuthorizedRequestData(token);
        try {
            return (await requestServices(request_data)).getData();
        } catch (err) {
            return [];
        }
    }
    @Bind()
    requestResult(result: IService[]) {
        this.setState({services: result});
    }
    @Bind()
    async getDirectionsRequest(): Promise<ICountableResponse<IDirection[]>> {
        const token = await this.props.getToken();
        try {
            const request_data = new AuthorizedRequestData(token);
            return await requestDirections(request_data);
        } catch (err) {
            return new CountableResponse<Direction[]>([], 0);
        }
    }
    @Bind()
    directionRequestResult(result: ICountableResponse<IDirection[]>) {
        this.setState({available_delivery_direction: result.getData()});
    }
    @Bind()
    async getDestinationRequest(): Promise<ICountableResponse<IDestination[]>> {
        const token = await this.props.getToken();
        try {
            const request_data = new AuthorizedRequestData(token);
            return await requestDestinations(request_data);
        } catch (err) {
            return new CountableResponse<Destination[]>([], 0);
        }
    }
    @Bind()
    destinationRequestResult(destinations: ICountableResponse<IDestination[]>) {
        this.setState({available_destinations: destinations.getData()});
    }
    @Bind()
    async deleteServiceRequest(service_id: string) {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);

        try {
            const resultStatus = await deleteServiceRequest(service_id, request_data);
            if (resultStatus) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Услуга удалена"
                });
                if (this.loaderRef !== undefined) {
                    this.loaderRef.tryToLoading();
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка удаления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка удаления"
            });
        }
    }
    @Bind()
    showDeleteDialog(service_id: string) {
        this.setState({
            delete_dialog_visible: true,
            deleting_service_id: service_id
        });
    }
    @Bind()
    hideDeleteDialog() {
        this.setState({delete_dialog_visible: false});
    }

    deleteDialogFooterTemplate = (
        //TODO Обновлять список сервисов после удаления
        <div>
            <Button
                label="Да"
                icon="pi pi-check"
                onClick={async () => {
                    if (this.state.deleting_service_id) {
                        await this.deleteServiceRequest(this.state.deleting_service_id);
                        this.setState({deleting_service_id: undefined});
                    } else {
                        this.growl.show({
                            severity: "error",
                            summary: "Ошибка",
                            detail: "Ошибка удаления"
                        });
                    }

                    this.hideDeleteDialog();
                }}
            />
            <Button
                label="Нет"
                icon="pi pi-times"
                onClick={() => this.hideDeleteDialog()}
            />
        </div>
    );

    getServicesByType(type: ServiceType) {
        let result: IService[] = [];

        this.state.services.map((service: IService) => {
            switch (type) {
                case ServiceType.Luggage:
                    if (service instanceof LuggageService) {
                        result.push(service);
                    }
                    break;
                case ServiceType.Move:
                    if (service instanceof DeliveryService) {
                        result.push(service);
                    }
                    break;
                case ServiceType.Store:
                    if (service instanceof StorageService) {
                        result.push(service);
                    }
                    break;
                case ServiceType.Other:
                    if (service instanceof OtherService) {
                        result.push(service);
                    }
                    break;
            }
            return service;
        });

        return result;
    }
    @Bind()
    getDirectionById(id: string): IDirection | undefined {
        const result = this.state.available_delivery_direction.filter(
            (item: IDirection) => {
                return item.getId() === id;
            }
        );

        return result.length > 0 ? result[0] : undefined;
    }
    @Bind()
    getDirectionTitle(direction: IDirection): string {
        const firstDestinationId = direction.getVertices()[0]
            ? direction.getVertices()[0]
            : "";
        const secondDestinationId = direction.getVertices()[1]
            ? direction.getVertices()[1]
            : "";

        if (this.state.available_destinations) {
            const firstDestination = this.state.available_destinations.filter(
                (item: IDestination) => {
                    return item.getId() === firstDestinationId;
                }
            )[0];
            const secondDestination = this.state.available_destinations.filter(
                (item: IDestination) => {
                    return item.getId() === secondDestinationId;
                }
            )[0];

            let firstDestinationTitle = firstDestinationId;
            let secondDestinationTitle = secondDestinationId;

            if (firstDestination) {
                firstDestinationTitle = firstDestination.getTitle();
            }
            if (secondDestination) {
                secondDestinationTitle = secondDestination.getTitle();
            }

            return firstDestinationTitle + " : " + secondDestinationTitle;
        } else {
            return firstDestinationId + " : " + secondDestinationId;
        }
    }
    @Bind()
    serviceTypeChanged(e: any) {
        this.setState({current_service_type: e.value});
    }

    monthStorageCellTemplate(rowData: StorageService, column: any) {
        const cells = rowData
            .getMonthStorageCost()
            .map((item: IMonthStorageCost) => {
                return (
                    <tr key={item.getMonthCount()}>
                        <td>{item.getMonthCount()}</td>
                        <td>{item.getCost()}</td>
                    </tr>
                );
            });
        return (
            <table>
                <tbody className="p-datatable-tbody">{cells}</tbody>
            </table>
        );
    }
    @Bind()
    directionCellTemplate(
        rowData: DeliveryService | LuggageService
    ) {
        return (
            rowData.getDirectionCost().length
        );
    }

    paymentTypeCellTemplate(rowData: IService, column: any) {
        let value = "Нет данных";
        switch (rowData.getPaymentType()) {
            case ServicePaymentType.OneTime:
                value = "Разовая";
                break;
            case ServicePaymentType.Monthly:
                value = "Помесячная";
                break;
        }
        return value;
    }
    @Bind()
    actionTemplate(rowData: IService, column: any) {
        return (
            <>
                <Link to={RouteURLs.services + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="p-button-info mr-3"
                    />
                </Link>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.showDeleteDialog(rowData.getId());
                    }}
                />
            </>
        );
    }

    render() {
        let servicesTableComponent;

        switch (this.state.current_service_type.type) {
            case ServiceType.Other:
                servicesTableComponent = (
                    <DataTable
                        className="main_list_table"
                        value={this.getServicesByType(ServiceType.Other)}
                    >
                        <Column field="name" header="Наименование услуги"/>
                        <Column
                            field="paymentType"
                            body={this.paymentTypeCellTemplate}
                            header="Тип оплаты"
                        />
                        <Column field="cost" header="Цена"/>
                        <Column body={this.actionTemplate} style={{width: "8em"}}/>
                    </DataTable>
                );
                break;
            case ServiceType.Move:
                servicesTableComponent = (
                    <DataTable
                        className="main_list_table"
                        value={this.getServicesByType(ServiceType.Move)}
                    >
                        <Column field="name" header="Наименование услуги"/>
                        <Column
                            field="paymentType"
                            body={this.paymentTypeCellTemplate}
                            header="Тип оплаты"
                        />
                        <Column
                            field="cost"
                            body={this.directionCellTemplate}
                            header="Кол-во направлений"
                        />
                        <Column body={this.actionTemplate} style={{width: "8em"}}/>
                    </DataTable>
                );
                break;
            case ServiceType.Luggage:
                servicesTableComponent = (
                    <DataTable
                        className="main_list_table"
                        value={this.getServicesByType(ServiceType.Luggage)}
                    >
                        <Column field="name" header="Наименование услуги"/>
                        <Column
                            field="paymentType"
                            body={this.paymentTypeCellTemplate}
                            header="Тип оплаты"
                        />
                        <Column
                            field="cost"
                            body={this.directionCellTemplate}
                            header="Кол-во направлений"
                        />
                        <Column body={this.actionTemplate} style={{width: "8em"}}/>
                    </DataTable>
                );
                break;
            case ServiceType.Store:
                servicesTableComponent = (
                    <DataTable
                        className="main_list_table"
                        value={this.getServicesByType(ServiceType.Store)}
                    >
                        <Column field="name" header="Наименование услуги"/>
                        <Column
                            field="paymentType"
                            body={this.paymentTypeCellTemplate}
                            header="Тип оплаты"
                        />
                        <Column
                            field="monthCount"
                            body={this.monthStorageCellTemplate}
                            header="Кол-во месяцев / Цена"
                        />
                        <Column body={this.actionTemplate} style={{width: "8em"}}/>
                    </DataTable>
                );
                break;
        }

        return (
            <div className="service-list">
                <div className="row">
                    <ContentLoaderComponent<ICountableResponse<IDestination[]>>
                        contentRequest={this.getDestinationRequest}
                        resultCallback={this.destinationRequestResult}
                    >
                        <ContentLoaderComponent<ICountableResponse<IDirection[]>>
                            contentRequest={this.getDirectionsRequest}
                            resultCallback={this.directionRequestResult}
                            errorCallback={err => console.log(err)}
                        >
                            <ContentLoaderComponent<IService[]>
                                contentRequest={this.contentRequest}
                                resultCallback={this.requestResult}
                                ref={el => {
                                    this.loaderRef = el;
                                }}
                            >
                                <Growl ref={el => (this.growl = el)}/>
                                <Dialog
                                    header="Внимание!"
                                    footer={this.deleteDialogFooterTemplate}
                                    visible={this.state.delete_dialog_visible}
                                    style={{width: "50vw"}}
                                    modal={true}
                                    onHide={() => {
                                        this.hideDeleteDialog();
                                    }}
                                >
                                    Вы уверенны, что хотите удалить услугу?
                                </Dialog>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h3>Список услуг</h3>
                                        </div>
                                    </div>
                                    <div className="row m-1">
                                        <label className="col-3">Тип услуги</label>
                                        <Dropdown
                                            value={this.state.current_service_type}
                                            options={this.state.available_service_types}
                                            onChange={this.serviceTypeChanged}
                                            placeholder="Тип услуги"
                                            optionLabel="title"
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">{servicesTableComponent}</div>
                                    </div>
                                </div>
                            </ContentLoaderComponent>
                        </ContentLoaderComponent>
                    </ContentLoaderComponent>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(ServiceListComponent);

"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserRequestData = void 0;

var AuthorizedRequestData_1 = require("./AuthorizedRequestData");

var UserRequestData =
/** @class */
function (_super) {
  __extends(UserRequestData, _super);

  function UserRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;

    _this.arguments = args;
    return _this;
  }

  UserRequestData.prototype.getData = function () {
    var result = Object.assign({
      active: true
    }, _super.prototype.getData.call(this));

    if (this.arguments.isAgent !== undefined) {
      result = Object.assign({}, result, {
        isAgent: this.arguments.isAgent
      });
    }

    if (this.arguments.partnerId) {
      result = Object.assign({}, result, {
        partnerId: this.arguments.partnerId
      });
    }

    if (this.arguments.isB2bAccount) {
      result = Object.assign({}, result, {
        claims: [{
          name: "role",
          value: "b2b"
        }]
      });
    }

    if (this.arguments.login) {
      result = Object.assign({}, result, {
        userName: this.arguments.login,
        displayName: this.arguments.login
      });
    }

    if (this.arguments.phoneNumber) {
      result = Object.assign({}, result, {
        phoneNumber: this.arguments.phoneNumber
      });
    }

    if (this.arguments.email) {
      result = Object.assign({}, result, {
        email: this.arguments.email
      });
    }

    if (this.arguments.firstName) {
      result = Object.assign({}, result, {
        firstName: this.arguments.firstName
      });
    }

    if (this.arguments.secondName) {
      result = Object.assign({}, result, {
        secondName: this.arguments.secondName
      });
    }

    if (this.arguments.thirdName) {
      result = Object.assign({}, result, {
        thirdName: this.arguments.thirdName
      });
    }

    if (this.arguments.id) {
      result = Object.assign({}, result, {
        id: this.arguments.id
      });
    }

    return result;
  };

  UserRequestData.prototype.getId = function () {
    return this.arguments.id;
  };

  UserRequestData.prototype.getLogin = function () {
    return this.arguments.login;
  };

  UserRequestData.prototype.isB2BAccount = function () {
    return this.arguments.isB2bAccount;
  };

  return UserRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);

exports.UserRequestData = UserRequestData;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Bind = void 0;

function Bind() {
  return function (target, propertyKey, descriptor) {
    if (!descriptor || typeof descriptor.value !== 'function') {
      return;
    }

    return Object.defineProperty(target, propertyKey, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      get: function get() {
        return descriptor.value.bind(this);
      }
    });
  };
}

exports.Bind = Bind;
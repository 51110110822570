import React from "react";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import RouteURLs from "../../../actions/routesURL";
import "./DirectionListComponentStyle.scss"
import {connect} from "react-redux";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Growl} from "primereact/growl";
import {Dialog} from "primereact/dialog";
import {Link} from "react-router-dom";
import {
    Direction,
    IDirection,
    AuthorizedRequestData,
    requestDestinationById,
    ICountableResponse,
    CountableResponse,
    requestDirections,
    deleteDirectionRequest,
    IDestination, Logger, Bind
} from "@shift-mono/common";

interface IDirectionListComponentProps {
    getToken: () => Promise<string>;
    ref: (item: any) => any;
    filterParams?: IListFilterParams
}

interface IDirectionListComponentState {
    directions: IDirection[];
    directionsCount: number;
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
    destinations: IDestination[];
    delete_dialog_visible: boolean;
    deleting_direction_id: string | undefined;
}

export interface IListFilterParams {
    firstDestinationId: string | undefined;
    secondDestinationId: string | undefined;
}

class DirectionListComponent extends React.Component<IDirectionListComponentProps,
    IDirectionListComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IDirectionListComponentProps) {
        super(props);

        this.state = {
            directions: [],
            directionsCount: 0,
            paginationListRows: 20,
            firstPaginationIndex: 0,
            loading: true,
            destinations: [],
            delete_dialog_visible: false,
            deleting_direction_id: undefined
        };
    }

    composeFilterRequestParams() {
        let result = {};

        if (this.props.filterParams) {
            if (this.props.filterParams.firstDestinationId && this.props.filterParams.secondDestinationId) {
                const query = `{"vertices.0": "${this.props.filterParams.firstDestinationId}", "vertices.1": "${this.props.filterParams.secondDestinationId}"}`;
                result = Object.assign({}, result, {query});
            } else if (this.props.filterParams.firstDestinationId) {
                const query = `{"vertices.0": "${this.props.filterParams.firstDestinationId}"}`;
                result = Object.assign({}, result, {query});
            } else if (this.props.filterParams.secondDestinationId) {
                const query = `{"vertices.1": "${this.props.filterParams.secondDestinationId}"}`;
                result = Object.assign({}, result, {query});
            }
        }

        return result;
    }
    @Bind()
    async contentRequest(): Promise<ICountableResponse<IDirection[]>> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token, {}, this.composeFilterRequestParams());
        try {
            const directionsResponse = await requestDirections(requestData);
            const directions = directionsResponse.getData();
            const destinationIdsSet = this.getDestinationIdsFrom(directions);
            const destinations = await this.getDestinations(destinationIdsSet);
            this.setState({destinations: destinations});
            return directionsResponse
        } catch (err) {
            return new CountableResponse<Direction[]>([], 0);
        }
    }
    @Bind()
    requestResult(directions: ICountableResponse<IDirection[]>) {
        this.setState({
            directions: directions.getData(),
            loading: false,
            directionsCount: directions.getObjectsCount()
        });
    }

    getDeliveryDurationPostfix(duration: number): string{
        if(duration === 1){
            return "день";
        }
        if(duration > 1 && duration < 5){
            return "дня";
        }
        return "дней";
    }
    @Bind()
    verticesTemplate(direction: IDirection) {
        const vertices = direction.getVertices();
        const firstVertId = vertices[0] ? vertices[0] : "";
        const secondVertId = vertices[1] ? vertices[1] : "";

        const getTitle = (id: string, destinations: IDestination[]) => {
            const foundedDestination = destinations.find(
                (item: IDestination) => {
                    return item.getId() === id;
                }
            );

            return foundedDestination ? foundedDestination.getTitle() : "";
        };

        let directionName = direction.getName();
        let firstVertTitle = getTitle(firstVertId, this.state.destinations);
        let secondVertTitle = getTitle(secondVertId, this.state.destinations);
        let deliveryDuration = direction.getDeliveryDuration();

        return (
            <span className={"direction-list-info"}>
                <span className={"direction-list-info__name"}>
                    <span className={"direction-list-info__title"}>Имя: </span> {directionName}
                </span>
                <span className={"direction-list-info__vertices-item"}>
                    <span className={"direction-list-info__title"}>Город откуда: </span> {firstVertTitle}
                </span>
                <span className={"direction-list-info__vertices-item"}>
                    <span className={"direction-list-info__title"}>Город куда: </span> {secondVertTitle}
                </span>
                <span className={"direction-list-info__vertices-item"}>
                    <span className={"direction-list-info__title"}>Время доставки: </span> {deliveryDuration} {this.getDeliveryDurationPostfix(deliveryDuration)}
                </span>
            </span>
        );
    }
    @Bind()
    actionTemplate(rowData: Direction, column: any) {
        return (
            <>
                <Link to={RouteURLs.destinations + RouteURLs.directions + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="p-button-info mr-3"
                    />
                </Link>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.showDeleteDialog(rowData.getId());
                    }}
                />
            </>
        );
    }
    @Bind()
    showDeleteDialog(direction_id: string) {
        this.setState({
            delete_dialog_visible: true,
            deleting_direction_id: direction_id
        });
    }
    @Bind()
    hideDeleteDialog() {
        this.setState({delete_dialog_visible: false});
    }
    @Bind()
    async deleteDirectionRequest(direction_id: string) {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);

        try {
            const resultStatus = await deleteDirectionRequest(direction_id, request_data);
            if (resultStatus) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Направление удалено"
                });
                this.reloadContent();
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка удаления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка удаления"
            });
        }
    }

    deleteDialogFooterTemplate = (
        <div>
            <Button
                label="Да"
                icon="pi pi-check"
                onClick={async () => {
                    if (this.state.deleting_direction_id) {
                        await this.deleteDirectionRequest(this.state.deleting_direction_id);
                        this.setState({deleting_direction_id: undefined});
                    } else {
                        this.growl.show({
                            severity: "error",
                            summary: "Ошибка",
                            detail: "Ошибка удаления"
                        });
                    }

                    this.hideDeleteDialog();
                }}
            />
            <Button
                label="Нет"
                icon="pi pi-times"
                onClick={() => this.hideDeleteDialog()}
            />
        </div>
    );
    @Bind()
    reloadContent() {
        if (this.loaderRef !== undefined) {
            this.loaderRef.tryToLoading();
        }
    }
    @Bind()
    getDestinationIdsFrom(directions: IDirection[]) {
        const destinationIdsSet = new Set<string>();
        for (let direction of directions) {
            const firstId = direction.getVertices()[0] ? direction.getVertices()[0] : undefined;
            const secondId = direction.getVertices()[1] ? direction.getVertices()[1] : undefined;
            if (firstId) {
                destinationIdsSet.add(firstId);
            }
            if (secondId) {
                destinationIdsSet.add(secondId);
            }
        }
        return destinationIdsSet;
    }
    @Bind()
    async getDestinations(ids: Set<string>): Promise<IDestination[]> {
        const token = await this.props.getToken();
        const authRequestData = new AuthorizedRequestData(token);

        const destRequests = Array.from(ids).map((id) => {
            return new Promise<IDestination | undefined>((resolve) => {
                try {
                    requestDestinationById(id, authRequestData).then(destination => {
                        resolve(destination.getData())
                    }).catch(() => {
                        resolve(undefined);
                    });
                } catch (e) {
                    resolve(undefined);
                }
            })
            // return getDestination(id, authRequestData);
        });
        return (await Promise.all(destRequests)).reduce((dests: IDestination[], curDestination: (IDestination | undefined)) => {
            if (curDestination) {
                dests.push(curDestination)
            }
            return dests;
        }, []);
    }
    @Bind()
    async onPage(event: any) {
        this.setState({
            loading: true
        });

        const startIndex = event.first;
        const endIndex = this.state.paginationListRows;

        const token = await this.props.getToken();
        let params = {
            skip: startIndex,
            limit: endIndex
        };
        params = Object.assign({}, params, this.composeFilterRequestParams());
        Logger.d(params);
        const request_data = new AuthorizedRequestData(token, {}, params);
        try {
            const directionsResponse = await requestDirections(request_data);
            const directions = directionsResponse.getData();
            const destinationIdsSet = this.getDestinationIdsFrom(directions);
            const destinations = await this.getDestinations(destinationIdsSet);

            this.setState({
                firstPaginationIndex: startIndex,
                directions: directions,
                loading: false,
                directionsCount: directionsResponse.getObjectsCount(),
                destinations: destinations
            })
        } catch (err) {
            return new CountableResponse<Direction[]>([], 0);
        }
    }

    render() {
        return (
            <>
                <Growl
                    ref={el => {
                        this.growl = el;
                    }}
                />
                <Dialog
                    header="Внимание!"
                    footer={this.deleteDialogFooterTemplate}
                    visible={this.state.delete_dialog_visible}
                    style={{width: "50vw"}}
                    modal={true}
                    onHide={() => {
                        this.hideDeleteDialog();
                    }}
                >
                    Вы уверенны, что хотите удалить направление?
                </Dialog>
                <ContentLoaderComponent<ICountableResponse<IDirection[]>>
                    contentRequest={this.contentRequest}
                    resultCallback={this.requestResult}
                    ref={el => {
                        this.loaderRef = el;
                    }}
                >
                    <DataTable
                        className="main_list_table mt-2 mb-3"
                        value={this.state.directions}
                        paginator={true}
                        totalRecords={this.state.directionsCount}
                        rows={this.state.paginationListRows}
                        first={this.state.firstPaginationIndex}
                        lazy={true}
                        loading={this.state.loading}
                        onPage={this.onPage}
                    >
                        <Column body={this.verticesTemplate} header="Места назначения"/>
                        <Column body={this.actionTemplate} style={{width: "8em"}}/>
                    </DataTable>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
    DirectionListComponent
);

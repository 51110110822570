import React from "react";
import NotificationListComponent from "./NotificationListComponent";

interface INotificationComponentProps {
}

interface INotificationComponentState {
}

export default class NotificationComponent extends React.Component<INotificationComponentProps, INotificationComponentState> {
    render() {
        return <NotificationListComponent/>
    }
}
import React from "react";
import {Bind, IPartner} from "@shift-mono/common";
import {Dropdown} from "primereact/dropdown";

interface IPartnerPickerProps extends React.HTMLAttributes<HTMLDivElement> {
    availablePartners: IPartner[],
    changeHandler: (selectedPartner: IPartner | undefined) => void;
    currentPartnerId?: string;
}

interface IPartnerPickerState {
}

interface DropdownItem {
    title: string,
    value: IPartner | undefined,
    id: string,
}

export default class PartnerPicker extends React.Component<IPartnerPickerProps, IPartnerPickerState> {

    @Bind()
    getOptions(): DropdownItem[] {
        return [...this.props.availablePartners, undefined]
            .map((item) => {
                return this.getOptionItemFor(item)
            })
    }

    getOptionItemFor(partner: IPartner | undefined) {
        return partner
            ? {title: partner.getTitle(), value: partner, id: partner.getId()}
            : {title: "Нет партнера", value: partner, id: "-1"}
    }

    render() {
        const currentPartner = this.props.availablePartners
            .filter((partner) => {
                return partner.getId() === this.props.currentPartnerId
            })
            .pop()

        const current = this.getOptionItemFor(currentPartner);

        return <Dropdown
            className={this.props.className ? this.props.className : ""}
            optionLabel={"title"}
            value={current}
            options={this.getOptions()}
            onChange={(e) => {
                this.props.changeHandler(e.value.value);
            }}
            dataKey={"id"}
            placeholder="Выберите партнера"/>
    }
}
import C from "../actionsTypes";
import {NotificationStatus} from "@shift-mono/common";

export const addNotification = (data: any) => {
    return {
        type: C.NOTIFICATION.ADD,
        data
    }
};

export const changeNotificationStatus = (id: string, status: NotificationStatus) => {
  return {
      type: C.NOTIFICATION.CHANGE_STATUS,
      id,
      status,
  }
};

export const removeNotification = (id: string) => {
    return {
        type: C.NOTIFICATION.REMOVE,
        id
    }
};

export const removeAllNotification = () => {
    return {
        type: C.NOTIFICATION.REMOVE_ALL
    }
};
"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deletePartnerRequest = exports.putPartnerRequest = exports.postPartnerRequest = exports.requestPartnerById = exports.requestPartners = void 0;

var common_1 = require("../common");

var request_1 = require("./request");

var models_1 = require("../../models");

var response_1 = require("../response");

var data_1 = require("../data");

var common_2 = require("../../common");

var errors_1 = require("../../errors");

exports.requestPartners = function (requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, updatedRequestData, result, requestResult, objectsCount, partnerObjects, partners, err_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = common_1.getApiDomain() + "/v1.0/partner";
          method = request_1.RequestMethods.GET;
          updatedRequestData = requestData;

          if (!updatedRequestData.getParams().hasOwnProperty("limit")) {
            updatedRequestData = new data_1.AuthorizedRequestData(requestData.getAccessToken(), requestData.getHeaders(), Object.assign({}, requestData.getParams(), {
              limit: 0
            }), requestData.getData(), requestData.getAxiosOptions());
          }

          result = new response_1.CountableResponse([], 0);
          _a.label = 1;

        case 1:
          _a.trys.push([1, 3,, 4]);

          return [4
          /*yield*/
          , request_1.request(url, method, updatedRequestData)];

        case 2:
          requestResult = _a.sent();

          if (requestResult.data) {
            objectsCount = requestResult.headers["x-objects-count"] ? parseInt(requestResult.headers["x-objects-count"]) : 0;
            partnerObjects = requestResult.data;
            partners = partnerObjects.reduce(function (partners, item) {
              try {
                var partner = models_1.Partner.fromJson(item);
                partners.push(partner);
              } catch (err) {
                common_2.Logger.e(err);

                if (!(err instanceof errors_1.MissingPropertiesError)) {
                  throw err;
                }
              }

              return partners;
            }, []);
            result = new response_1.CountableResponse(partners, objectsCount);
          }

          return [3
          /*break*/
          , 4];

        case 3:
          err_1 = _a.sent();
          common_2.Logger.e(err_1.message);

          if (!(err_1 instanceof errors_1.MissingPropertiesError)) {
            throw err_1;
          }

          return [3
          /*break*/
          , 4];

        case 4:
          return [2
          /*return*/
          , result];
      }
    });
  });
};

exports.requestPartnerById = function (partnerId, requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, result, partner, err_2;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = common_1.getApiDomain() + "/v1.0/partner/" + partnerId;
          method = request_1.RequestMethods.GET;
          _a.label = 1;

        case 1:
          _a.trys.push([1, 3,, 4]);

          return [4
          /*yield*/
          , request_1.request(url, method, requestData)];

        case 2:
          result = _a.sent();

          if (result.data) {
            partner = models_1.Partner.fromJson(result.data);
            return [2
            /*return*/
            , new response_1.DataResponse(partner)];
          }

          return [2
          /*return*/
          , new response_1.DataResponse(undefined)];

        case 3:
          err_2 = _a.sent();
          throw err_2;

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  });
};

exports.postPartnerRequest = function (requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, err_3;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = common_1.getApiDomain() + "/v1.0/partner/";
          method = request_1.RequestMethods.POST;
          _a.label = 1;

        case 1:
          _a.trys.push([1, 3,, 4]);

          return [4
          /*yield*/
          , request_1.request(url, method, requestData)];

        case 2:
          _a.sent();

          return [2
          /*return*/
          , true];

        case 3:
          err_3 = _a.sent();
          common_2.Logger.d(err_3);
          return [2
          /*return*/
          , false];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  });
};

exports.putPartnerRequest = function (requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, err_4;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!requestData.getId()) {
            common_2.Logger.d("Partner id must not be undefined");
            return [2
            /*return*/
            , false];
          }

          url = common_1.getApiDomain() + "/v1.0/partner/" + requestData.getId();
          method = request_1.RequestMethods.PUT;
          _a.label = 1;

        case 1:
          _a.trys.push([1, 3,, 4]);

          return [4
          /*yield*/
          , request_1.request(url, method, requestData)];

        case 2:
          _a.sent();

          return [2
          /*return*/
          , true];

        case 3:
          err_4 = _a.sent();
          common_2.Logger.d(err_4);
          return [2
          /*return*/
          , false];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  });
};

exports.deletePartnerRequest = function (partnerId, requestData) {
  return __awaiter(void 0, void 0, void 0, function () {
    var url, method, err_5;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          url = common_1.getApiDomain() + "/v1.0/partner/" + partnerId;
          method = request_1.RequestMethods.DELETE;
          _a.label = 1;

        case 1:
          _a.trys.push([1, 3,, 4]);

          return [4
          /*yield*/
          , request_1.request(url, method, requestData)];

        case 2:
          _a.sent();

          return [2
          /*return*/
          , true];

        case 3:
          err_5 = _a.sent();
          common_2.Logger.d(err_5);
          return [2
          /*return*/
          , false];

        case 4:
          return [2
          /*return*/
          ];
      }
    });
  });
};
import React from "react";
import { connect } from "react-redux";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import { Growl } from "primereact/growl";
import ServiceFormComponent, {
  IServiceFormData
} from "./service_form_parts/ServiceFormComponent";
import {
  Bind,
  postServiceRequest,
  ServiceRequestData,
  ServiceType
} from "@shift-mono/common";

interface IAddServiceFormComponentProps {
  getToken: () => Promise<string>;
}
interface IAddServiceFormComponentState {}

class AddServiceFormComponent extends React.Component<
  IAddServiceFormComponentProps,
  IAddServiceFormComponentState
> {
  private growl: any = undefined;
  private serviceFormRef: any = undefined;

  @Bind()
  async sendService(data: IServiceFormData) {
    const token = await this.props.getToken();

    const name = data.serviceName;
    const localizedName = data.localizedName;
    const currency = data.currency;
    const payment_type = data.paymentType;
    const service_type = data.serviceType;
    const cost = data.cost;
    const cost_per_direction_list = data.costPerDirectionList;
    const cost_per_storage_months = data.costPerStorageMonths;
    const showInCarousel = data.showInCarousel;
    const discountApplicable = data.discountApplicable;

    let service_added = false;

    if (
      name.trim() === "" ||
      payment_type === null ||
      service_type === null ||
      (service_type === ServiceType.Other && cost <= 0) ||
      ((service_type === ServiceType.Move ||
        service_type === ServiceType.Luggage) &&
        cost_per_direction_list.length <= 0) ||
      (service_type === ServiceType.Store &&
        cost_per_storage_months.length <= 0)
    ) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не все поля заполнены"
      });
      return false;
    }
    if (payment_type) {
      const request_data = new ServiceRequestData({
            accessToken: token,
            serviceType: service_type,
            name: name,
            localizedName: localizedName,

            currency: currency,
            paymentType: payment_type,
            cost: cost,
            directionsCost: cost_per_direction_list,
            monthsCost: cost_per_storage_months,
            showInCarousel: showInCarousel,
            discountApplicable: discountApplicable,
          });

      service_added = await postServiceRequest(request_data);
    }

    if (service_added) {
      this.serviceFormRef.clearForm();
      this.growl.show({
        severity: "success",
        summary: "Успешно",
        detail: "Услуга добавлена"
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка добавления"
      });
    }

    return service_added;
  }

  render() {
    return (
      <>
        <Growl ref={el => (this.growl = el)} />
        <ServiceFormComponent
          ref={el => {
            this.serviceFormRef = el;
          }}
          saveCallback={form_data => {
            this.sendService(form_data);
          }}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken())
  };
};

export default connect(null, mapDispatchToProps)(AddServiceFormComponent);

import React from "react";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {
    AuthorizedRequestData, Bind,
    CountableResponse,
    IClient,
    ICountableResponse,
    IPartner,
    Logger,
    requestPartners
} from "@shift-mono/common";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {calcSkipAndLimit} from "../../../../helpers/PaginationHelpers";
import {Link} from "react-router-dom";
import RouteURLs from "../../../../actions/routesURL";
import {Button} from "primereact/button";

interface IPartnersListComponentProps {
    getToken: () => Promise<string>;
}

interface IPartnersListComponentState {
    partners: IPartner[],
    partnersCount: number,
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
}

class PartnersListComponent extends React.Component<IPartnersListComponentProps, IPartnersListComponentState> {

    constructor(props: IPartnersListComponentProps) {
        super(props);

        this.state = {
            partners: [],
            partnersCount: 0,
            paginationListRows: 20,
            firstPaginationIndex: 0,
            loading: true,
        }
    }
    @Bind()
    async getPartnersRequest(): Promise<ICountableResponse<IPartner[]>> {
        try {
            const token = await this.props.getToken();
            let currentPartnersCount = this.state.partnersCount;

            if (currentPartnersCount === 0) {
                currentPartnersCount = await this.getPartnersCount();
            }

            const startIndex = 0;
            let {skip, limit} = calcSkipAndLimit(startIndex, currentPartnersCount, this.state.paginationListRows);

            const requestData = new AuthorizedRequestData(token, {}, {
                skip: skip,
                limit: limit
    });

            return requestPartners(requestData);
        } catch (err) {
            return new CountableResponse<IPartner[]>([], 0);
        }
    }
    @Bind()
    partnersRequestResult(partners: ICountableResponse<IPartner[]>) {
        this.setState({
            partners: partners.getData(),
            loading: false,
            partnersCount: partners.getObjectsCount(),
        })
    }
    @Bind()
    async getPartnersCount(): Promise<number> {
        const token = await this.props.getToken();
        const mockRequestData = new AuthorizedRequestData(token, {}, {
            skip: 0,
            limit: 1
        });

        try {
            const partnersResponse = await requestPartners(mockRequestData);
            return partnersResponse.getObjectsCount();

        } catch (err) {
            return 0;
        }
    }
    @Bind()
    async onPage(event: any) {
        this.setState({
            loading: true
        });

        const token = await this.props.getToken();
        let currentPartnersCount = this.state.partnersCount;

        if (currentPartnersCount === 0) {
            currentPartnersCount = await this.getPartnersCount();
        }

        const startIndex = event.first;
        let {skip, limit} = calcSkipAndLimit(event.first, currentPartnersCount, this.state.paginationListRows);

        const requestData = new AuthorizedRequestData(token, {}, {
            skip: skip,
            limit: limit,
        });
        try {
            const partnersResponse = await requestPartners(requestData);
            this.setState({
                firstPaginationIndex: startIndex,
                partners: partnersResponse.getData().reverse(),
                loading: false,
                partnersCount: partnersResponse.getObjectsCount()
            })
        } catch (err) {
            return new CountableResponse<IClient[]>([], 0);
        }
    }
    actionTemplate(rowData: IPartner) {
        return (
            <>
                <Link to={RouteURLs.users + RouteURLs.partner + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-file-o"
                        className="p-button-info mr-3"
                    />
                </Link>
            </>
        );
    }

    render() {
        return (
            <ContentLoaderComponent<ICountableResponse<IPartner[]>>
                contentRequest={this.getPartnersRequest}
                resultCallback={this.partnersRequestResult}
                errorCallback={(err) => {
                    Logger.d(err);
                }}
            >
                <div className="row">
                    <div className="col-lg-12 ">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>Список партнеров</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <DataTable
                                    className="main_list_table mb-3"
                                    value={this.state.partners}
                                    paginator={true}
                                    totalRecords={this.state.partnersCount}
                                    rows={this.state.paginationListRows}
                                    first={this.state.firstPaginationIndex}
                                    lazy={true}
                                    loading={this.state.loading}
                                    onPage={this.onPage}
                                >
                                    <Column
                                        body={(client: IPartner) => {
                                            return client.getTitle();
                                        }}
                                        header="Партнер"
                                        // style={{width: "150px", textAlign: "center"}}
                                    />
                                    <Column
                                        body={(client: IPartner) => {
                                            return client.getId();
                                        }}
                                        header="Идентификатор"
                                        // style={{width: "150px", textAlign: "center"}}
                                    />
                                    <Column
                                        body={this.actionTemplate}
                                        style={{width: "4em"}}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentLoaderComponent>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(PartnersListComponent);
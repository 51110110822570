"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.News = void 0;

var Model_1 = require("./Model");

var errors_1 = require("../errors");

var News =
/** @class */
function (_super) {
  __extends(News, _super);

  function News(id, title, content, tags, imageUrl, createdOn, lastUpdatedOn) {
    var _this = _super.call(this) || this;

    _this.id = id;
    _this.title = title;
    _this.content = content;
    _this.tags = tags;
    _this.imageUrl = imageUrl;
    _this.createdOn = createdOn;
    _this.lastUpdatedOn = lastUpdatedOn;
    return _this;
  }

  News.fromJson = function (data) {
    var requiredProps = ["id"];
    var verifyResult = News.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    return new News(data.id ? data.id : "", data.title ? data.title : "", data.content ? data.content : "", data.tags ? data.tags : [], data.thumbnailImage ? data.thumbnailImage : "", data.createdOn ? new Date(data.createdOn) : new Date(), data.latestUpdatedOn ? new Date(data.latestUpdatedOn) : new Date());
  };

  News.prototype.getId = function () {
    return this.id;
  };

  News.prototype.getCreatedOn = function () {
    return this.createdOn;
  };

  News.prototype.getLatestUpdatedOn = function () {
    return this.lastUpdatedOn;
  };

  News.prototype.getTitle = function () {
    return this.title;
  };

  News.prototype.getContent = function () {
    return this.content;
  };

  News.prototype.getTags = function () {
    return this.tags;
  };

  News.prototype.getImageUrl = function () {
    return this.imageUrl;
  };

  return News;
}(Model_1.Model);

exports.News = News;
"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MobileAppSettings = void 0;

var Model_1 = require("./Model");

var errors_1 = require("../errors");

var MobileAppSettings =
/** @class */
function (_super) {
  __extends(MobileAppSettings, _super);

  function MobileAppSettings(defaultDeliveryServiceId, defaultStoreServiceId, defaultSkiBagsServiceId, defaultBicycleServiceId, lastServiceVersion) {
    var _this = _super.call(this) || this;

    _this.defaultDeliveryServiceId = defaultDeliveryServiceId;
    _this.defaultStoreServiceId = defaultStoreServiceId;
    _this.defaultSkiBagsServiceId = defaultSkiBagsServiceId;
    _this.defaultBicycleServiceId = defaultBicycleServiceId;
    _this.lastServiceVersion = lastServiceVersion;
    return _this;
  }

  MobileAppSettings.fromJson = function (data) {
    var requiredProps = ["defaultDeliveryService", "defaultStoreService", "defaultSkiBagsService", "defaultBicycleService", "lastStableVersion"];
    var verifyResult = MobileAppSettings.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    return new MobileAppSettings(data.defaultDeliveryService, data.defaultStoreService, data.defaultSkiBagsService, data.defaultBicycleService, data.lastStableVersion);
  };

  MobileAppSettings.prototype.getDefaultDeliveryServiceID = function () {
    return this.defaultDeliveryServiceId;
  };

  MobileAppSettings.prototype.getDefaultStoreServiceID = function () {
    return this.defaultStoreServiceId;
  };

  MobileAppSettings.prototype.getDefaultSkiBagsServiceID = function () {
    return this.defaultSkiBagsServiceId;
  };

  MobileAppSettings.prototype.getDefaultBicycleServiceID = function () {
    return this.defaultBicycleServiceId;
  };

  MobileAppSettings.prototype.getLastStableVersion = function () {
    return this.lastServiceVersion;
  };

  MobileAppSettings.prototype.setDefaultDeliveryServiceID = function (id) {
    this.defaultDeliveryServiceId = id;
  };

  MobileAppSettings.prototype.setDefaultStoreServiceID = function (id) {
    this.defaultStoreServiceId = id;
  };

  MobileAppSettings.prototype.setDefaultSkiBagsServiceID = function (id) {
    this.defaultSkiBagsServiceId = id;
  };

  MobileAppSettings.prototype.setDefaultBicycleServiceID = function (id) {
    this.defaultBicycleServiceId = id;
  };

  MobileAppSettings.prototype.setLastStableVersion = function (version) {
    this.lastServiceVersion = version;
  };

  return MobileAppSettings;
}(Model_1.Model);

exports.MobileAppSettings = MobileAppSettings;
export interface IPaginationCalcResult {
    skip: number;
    limit: number;
}

export function calcSkipAndLimit(firstElementIndex: number, totalCount: number, countPerList: number): IPaginationCalcResult {
    let skip = (totalCount - firstElementIndex) - countPerList;
    let limit = countPerList;
    if (skip < 0) {
        limit = limit + skip;
        skip = 0;
    }
    return {
        skip: skip,
        limit: limit
    }
}


import PageTemplate from "./PageTemplate";
import {Route} from "react-router-dom";
import RouteURLs from "../actions/routesURL";
import UsersComponent from "../components/content/users/UsersComponent";

import React from "react";
import ClientDetailComponent from "../components/content/users/clients/ClientDetailComponent";
import PartnerDetailComponent from "../components/content/users/partners/PartnerDetailComponent";
import AccountDetailComponent from "../components/content/users/accounts/AccountDetailComponent";

const UsersPage = () => {
    return (
        <PageTemplate>
            <Route exact path={RouteURLs.users} component={UsersComponent} />
            <Route exact path={RouteURLs.users + "/:id"} component={ClientDetailComponent} />
            <Route path={RouteURLs.users + RouteURLs.partner + "/:id"} component={PartnerDetailComponent} />
            <Route path={RouteURLs.users + RouteURLs.accounts + "/:id"} component={AccountDetailComponent} />
        </PageTemplate>
    );
};

export default UsersPage;

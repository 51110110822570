"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DestinationRequestData = void 0;

var AuthorizedRequestData_1 = require("./AuthorizedRequestData");

var models_1 = require("../../models");

var DestinationRequestData =
/** @class */
function (_super) {
  __extends(DestinationRequestData, _super);

  function DestinationRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;

    _this.arguments = args;
    return _this;
  }

  DestinationRequestData.prototype.getData = function () {
    var center_object = {};
    var bounding_box_object = {};
    var polygon_object = {};

    try {
      center_object = JSON.parse(this.arguments.center);
      bounding_box_object = JSON.parse(this.arguments.boundingBox);
      polygon_object = JSON.parse(this.arguments.polygon);
    } catch (err) {}

    var result = {
      id: this.arguments.destinationId,
      title: this.arguments.title,
      localizedTitle: models_1.convertToObject(this.arguments.localizedTitle),
      center: center_object,
      boundingBox: bounding_box_object,
      polygon: polygon_object
    };

    if (this.arguments.destinationId) {
      result = Object.assign({}, result, {
        id: this.arguments.destinationId
      });
    }

    return Object.assign({}, _super.prototype.getData.call(this), result);
  };

  DestinationRequestData.prototype.getId = function () {
    return this.arguments.destinationId;
  };

  return DestinationRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);

exports.DestinationRequestData = DestinationRequestData;
import React from "react";
import PageTemplate from "./PageTemplate";
import RouteURLs from "../actions/routesURL";
import ServiceComponent from "../components/content/service/ServiceComponent";
import ServiceDetailComponent from "../components/content/service/ServiceDetailComponent";
import { Route } from "react-router-dom";

const ServicesPage = () => {
  return (
    <PageTemplate>
      <Route exact path={RouteURLs.services} component={ServiceComponent} />
      <Route exact path={RouteURLs.services + "/:id"} component={ServiceDetailComponent} />
    </PageTemplate>
  );
};

export default ServicesPage;

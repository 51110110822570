import React from "react";
import ClientsListComponent from "./ClientsListComponent";
import ClientsFilterComponent from "./ClientsFilterComponent";
import {Bind} from "@shift-mono/common";

interface IClientsComponentProps {
}

interface IClientsComponentState {
    filterClientStatus: boolean | undefined
}

export default class ClientsComponent extends React.Component<IClientsComponentProps, IClientsComponentState> {
    private listRef: any = undefined;

    constructor(props: IClientsComponentProps) {
        super(props);

        this.state = {
            filterClientStatus: undefined
        }
    }

    @Bind()
    filterComponentHandler(isAgent: boolean | undefined) {
        this.setState({filterClientStatus: isAgent})
        this.reloadListContent()
    }

    @Bind()
    reloadListContent() {
        if (this.listRef && this.listRef.reloadContent) {
            this.listRef.reloadContent();
        }
    }

    render() {
        return (<>
            <div className="row">
                <div className="col-lg-12 ">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Список клиентов</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <ClientsListComponent
                                ref={(e) => {
                                    this.listRef = e;
                                }}
                                filterParams={
                                    {agentStatus: this.state.filterClientStatus}
                                }
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-12">
                                    <ClientsFilterComponent
                                        defaultStatus={this.state.filterClientStatus}
                                        callbackHandler={this.filterComponentHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);

    }
}
import React, {useState} from "react";

interface ImageItemProps {
    imageSrc: string
}

export const ImageItem = (props: ImageItemProps) => {
    const [isOpen, setOpen] = useState(false);
    const onClickHandler = () => {
        setOpen(!isOpen);
    }

    return (
        <>
            {isOpen ? <div
                className={"imgBackPlate"}
                onClick={onClickHandler}
            /> : <></>}
            <img src={props.imageSrc}
                 alt={props.imageSrc}
                 className={isOpen ? "open" : ""}
                 style={{cursor: 'pointer'}}
                 onClick={onClickHandler}/>
        </>
    )
}
import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./BackButtonComponentStyle.scss"

const BackButtonComponent = props => {
  return <span className="back-btn" onClick={props.history.goBack}>&#x2190;</span>;
};

BackButtonComponent.propTypes = {
  history: PropTypes.object
};

export default withRouter(BackButtonComponent);

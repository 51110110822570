import C from "../actionsTypes";
import {isDevMode} from "../../helpers/AppHelpers";
import {
    setRequestEntitiesStatusToComponent,
    setFailureRequestStatusToComponent,
    setSuccessRequestStatusToComponent
} from "../actionCreators";
import {requestTokenByPassword, IDataResponse, IToken} from "@shift-mono/common";

//USER ### START

export const loginUser = (token: IToken) => {
    return {
        type: C.USER.LOGIN,
        data: {
            token
        }
    };
};

export const logoutUser = () => {
    return {
        type: C.USER.LOGOUT
    };
};

export const receiveUserData = (
    login: string,
    password: string,
    component_title: string
) => {
    return async (dispatch: any) => {
        dispatch(setRequestEntitiesStatusToComponent(component_title));

        try {
            const response: IDataResponse<IToken | undefined> = await requestTokenByPassword(login, password);
            const token = response.getData();
            if(!token){
                const defaultErrorMessage = "Ошибка Авторизации";
                dispatch(setFailureRequestStatusToComponent(component_title, defaultErrorMessage));
                return;
            }

            dispatch(setSuccessRequestStatusToComponent(component_title));
            dispatch(loginUser(token));

        } catch (err) {
            if (isDevMode()) {
                console.log(err);
            }

            const defaultErrorMessage = "Ошибка Авторизации";
            const message = err.response !== undefined && err.response.data.error_description ?
                err.response.data.error_description :
                defaultErrorMessage;
            dispatch(setFailureRequestStatusToComponent(component_title, message));
        }

    };
};

//USER ### END

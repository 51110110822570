import React from "react";
import URLS from "../../../actions/routesURL"
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Notification, INotification, NotificationStatus} from "@shift-mono/common";
import "./NotificationBadgeComponentStyle.scss";

interface INotificationBadgeComponentProps {
    notifications: INotification[]
}

interface INotificationBadgeComponentState {
}

class NotificationBadgeComponent extends React.Component<INotificationBadgeComponentProps, INotificationBadgeComponentState> {
    render() {
        const newNotificationsCount = this.props.notifications
            .filter((item: INotification) => {
                if (!item) {
                    return false
                }
                return item.getStatus() === NotificationStatus.New;
            })
            .length;

        return <div className="notification-block">
            {newNotificationsCount > 0 ?
                <div className="notification-block__badge">{newNotificationsCount}</div>
                : <></>}

            <Link to={URLS.notifications}>
                <img
                    src={process.env.PUBLIC_URL + "/images/notification.svg"}
                    alt="Logout"
                    className="notification-block__image"
                />
            </Link>
        </div>;
    }
}

const mapStateToProps = (state: any) => {
    return {
        notifications: state.notification.map((item: any) => {
            return Notification.from(item);
        })
    }
};


export default connect(mapStateToProps)(NotificationBadgeComponent)
import React from "react";
import {Bind, IPartner} from "@shift-mono/common";
import {Dropdown} from "primereact/dropdown";

interface IPartnerPickerComponentProps extends React.HTMLAttributes<HTMLDivElement> {
    availablePartners: IPartner[],
    changeHandler: (selectedPartner: IPartner | undefined) => void,
    currentPartnerId?: string,
}

interface IPartnerPickerComponentState {
}

interface DropdownItem {
    title: string,
    value: IPartner | undefined
}

export default class PartnerPickerComponent extends React.Component<IPartnerPickerComponentProps, IPartnerPickerComponentState> {

    @Bind()
    getOptions(): DropdownItem[] {
        return [...this.props.availablePartners, undefined]
            .map((item) => {
                return this.getOptionItemFor(item)
            })
    }

    getOptionItemFor(partner: IPartner | undefined) {
        return partner
            ? {title: partner.getTitle(), value: partner}
            : {title: "Нет партнера", value: partner}
    }

    render() {
        const currentPartner = this.props.availablePartners
            .filter((partner) => {
                return partner.getId() === this.props.currentPartnerId
            })
            .pop()

        const current = this.getOptionItemFor(currentPartner);

        return <Dropdown
            className={this.props.className ? this.props.className : ""}
            optionLabel={"title"}
            value={current}
            options={this.getOptions()}
            onChange={(e) => {
                this.props.changeHandler(e.value.value);
            }}
            placeholder="Выберите партнера"/>
    }
}
import React from "react";
import {
    AuthorizedRequestData,
    Bind, IClient, IHash,
    IWithdrawal, putWithdrawalRequest, requestClientsByIds,
    requestWithdrawal, WithdrawalRequestData,
} from "@shift-mono/common";

import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import {WithdrawalFilterOptions} from "./partials/WithdrawalFilterOptions";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Growl} from "primereact/growl";

interface IWithdrawalListComponentProps {
    getToken: () => Promise<string>;
    filterParams: WithdrawalFilterOptions;
    ref: (item: any) => any;
}

interface IWithdrawalListComponentState {
    withdrawals: IWithdrawal[];
    clients: IHash<IClient>;
    confirmDialogVisible: boolean;
    confirmableWithdrawals: IWithdrawal | undefined;
}

class WithdrawalListComponent extends React.Component<IWithdrawalListComponentProps,
    IWithdrawalListComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IWithdrawalListComponentProps) {
        super(props);
        this.state = {
            withdrawals: [],
            clients: {},
            confirmDialogVisible: false,
            confirmableWithdrawals: undefined,
        };
    }

    @Bind()
    async contentRequest() {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token, {}, this.composeFilterRequestParams());

        try {

            const withdrawal = (await requestWithdrawal(request_data)).getData();

            const clientsId = withdrawal.map((item: IWithdrawal) => (item.getUserId()));
            const clients = (await requestClientsByIds(clientsId, request_data)).getData();
            const clientsHash = clients.reduce((clientsHash: IHash<IClient>, client) => {
                clientsHash[client.getId()] = client;
                return clientsHash;
            }, {});
            this.setState({
                clients: clientsHash
            });

            return withdrawal;
        } catch (err) {
            return [];
        }
    }

    @Bind()
    composeFilterRequestParams() {
        switch (this.props.filterParams) {
            case WithdrawalFilterOptions.COMPLETED:
                return {query: `{"complitted": true}`};
            case WithdrawalFilterOptions.IN_PROGRESS:
                return {query: `{"complitted": false}`};
            case WithdrawalFilterOptions.NONE:
                return {}
        }
    }

    @Bind()
    async requestResult(result: IWithdrawal[]) {
        this.setState({withdrawals: result});
    }

    @Bind()
    reloadContent() {
        if (this.loaderRef !== undefined) {
            this.loaderRef.tryToLoading();
        }
    }

    @Bind()
    renderConfirmWithdrawalDialog() {
        return (<Dialog
            header="Внимание!"
            footer={this.renderConfirmWithdrawalDialogFooter()}
            visible={this.state.confirmDialogVisible}
            style={{width: "50vw"}}
            modal={true}
            onHide={() => {
                this.hideConfirmWithdrawalDialog();
            }}
        >
            Вы уверенны, что хотите подтведить вывод средств?
        </Dialog>)
    }

    @Bind()
    renderConfirmWithdrawalDialogFooter() {
        return (
            <div>
                <Button
                    label="Да"
                    icon="pi pi-check"
                    onClick={async () => {
                        await this.confirmWithdrawalRequest()
                        this.hideConfirmWithdrawalDialog();
                    }}
                />
                <Button
                    label="Нет"
                    icon="pi pi-times"
                    onClick={() => this.hideConfirmWithdrawalDialog()}
                />
            </div>
        );
    }

    @Bind()
    hideConfirmWithdrawalDialog() {
        this.setState({confirmDialogVisible: false})
    }

    @Bind()
    showConfirmWithdrawalDialog(item: IWithdrawal) {
        this.setState({
            confirmDialogVisible: true,
            confirmableWithdrawals: item
        })
    }

    @Bind()
    async confirmWithdrawalRequest() {
        if (this.state.confirmableWithdrawals) {
            const token = await this.props.getToken();
            const confirmableWithdrawals = this.state.confirmableWithdrawals;
            const requestData = new WithdrawalRequestData({
                accessToken: token,
                userId: confirmableWithdrawals.getUserId(),
                amount: confirmableWithdrawals.getAmount(),
                complitted: true,
                id: confirmableWithdrawals.getId(),
            });
            if (await putWithdrawalRequest(requestData)) {
                this.reloadContent();
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка обновления статуса"
                });
            }
        } else {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка обновления статуса"
            });
        }
    }

    render() {
        return (<>
                <Growl ref={el => (this.growl = el)}/>
                {this.renderConfirmWithdrawalDialog()}
                <ContentLoaderComponent<IWithdrawal[]>
                    contentRequest={this.contentRequest}
                    resultCallback={this.requestResult}
                    ref={el => {
                        this.loaderRef = el;
                    }}
                >
                    <DataTable
                        className="main_list_table"
                        value={this.state.withdrawals}
                        paginator={true}
                        rows={25}
                    >
                        <Column
                            body={(item: IWithdrawal) => {
                                const clientId = item.getUserId();
                                if (!this.state.clients[clientId]) {
                                    return "Нет данных!"
                                }
                                const client = this.state.clients[clientId];

                                return (
                                    <>
                                        <div>Телефон: {client.getPhone()}</div>
                                        {client.getFullName().trim() !== "" ?
                                            <div> ФИО: {client.getFullName()}</div> : <></>}
                                        {client.getEmail().trim() !== "" ?
                                            <div> Почта: {client.getEmail()}</div> : <></>}
                                        {client.getAddress().getFullAddress().trim() !== "" ?
                                            <div> Адресс: {client.getAddress().getFullAddress()}</div> : <></>}
                                    </>
                                );

                            }}
                            header="Пользователь"
                            style={{width: "50%"}}
                        />
                        <Column
                            body={(item: IWithdrawal) => {
                                return item.getAmount();
                            }}
                            header="Сумма"
                            style={{width: "20%"}}
                        />
                        <Column
                            body={(item: IWithdrawal) => {
                                return (item.isCompleted()
                                    ? "Выполнено"
                                    : <Button
                                        className={"p-button-success"}
                                        label={"Подтвердить"}
                                        onClick={() => {
                                            this.showConfirmWithdrawalDialog(item);
                                        }}
                                    />);
                            }}
                            header="Статус"
                            style={{width: "30%", textAlign: "center"}}
                        />
                    </DataTable>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(WithdrawalListComponent);
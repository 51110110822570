import PageTemplate from "./PageTemplate";
import {Route} from "react-router-dom";
import RouteURLs from "../actions/routesURL";
import React from "react";
import DestinationComponent from "../components/content/destination/DestinationComponent";
import DestinationDetailComponent from "../components/content/destination/DestinationDetailComponent";
import DirectionDetailComponent from "../components/content/direction/DirectionDetailComponent";

const DestinationPage = () => {
    return (
        <PageTemplate>
            <Route exact path={RouteURLs.destinations} component={DestinationComponent}/>
            <Route exact path={RouteURLs.destinations + "/:id"} component={DestinationDetailComponent}/>
            <Route path={RouteURLs.destinations + RouteURLs.directions + "/:id"} component={DirectionDetailComponent}/>
        </PageTemplate>
    );
};
export default DestinationPage;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServicePaymentType = void 0;
var ServicePaymentType;

(function (ServicePaymentType) {
  ServicePaymentType["OneTime"] = "onetime";
  ServicePaymentType["Monthly"] = "monthly";
})(ServicePaymentType = exports.ServicePaymentType || (exports.ServicePaymentType = {}));
import React from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {getYaKassaOrder} from "@shift-mono/common";
import {Card} from "primereact/card";

interface YaKassaOrderComponentProps {
}

interface YaKassaOrderComponentState {
    order_id: string;
    order: any;
    error_message: string | undefined;
}

export default class YaKassaOrderComponent extends React.Component<YaKassaOrderComponentProps,
    YaKassaOrderComponentState> {
    constructor(props: YaKassaOrderComponentProps) {
        super(props);
        this.state = {
            order_id: "",
            order: undefined,
            error_message: undefined
        };
    }

    fetchOrder(order_id: string) {
        getYaKassaOrder(order_id)
            .then(result => {
                const tmp: any = result;
                this.setState({order: tmp.data});
            })
            .catch(error => {
                this.setState({order: undefined, error_message: "Нет данных!"});
                console.log(error);
            });
    }

    render() {
        let apartmentComponent = <></>;
        if (this.state.order && this.state.order.metadata.apartmentDetails) {
            const apartmentData = this.state.order.metadata.apartmentDetails.split(
                "|"
            );
            const defaultMessage = "Нет данных";
            let apartmentNumber = defaultMessage;
            let floor = defaultMessage;
            let entranceNumber = defaultMessage;
            if (apartmentData[0]) {
                apartmentNumber = apartmentData[0];
            }
            if (apartmentData[1]) {
                floor = apartmentData[1];
            }
            if (apartmentData[2]) {
                entranceNumber = apartmentData[2];
            }

            apartmentComponent = (
                <>
                    <div className="row m-1">
                        <label className="col-2 font-weight-bold">Номер квартиры:</label>
                        <span className="col-4">{apartmentNumber}</span>
                        <label className="col-2 font-weight-bold">Номер этажа:</label>
                        <span className="col-4">{floor}</span>
                    </div>
                    <div className="row m-1">
                        <label className="col-2 font-weight-bold">Номер подъезда:</label>
                        <span className="col-4">{entranceNumber}</span>
                    </div>
                </>
            );
        }

        return (
            <div className="card col-12 mt-2 p-2">
                <form
                    onSubmit={(e: any) => {
                        e.preventDefault();
                    }}
                >
                    <div className="row m-1">
                        <label className="col-3">ID заказа Яндекс кассы</label>
                        <InputText
                            className="col-5"
                            value={this.state.order_id}
                            placeholder="Id"
                            onChange={(e: any) => this.setState({order_id: e.target.value})}
                        />
                    </div>

                    <div className="row m-1 ml-2">
                        <Button
                            label="Найти"
                            onClick={() => {
                                if (this.state.order_id !== "") {
                                    this.fetchOrder(this.state.order_id);
                                    this.setState({order_id: "", error_message: undefined});
                                }
                            }}
                        />
                    </div>
                </form>
                {!this.state.order ? (
                    this.state.error_message ? (
                        <h3 className="w-100 d-flex justify-content-center text-danger">
                            {this.state.error_message}
                        </h3>
                    ) : (
                        ""
                    )
                ) : (
                    <>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">Откуда:</label>
                            <span className="col-4">{this.state.order.metadata.from}</span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">Куда:</label>
                            <span className="col-4">{this.state.order.metadata.to}</span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">Когда забрать:</label>
                            <span className="col-4">
                              {this.state.order.metadata.when_to_arrive}
                            </span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">ФИО:</label>
                            <span className="col-4">
                              {this.state.order.metadata.user_credentials}
                            </span>
                            <label className="col-2 font-weight-bold">Телефон:</label>
                            <span className="col-4">
                              {this.state.order.metadata.sum_funny_num}
                            </span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">Цена:</label>
                            <span className="col-4">{this.state.order.metadata.price}</span>
                            <label className="col-2 font-weight-bold">Кол-во сумок:</label>
                            <span className="col-4">
                              {this.state.order.metadata.summary_bags_count}
                            </span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">Хранение:</label>
                            <span className="col-4">
                              {this.state.order.metadata.storage_keeping_duration === "null"
                                  ? "Нет"
                                  : this.state.order.metadata.storage_keeping_duration}
                            </span>
                            <label className="col-2 font-weight-bold">Цена хранения:</label>
                            <span className="col-4">
                              {this.state.order.metadata.storage_keeping_cost}
                            </span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">Код скидки:</label>
                            <span className="col-4">
                              {this.state.order.metadata.discount_code}
                            </span>
                            <label className="col-2 font-weight-bold">Процент скидки:</label>
                            <span className="col-4">
                              {this.state.order.metadata.discount_value}
                            </span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">
                                Количество сумок Skishift:
                            </label>
                            <span className="col-4">
                              {this.state.order.metadata.skishift_bags_count}
                            </span>
                            <label className="col-2 font-weight-bold">
                                Цена за сумки Skishift:
                            </label>
                            <span className="col-4">
                              {this.state.order.metadata.bagsPrice}
                            </span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">Дата создания:</label>
                            <span className="col-4">
                              {this.state.order.metadata.creation_date}
                            </span>
                            <label className="col-2 font-weight-bold">Направление:</label>
                            <span className="col-4">
                              {this.state.order.metadata.direction}
                            </span>
                        </div>
                        <div className="row m-1">
                            <label className="col-2 font-weight-bold">
                                Количество чемоданов:
                            </label>
                            <span className="col-4">
                              {this.state.order.metadata.summary_luggage_count}
                            </span>
                            <label className="col-2 font-weight-bold">Тип устройства:</label>
                            <span className="col-4">
                              {this.state.order.metadata.device_type === "0" ? (
                                  <>
                                      "Android " <i className="pi pi-android"/>
                                  </>
                              ) : this.state.order.metadata.device_type === "1" ? (
                                  <>
                                      "iOS " <i className="pi pi-apple"/>
                                  </>
                              ) : (
                                  "Неизвестно"
                              )}
                            </span>
                        </div>
                        {apartmentComponent}
                        <div className="row m-1">
                            <h3>Исходный JSON</h3>
                        </div>
                        <div className="row m-1">
                            <div className="col-12">
                                <Card>
                                    <code>
                                        <pre>
                                          {this.state.order
                                              ? JSON.stringify(this.state.order, null, 4)
                                              : ""}
                                        </pre>
                                    </code>
                                </Card>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

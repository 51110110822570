const initialState = {
    user: {
        is_authorized: false,
    },
    entities: {
        clients: {},
        orders: {},
        bags: {},
        services: {},
        events: {},
        units: {},
        places: {},
        discounts: {},
        qrbagcodes: {},
        qrplacecodes: {},
        bag_temps: {}
    },
    component_state: {
        // bags: {
        //   isFetching: false,
        //   status: "succes",
        //   lastUpdated: 321,
        //   items: [1, 2]
        // },
        // orders: {
        //   isFetching: false,
        //   status: "error",
        //   lastUpdated: 321,
        //   items: [1, 2]
        // }
    },
    notification: [],
};

export default initialState;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrency = exports.Currency = void 0;

var errors_1 = require("../errors");

var Currency;

(function (Currency) {
  Currency["Rub"] = "rub";
  Currency["Percent"] = "percent";
})(Currency = exports.Currency || (exports.Currency = {}));

exports.getCurrency = function (str) {
  switch (str.toLowerCase()) {
    case "rub":
      return Currency.Rub;

    case "percent":
      return Currency.Percent;

    default:
      throw new errors_1.WrongArgumentError("Currency type " + str + " does not exist");
  }
};
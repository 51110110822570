"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationType = void 0;
var NotificationType;

(function (NotificationType) {
  NotificationType[NotificationType["Info"] = 0] = "Info";
  NotificationType[NotificationType["Warning"] = 1] = "Warning";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
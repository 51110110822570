import React from "react";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import RouteURLs from "../../../actions/routesURL";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {connect} from "react-redux";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {Button} from "primereact/button";
import {Growl} from "primereact/growl";
import {Dialog} from "primereact/dialog";
import {Link} from "react-router-dom";
import {
    ICountableResponse,
    CountableResponse,
    IStoragePoint,
    requestStoragePoints,
    deleteStoragePointRequest,
    AuthorizedRequestData, Bind
} from "@shift-mono/common";

interface IStoragePointListComponentProps {
    getToken: () => Promise<string>;
}

interface IStoragePointListComponentState {
    storage_points: IStoragePoint[];
    storagePointsCount: number;
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
    delete_dialog_visible: boolean;
    deleting_point_id: string | undefined;
}

class StoragePointListComponent extends React.Component<IStoragePointListComponentProps,
    IStoragePointListComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IStoragePointListComponentProps) {
        super(props);

        this.state = {
            storage_points: [],
            storagePointsCount: 0,
            paginationListRows: 30,
            firstPaginationIndex: 0,
            loading: true,
            deleting_point_id: undefined,
            delete_dialog_visible: false
        };
    }
    @Bind()
    async getStoragePointRequest(): Promise<ICountableResponse<IStoragePoint[]>> {
        const token = await this.props.getToken();
        const startIndex = 0;
        const endIndex = this.state.paginationListRows;

        const request_data = new AuthorizedRequestData(token,{},{
            skip: startIndex,
            limit: endIndex
        });
        try {
            return await requestStoragePoints(request_data);
        } catch (err) {
            return new CountableResponse<IStoragePoint[]>([], 0);
        }
    }
    @Bind()
    storagePointRequestResult(result: ICountableResponse<IStoragePoint[]>) {
        this.setState({
          storage_points: result.getData(),
          loading: false,
          storagePointsCount: result.getObjectsCount(),
        });
    }
    @Bind()
    async deleteStoragePointRequest(storage_point_id: string) {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);

        try {
            const resultStatus = await deleteStoragePointRequest(
                storage_point_id,
                request_data
            );
            if (resultStatus) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Пункт хранения удалён"
                });
                if (this.loaderRef !== undefined) {
                    this.loaderRef.tryToLoading();
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка удаления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка удаления"
            });
        }
    }
    @Bind()
    imageCellTemplate(rowData: IStoragePoint, column: any) {
        if (rowData.getImages() && rowData.getImages().length > 0) {
            return (
                <img src={rowData.getImages()[0]} style={{width: "160px"}} alt=""/>
            );
        }
        return <>Нет изображения</>;
    }
    @Bind()
    actionTemplate(storagePoint: IStoragePoint) {
        return (
            <>
                <Link to={RouteURLs.storages + "/" + storagePoint.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="p-button-info mr-3"
                    />
                </Link>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.showDeleteDialog(storagePoint.getId());
                    }}
                />
            </>
        );
    };
    @Bind()
    showDeleteDialog(storage_point_id: string) {
        this.setState({
            delete_dialog_visible: true,
            deleting_point_id: storage_point_id
        });
    }
    @Bind()
    hideDeleteDialog() {
        this.setState({delete_dialog_visible: false});
    }

    deleteDialogFooterTemplate = (
        <div>
            <Button
                label="Да"
                icon="pi pi-check"
                onClick={async () => {
                    if (this.state.deleting_point_id) {
                        await this.deleteStoragePointRequest(this.state.deleting_point_id);
                        this.setState({deleting_point_id: undefined});
                    } else {
                        this.growl.show({
                            severity: "error",
                            summary: "Ошибка",
                            detail: "Ошибка удаления"
                        });
                    }

                    this.hideDeleteDialog();
                }}
            />
            <Button
                label="Нет"
                icon="pi pi-times"
                onClick={() => this.hideDeleteDialog()}
            />
        </div>
    );
    @Bind()
    async onPage(event: any) {
    this.setState({
      loading: true
    });

    const startIndex = event.first;
    const endIndex = this.state.paginationListRows;

    const token = await this.props.getToken();
    const request_data = new AuthorizedRequestData(token, {}, {
      skip: startIndex,
      limit: endIndex
    });
    try {
      const storagePointsResponse = await requestStoragePoints(request_data);
      this.setState({
        firstPaginationIndex: startIndex,
        storage_points: storagePointsResponse.getData(),
        loading: false,
        storagePointsCount: storagePointsResponse.getObjectsCount()
      })
    } catch (err) {
      return new CountableResponse<IStoragePoint[]>([], 0);
    }
  }

    render() {
        return (
            <div className="row">
                <Growl
                    ref={el => {
                        this.growl = el;
                    }}
                />
                <ContentLoaderComponent<ICountableResponse<IStoragePoint[]>>
                    contentRequest={this.getStoragePointRequest}
                    resultCallback={this.storagePointRequestResult}
                    ref={el => {
                        this.loaderRef = el;
                    }}
                >
                    <Dialog
                        header="Внимание!"
                        footer={this.deleteDialogFooterTemplate}
                        visible={this.state.delete_dialog_visible}
                        style={{width: "50vw"}}
                        modal={true}
                        onHide={() => {
                            this.hideDeleteDialog();
                        }}
                    >
                        Вы уверенны, что хотите удалить пункт хранения?
                    </Dialog>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>Список мест хранения</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <DataTable
                                    className="main_list_table mb-3"
                                    value={this.state.storage_points}
                                    paginator={true}
                                    totalRecords={this.state.storagePointsCount}
                                    rows={this.state.paginationListRows}
                                    first={this.state.firstPaginationIndex}
                                    lazy={true}
                                    loading={this.state.loading}
                                    onPage={this.onPage}
                                >
                                    <Column
                                        field="images"
                                        body={this.imageCellTemplate}
                                        header=""
                                        style={{width: "13em"}}
                                    />
                                    <Column
                                        body={(rowData: IStoragePoint) => {
                                            return (
                                                <>
                                                    <p>
                                                        <b>Название: </b>
                                                        {rowData.getName()}
                                                    </p>
                                                    <p>
                                                        <b>Адрес: </b>
                                                        {rowData.getAddress().getFullAddress()}
                                                    </p>
                                                </>
                                            );
                                        }}
                                        header="Информация"
                                    />
                                    <Column
                                        body={(rowData: IStoragePoint) => {
                                            return (
                                                <>
                                                    <p>
                                                        <b>Свободно / Всего: </b>
                                                        {rowData.getFreeCells()} / {rowData.getCells()}
                                                    </p>
                                                </>
                                            );
                                        }}
                                        header="Ячейки"
                                    />
                                    <Column body={this.actionTemplate} style={{width: "8em"}}/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </ContentLoaderComponent>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(StoragePointListComponent);

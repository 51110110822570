import React from "react";
import RouteURLs from "../actions/routesURL";
import PageTemplate from "./PageTemplate";
import OrderComponent from "../components/content/orders/OrderComponent";
import OrderDetailComponent from "../components/content/orders/OrderDetailComponent";
import { Route } from "react-router-dom";

const OrdersPage = () => {
  return (
    <PageTemplate>
      <Route exact path={RouteURLs.orders} component={OrderComponent} />
      <Route
        path={RouteURLs.orders + "/:id"}
        component={OrderDetailComponent}
      />
    </PageTemplate>
  );
};

export default OrdersPage;

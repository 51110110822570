import React from "react";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {
    AuthorizedRequestData, Bind, CountableResponse,
    IClient,
    ICountableResponse, Logger,
    requestClients
} from "@shift-mono/common";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {calcSkipAndLimit} from "../../../../helpers/PaginationHelpers";
import {Link} from "react-router-dom";
import RouteURLs from "../../../../actions/routesURL";
import {Button} from "primereact/button";
import {SearchComponent} from "./partials/SearchComponent";

interface IClientListFilterParams {
    agentStatus: boolean | undefined,
}

interface IClientsListComponentProps {
    getToken: () => Promise<string>;
    filterParams?: IClientListFilterParams;
    ref: (item: any) => any;
}

interface IClientsListComponentState {
    clients: IClient[],
    clientsCount: number,
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
    searchVal: string | undefined;
}

class ClientsListComponent extends React.Component<IClientsListComponentProps, IClientsListComponentState> {
    private loaderRef: any = undefined;

    constructor(props: IClientsListComponentProps) {
        super(props);

        this.state = {
            clients: [],
            clientsCount: 0,
            paginationListRows: 20,
            firstPaginationIndex: 0,
            loading: true,
            searchVal: undefined,
        };
    }

    @Bind()
    async getClientsRequest(): Promise<ICountableResponse<IClient[]>> {
        try {
            const token = await this.props.getToken();
            let currentClientsCount = this.state.clientsCount;

            // if (currentClientsCount === 0) {
                currentClientsCount = await this.getClientsCount();
            // }

            const startIndex = 0;
            let params = calcSkipAndLimit(startIndex, currentClientsCount, this.state.paginationListRows);
            params = Object.assign({}, params, this.composeQueryRequestParams());

            const requestData = new AuthorizedRequestData(token, {}, params);

            return requestClients(requestData);
        } catch (err) {
            return new CountableResponse<IClient[]>([], 0);
        }
    }

    @Bind()
    composeQueryRequestParams() {
        const queryObjs = [];

        if (this.props.filterParams) {
            switch (this.props.filterParams!.agentStatus) {
                case true:
                    queryObjs.push({"isAgent": true});
                    break;
                case false:
                    queryObjs.push({"$or" : [{"isAgent": false}, {"isAgent": null}]});
                    break;
            }
        }

        if (this.state.searchVal){
            const searchReg = this.state.searchVal
                .trim()
                .replace("+", "\\+")
                .split(" ")
                .join("|");
            queryObjs.push(
                {
                    "$or": [
                        {'phoneNumber': {"$regex" : searchReg, $options: 'i'}},
                        {'firstName': {"$regex" : searchReg, $options: 'i'}},
                        {'secondName': {"$regex" : searchReg, $options: 'i'}},
                        {'thirdName': {"$regex" : searchReg, $options: 'i'}},
                    ]
                }
            );
        }

        const result = queryObjs.length > 0? { "$and" : queryObjs }: {}
        return {query: JSON.stringify(result)};
    }

    @Bind()
    clientRequestResult(clients: ICountableResponse<IClient[]>) {
        this.setState({
            clients: clients.getData().reverse(),
            loading: false,
            clientsCount: clients.getObjectsCount(),
        })
    }

    @Bind()
    async getClientsCount(): Promise<number> {
        const token = await this.props.getToken();
        let params = {
            skip: 0,
            limit: 1
        }
        params = Object.assign({}, params, this.composeQueryRequestParams());
        const mockRequestData = new AuthorizedRequestData(token, {}, params);

        try {
            const clientsResponse = await requestClients(mockRequestData);
            return clientsResponse.getObjectsCount();

        } catch (err) {
            return 0;
        }
    }

    @Bind()
    async onPage(event: any) {
        this.setState({
            loading: true
        });

        const token = await this.props.getToken();
        let currentClientsCount = this.state.clientsCount;

        // if (currentClientsCount === 0) {
            currentClientsCount = await this.getClientsCount();
        // }

        const startIndex = event.first;
        let params = calcSkipAndLimit(event.first, currentClientsCount, this.state.paginationListRows);
        params = Object.assign({}, params, this.composeQueryRequestParams());

        const requestData = new AuthorizedRequestData(token, {}, params);
        try {
            const clientsResponse = await requestClients(requestData);
            this.setState({
                firstPaginationIndex: startIndex,
                clients: clientsResponse.getData().reverse(),
                loading: false,
                clientsCount: clientsResponse.getObjectsCount()
            })
        } catch (err) {
            return new CountableResponse<IClient[]>([], 0);
        }
    }

    actionTemplate(rowData: IClient, column: any) {
        return (
            <>
                <Link to={RouteURLs.users + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-file-o"
                        className="p-button-info mr-3"
                    />
                </Link>
            </>
        );
    }

    @Bind()
    reloadContent() {
        if (this.loaderRef !== undefined) {
            this.loaderRef.tryToLoading();
        }
    }

    render() {
        return (
            <ContentLoaderComponent<ICountableResponse<IClient[]>>
                contentRequest={this.getClientsRequest}
                resultCallback={this.clientRequestResult}
                errorCallback={(err) => {
                    Logger.d(err);
                }}
                ref={el => {
                    this.loaderRef = el;
                }}
            >
                <SearchComponent
                    style={{marginBottom: '10px'}}
                    searchValue={this.state.searchVal}
                    onChangeHandler={(searchVal) => {
                        this.setState({searchVal});
                    }}
                    searchCallback={(searchVal) => {
                        this.setState({searchVal});
                        this.reloadContent()
                    }}
                />
                <DataTable
                    className="main_list_table mb-3"
                    value={this.state.clients}
                    paginator={true}
                    totalRecords={this.state.clientsCount}
                    rows={this.state.paginationListRows}
                    first={this.state.firstPaginationIndex}
                    lazy={true}
                    loading={this.state.loading}
                    onPage={this.onPage}
                >
                    <Column
                        body={(client: IClient) => {
                            return <>
                                <div>Телефон: {client.getPhone()}</div>
                                {client.getFullName().trim() !== "" ?
                                    <div> ФИО: {client.getFullName()}</div> : <></>}
                                {client.getEmail().trim() !== "" ?
                                    <div> Почта: {client.getEmail()}</div> : <></>}
                                {client.getAddress().getFullAddress().trim() !== "" ?
                                    <div> Адресс: {client.getAddress().getFullAddress()}</div> : <></>}
                            </>

                        }}
                        header="Информация"
                        // style={{width: "75%"}}
                    />

                    <Column
                        body={(client: IClient) => {
                            return client.isAgent() ? "Агент" : "Пользователь";
                        }}
                        header="Статус"
                        style={{width: "25%", textAlign: "center"}}
                    />
                    <Column
                        body={this.actionTemplate}
                        style={{width: "4em"}}
                    />
                </DataTable>
            </ContentLoaderComponent>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(ClientsListComponent);
"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerRequestData = void 0;

var _1 = require("./");

var PartnerRequestData =
/** @class */
function (_super) {
  __extends(PartnerRequestData, _super);

  function PartnerRequestData(arg) {
    var _this = _super.call(this, arg.accessToken) || this;

    _this.arguments = arg;
    return _this;
  }

  PartnerRequestData.prototype.getData = function () {
    var result = {
      title: this.arguments.partnerTitle
    };

    if (this.arguments.partnerId) {
      result = Object.assign({}, result, {
        id: this.arguments.partnerId
      });
    }

    return result;
  };

  PartnerRequestData.prototype.getId = function () {
    return this.arguments.partnerId;
  };

  return PartnerRequestData;
}(_1.AuthorizedRequestData);

exports.PartnerRequestData = PartnerRequestData;
import React from "react";
import {Button} from "primereact/button";
import ClientStatusDropdown from "./partials/ClientStatusDropdown";

interface IClientsFilterComponentProps {
    defaultStatus: boolean | undefined;
    callbackHandler: (clientStatus: boolean | undefined) => void;
}

interface IClientsFilterComponentState {
    agentStatus: boolean | undefined;
}

export default class ClientsFilterComponent extends React.Component<IClientsFilterComponentProps, IClientsFilterComponentState> {

    constructor(props: IClientsFilterComponentProps) {
        super(props);

        this.state = {
            agentStatus: props.defaultStatus
        }
    }

    render() {
        return <>
            <form
                className="card mt-2 p-2"
                onSubmit={(e: any) => {
                    e.preventDefault();
                }}
            >
                <div className="row m-1">
                    <label className={"col-lg-5"}><b>Фильтр</b></label>
                </div>
                <div className="row m-1">
                    <label className={"col-lg-5"}>Статус пользователя</label>
                    <ClientStatusDropdown
                        statusValue={this.state.agentStatus}
                        changeStatusHandler={(agentStatus) => {
                            this.setState({agentStatus})
                        }}
                    />
                </div>
                <div className="row m-1 ml-2">
                    <Button
                        label={"Отфильтровать"}
                        onClick={() => {
                            this.props.callbackHandler(this.state.agentStatus);
                        }}
                    />
                </div>
            </form>
        </>
    }
}
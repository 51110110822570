"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoragePointType = void 0;
var StoragePointType;

(function (StoragePointType) {
  StoragePointType["StoragePoint"] = "storagepoint";
  StoragePointType["Hotel"] = "hotel";
  StoragePointType["None"] = "none";
})(StoragePointType = exports.StoragePointType || (exports.StoragePointType = {}));
import React from "react";
import PageTemplate from "./PageTemplate";
import RouteURLs from "../actions/routesURL";
import DiscountComponent from "../components/content/discount/DiscountComponent";
import DiscountDetailComponent from "../components/content/discount/DiscountDetailComponent";
import { Route } from "react-router-dom";

const DiscountPage = () => {
  return (
    <PageTemplate>
      <Route exact path={RouteURLs.discounts} component={DiscountComponent}/>
      <Route path={RouteURLs.discounts + "/:id"} component={DiscountDetailComponent}/>
    </PageTemplate>
  );
};
export default DiscountPage;

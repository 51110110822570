"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderStatus = void 0;
var OrderStatus;

(function (OrderStatus) {
  OrderStatus["New"] = "new";
  OrderStatus["Payed"] = "payed";
  OrderStatus["Pending"] = "pending";
  OrderStatus["Processing"] = "processing";
  OrderStatus["OnAgreement"] = "onagreement";
  OrderStatus["Confirmed"] = "confirmed";
  OrderStatus["InProgress"] = "inprogress";
  OrderStatus["Complete"] = "complete";
  OrderStatus["Cancelled"] = "cancelled";
  OrderStatus["WaitingDelivery"] = "waitingdelivery";
  OrderStatus["CourierDelivery"] = "courierdelivery";
  OrderStatus["TransferredDelivery"] = "transferreddelivery";
  OrderStatus["Issued"] = "issued";
  OrderStatus["None"] = "none";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
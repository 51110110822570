import React from "react";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {
    AuthorizedRequestData, Bind, CountableResponse,
    IClient,
    ICountableResponse, IUser, Logger, requestB2bAccounts, requestClientAccounts,
} from "@shift-mono/common";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {calcSkipAndLimit} from "../../../../helpers/PaginationHelpers";
import {Link} from "react-router-dom";
import RouteURLs from "../../../../actions/routesURL";
import {Button} from "primereact/button";

interface IAccountsListComponentProps {
    getToken: () => Promise<string>;
    listType: AccountsListType;
}

interface IAccountsListComponentState {
    accounts: IUser[];
    accountsCount: number;
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
}

export enum AccountsListType {
    Clients,
    B2b,
}

class AccountsListComponent extends React.Component<IAccountsListComponentProps, IAccountsListComponentState> {
    private loaderRef: any = undefined;

    constructor(props: IAccountsListComponentProps) {
        super(props);

        this.state = {
            accounts: [],
            accountsCount: 0,
            paginationListRows: 20,
            firstPaginationIndex: 0,
            loading: true
        };
    }

    @Bind()
    getAccountRequestFunc() {
        return this.props.listType === AccountsListType.B2b? requestB2bAccounts: requestClientAccounts;
    }

    @Bind()
    async getAccountsRequest(): Promise<ICountableResponse<IUser[]>> {
        try {
            const token = await this.props.getToken();
            let currentAccountsCount = this.state.accountsCount;

            currentAccountsCount = await this.getAccountsCount();

            const startIndex = 0;
            let params = calcSkipAndLimit(startIndex, currentAccountsCount, this.state.paginationListRows);
            params = Object.assign({}, params, this.composeFilterRequestParams());

            const requestData = new AuthorizedRequestData(token, {}, params);

            return this.getAccountRequestFunc()(requestData);
        } catch (err) {
            return new CountableResponse<IClient[]>([], 0);
        }
    }

    @Bind()
    composeFilterRequestParams() {
        return {}
    }

    @Bind()
    accountsRequestResult(users: ICountableResponse<IUser[]>) {
        this.setState({
            accounts: users.getData().reverse(),
            loading: false,
            accountsCount: users.getObjectsCount(),
        })
    }

    @Bind()
    async getAccountsCount(): Promise<number> {
        const token = await this.props.getToken();
        let params = {
            skip: 0,
            limit: 1
        }
        params = Object.assign({}, params, this.composeFilterRequestParams());
        const mockRequestData = new AuthorizedRequestData(token, {}, params);

        try {
            const accountsResponse = await this.getAccountRequestFunc()(mockRequestData);
            return accountsResponse.getObjectsCount();

        } catch (err) {
            return 0;
        }
    }

    @Bind()
    async onPage(event: any) {
        this.setState({
            loading: true
        });

        const token = await this.props.getToken();
        let currentAccountsCount = this.state.accountsCount;

        currentAccountsCount = await this.getAccountsCount();

        const startIndex = event.first;
        let params = calcSkipAndLimit(event.first, currentAccountsCount, this.state.paginationListRows);
        params = Object.assign({}, params, this.composeFilterRequestParams());

        const requestData = new AuthorizedRequestData(token, {}, params);
        try {
            const accountsResponse = await this.getAccountRequestFunc()(requestData);
            this.setState({
                firstPaginationIndex: startIndex,
                accounts: accountsResponse.getData().reverse(),
                loading: false,
                accountsCount: accountsResponse.getObjectsCount()
            })
        } catch (err) {
            return new CountableResponse<IClient[]>([], 0);
        }
    }

    actionTemplate(rowData: IClient, column: any) {
        return (
            <>
                <Link to={RouteURLs.users + RouteURLs.accounts + "/" + rowData.getId()}>
                    <Button
                        type="button"
                        icon="pi pi-file-o"
                        className="p-button-info mr-3"
                    />
                </Link>
            </>
        );
    }

    render() {
        return (
            <ContentLoaderComponent<ICountableResponse<IUser[]>>
                contentRequest={this.getAccountsRequest}
                resultCallback={this.accountsRequestResult}
                errorCallback={(err) => {
                    Logger.d(err);
                }}
                ref={el => {
                    this.loaderRef = el;
                }}
            >
                <DataTable
                    className="main_list_table mb-3"
                    value={this.state.accounts}
                    paginator={true}
                    totalRecords={this.state.accountsCount}
                    rows={this.state.paginationListRows}
                    first={this.state.firstPaginationIndex}
                    lazy={true}
                    loading={this.state.loading}
                    onPage={this.onPage}
                >
                    <Column
                        body={(user: IUser) => {
                            return <>
                                <div>Логин: {user.getUsername()}</div>
                            </>

                        }}
                        header="Информация"
                        // style={{width: "75%"}}
                    />

                    <Column
                        body={this.actionTemplate}
                        style={{width: "4em"}}
                    />
                </DataTable>
            </ContentLoaderComponent>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(AccountsListComponent);
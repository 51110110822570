"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Order = void 0;

var Model_1 = require("./Model");

var OrderService_1 = require("./OrderService");

var OrderPayment_1 = require("./OrderPayment");

var OrderStatus_1 = require("./OrderStatus");

var Address_1 = require("./Address");

var errors_1 = require("../errors/");

var Order =
/** @class */
function (_super) {
  __extends(Order, _super);

  function Order(args) {
    var _this = _super.call(this) || this;

    _this.id = args.id;
    _this.shortId = args.shortId;
    _this.orderStatus = args.orderStatus;
    _this.total = args.total;
    _this.totalWithDiscount = args.totalWithDiscount;
    _this.pickupDate = args.pickupDate;
    _this.etaDate = args.etaDate;
    _this.createdOn = args.createdOn;
    _this.services = args.services;
    _this.discountId = args.discountId;
    _this.storagePointId = args.storagePointId;
    _this.payment = args.payment;
    _this.clientId = args.clientId;
    _this.images = args.images;
    _this.newOrder = args.newOrder;
    _this.addressFrom = args.addressFrom;
    _this.addressTo = args.addressTo;
    _this.storagePointFromId = args.storagePointFromId;
    _this.storagePointToId = args.storagePointToId;
    _this.destinationFromId = args.destinationFromId;
    _this.destinationToId = args.destinationToId;
    _this.ratings = args.ratings;
    return _this;
  }

  Order.fromJson = function (data) {
    var getStatus = function getStatus(status) {
      var currentStatus = OrderStatus_1.OrderStatus.None;

      if (status) {
        var tmpStatus = status.toLowerCase();

        switch (tmpStatus) {
          case OrderStatus_1.OrderStatus.New:
            currentStatus = OrderStatus_1.OrderStatus.New;
            break;

          case OrderStatus_1.OrderStatus.Pending:
            currentStatus = OrderStatus_1.OrderStatus.Pending;
            break;

          case OrderStatus_1.OrderStatus.Processing:
            currentStatus = OrderStatus_1.OrderStatus.Processing;
            break;

          case OrderStatus_1.OrderStatus.Confirmed:
            currentStatus = OrderStatus_1.OrderStatus.Confirmed;
            break;

          case OrderStatus_1.OrderStatus.OnAgreement:
            currentStatus = OrderStatus_1.OrderStatus.OnAgreement;
            break;

          case OrderStatus_1.OrderStatus.Payed:
            currentStatus = OrderStatus_1.OrderStatus.Payed;
            break;

          case OrderStatus_1.OrderStatus.InProgress:
            currentStatus = OrderStatus_1.OrderStatus.InProgress;
            break;

          case OrderStatus_1.OrderStatus.Complete:
            currentStatus = OrderStatus_1.OrderStatus.Complete;
            break;

          case OrderStatus_1.OrderStatus.Cancelled:
            currentStatus = OrderStatus_1.OrderStatus.Cancelled;
            break;

          case OrderStatus_1.OrderStatus.TransferredDelivery:
            currentStatus = OrderStatus_1.OrderStatus.TransferredDelivery;
            break;

          case OrderStatus_1.OrderStatus.WaitingDelivery:
            currentStatus = OrderStatus_1.OrderStatus.WaitingDelivery;
            break;

          case OrderStatus_1.OrderStatus.CourierDelivery:
            currentStatus = OrderStatus_1.OrderStatus.CourierDelivery;
            break;

          case OrderStatus_1.OrderStatus.Issued:
            currentStatus = OrderStatus_1.OrderStatus.Issued;
        }
      }

      return currentStatus;
    };

    var currentStatus = getStatus(data.orderStatus);
    var requiredProps = ["id", "createdOn", "orderStatus", "total", "totalWithDiscount", "clientId"];
    var verifyResult = Order.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    var services = [];

    if (data.hasOwnProperty("services")) {
      services = data.services.map(function (service) {
        return OrderService_1.OrderService.fromJson(service);
      });
    }

    var pickupDate = data.pickupDate ? new Date(Date.parse(data.pickupDate)) : undefined;
    var createdOn = data.createdOn ? new Date(Date.parse(data.createdOn)) : undefined;
    var etaDate = data.etaDate ? new Date(Date.parse(data.etaDate)) : undefined;
    var addressFrom = Address_1.Address.fromJson(data.hasOwnProperty("addressFrom") ? data.addressFrom : {});
    var addressTo = Address_1.Address.fromJson(data.hasOwnProperty("addressTo") ? data.addressTo : {});
    return new Order({
      id: data.id ? data.id : "",
      shortId: data.shortId ? data.shortId : undefined,
      orderStatus: currentStatus,
      total: data.total ? data.total : 0,
      totalWithDiscount: data.totalWithDiscount ? data.totalWithDiscount : 0,
      pickupDate: pickupDate,
      createdOn: createdOn,
      etaDate: etaDate,
      services: services,
      discountId: data.discountId ? data.discountId : "",
      storagePointId: data.storagePointId ? data.storagePointId : "",
      payment: data.payment ? OrderPayment_1.OrderPayment.fromJson(data.payment) : undefined,
      clientId: data.clientId ? data.clientId : "",
      newOrder: data.isNew ? data.isNew : false,
      addressFrom: addressFrom,
      addressTo: addressTo,
      storagePointToId: data.storagePointToId ? data.storagePointToId : "",
      storagePointFromId: data.storagePointFromId ? data.storagePointFromId : "",
      destinationToId: data.destinationToId ? data.destinationToId : "",
      destinationFromId: data.destinationFromId ? data.destinationFromId : "",
      ratings: data.ratings ? data.ratings : {}
    });
  };

  Order.prototype.toJson = function () {
    var result = {
      id: this.getId(),
      orderStatus: this.getOrderStatus(),
      total: this.getTotalPrice(),
      totalWithDiscount: this.getTotalPriceWithDiscount()
    };

    if (this.getPickupDate()) {
      result = Object.assign({}, result, {
        pickupDate: this.getPickupDate().toISOString()
      });
    }

    if (this.getCreatedDate()) {
      result = Object.assign({}, result, {
        createdOn: this.getCreatedDate().toISOString()
      });
    }

    if (this.getServices()) {
      result = Object.assign({}, result, {
        services: this.getServices().map(function (item) {
          return item.toJson();
        })
      });
    }

    if (this.getDiscountId()) {
      result = Object.assign({}, result, {
        discountId: this.getDiscountId()
      });
    }

    if (this.getStoragePointId()) {
      result = Object.assign({}, result, {
        storagePointId: this.getStoragePointId()
      });
    }

    if (this.getPayment()) {
      result = Object.assign({}, result, {
        payment: this.getPayment().toJson()
      });
    }

    if (this.getClientId()) {
      result = Object.assign({}, result, {
        clientId: this.getClientId()
      });
    }

    if (this.getImages()) {//result = Object.assign({}, result, {images: this.getImages()})
    }

    if (this.getAddressFrom()) {
      result = Object.assign({}, result, {
        addressFrom: this.getAddressFrom().toJson()
      });
    }

    if (this.getAddressTo()) {
      result = Object.assign({}, result, {
        addressTo: this.getAddressTo().toJson()
      });
    }

    if (this.getStoragePointFromId()) {
      result = Object.assign({}, result, {
        storagePointFromId: this.getStoragePointFromId()
      });
    }

    if (this.getStoragePointToId()) {
      result = Object.assign({}, result, {
        storagePointToId: this.getStoragePointToId()
      });
    }

    if (this.getDestinationFromId()) {
      result = Object.assign({}, result, {
        destinationFromId: this.getDestinationFromId()
      });
    }

    if (this.getDestinationToId()) {
      result = Object.assign({}, result, {
        destinationToId: this.getDestinationToId()
      });
    }

    if (this.getRatings()) {
      result = Object.assign({}, result, {
        ratings: this.getRatings()
      });
    }

    return result;
  };

  Order.prototype.getId = function () {
    return this.id;
  };

  Order.prototype.getOrderStatus = function () {
    return this.orderStatus;
  };

  Order.prototype.getTotalPrice = function () {
    return this.total;
  };

  Order.prototype.getTotalPriceWithDiscount = function () {
    return this.totalWithDiscount;
  };

  Order.prototype.getPayment = function () {
    return this.payment;
  };

  Order.prototype.getClientId = function () {
    return this.clientId;
  };

  Order.prototype.getServices = function () {
    return this.services;
  };

  Order.prototype.getDiscountId = function () {
    return this.discountId;
  };

  Order.prototype.getStoragePointId = function () {
    return this.storagePointId;
  };

  Order.prototype.getImages = function () {
    return this.images;
  };

  Order.prototype.getPickupDate = function () {
    return this.pickupDate;
  };

  Order.prototype.getCreatedDate = function () {
    return this.createdOn;
  };

  Order.prototype.getAddressFrom = function () {
    return this.addressFrom;
  };

  Order.prototype.getAddressTo = function () {
    return this.addressTo;
  };

  Order.prototype.isNew = function () {
    return this.newOrder;
  };

  Order.prototype.getStoragePointFromId = function () {
    return this.storagePointFromId;
  };

  Order.prototype.getStoragePointToId = function () {
    return this.storagePointToId;
  };

  Order.prototype.getDestinationFromId = function () {
    return this.destinationFromId;
  };

  Order.prototype.getDestinationToId = function () {
    return this.destinationToId;
  };

  Order.prototype.getEtaDate = function () {
    return this.etaDate;
  };

  Order.prototype.getShortId = function () {
    return this.shortId;
  };

  Order.prototype.getRatings = function () {
    return this.ratings;
  };

  return Order;
}(Model_1.Model);

exports.Order = Order;
import React from "react";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {InputText} from "primereact/inputtext";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {CostPerDirection} from "./TypedInputComponent";
import {
    requestDirectionsMap,
    CountableResponse,
    ICountableResponse,
    AuthorizedRequestData,
    Direction,
    IDirection, Bind
} from "@shift-mono/common";

interface IDirectionCostSelectorComponentProps {
    getToken: () => Promise<string>;
    selectedDirections: CostPerDirection[],
    changeSelectedDirectionHandler: (selectedDirections: CostPerDirection[]) => void
}

interface IDirectionCostSelectorComponentState {
    allDirections: Map<string, IDirection>,
    unselectedDirections: Map<string, IDirection>,
    directionsCount: number,

    dialogVisible: boolean,
    dialogCostValue: number,
    dialogDirectionIdValue: string,
}

class DirectionCostSelectorComponent extends React.Component<IDirectionCostSelectorComponentProps,
    IDirectionCostSelectorComponentState> {
    constructor(props: IDirectionCostSelectorComponentProps) {
        super(props);

        this.state = {
            allDirections: new Map(),
            unselectedDirections: new Map(),
            directionsCount: 0,

            dialogVisible: false,
            dialogCostValue: 0,
            dialogDirectionIdValue: "",
        };
    }
    @Bind()
    async directionsRequest(): Promise<ICountableResponse<Map<string, IDirection>>> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token, {}, {
            limit: 0,
            skip: 0,
        });
        try {
            return requestDirectionsMap(request_data);
        } catch (err) {
            return new CountableResponse<Map<string, Direction>>(new Map(), 0);
        }
    }
    @Bind()
    directionsRequestResult(directions: ICountableResponse<Map<string, IDirection>>) {
        const allDirections = directions.getData();
        const unselectedDirections = new Map(allDirections);
        this.props.selectedDirections.forEach((costPerDirection) => {
            unselectedDirections.delete(costPerDirection.getId());
        });

        this.setState({
            allDirections: allDirections,
            unselectedDirections: unselectedDirections,
            directionsCount: directions.getObjectsCount()
        });
    }
    @Bind()
    selectedDirectionAction(costPerDirection: CostPerDirection) {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        this.removeCostPerDirection(costPerDirection);
                    }}
                />
            </>
        );
    }
    @Bind()
    unselectedDirectionAction(direction: IDirection) {
        return (
            <>
                <Button
                    type="button"
                    icon="pi pi-plus"
                    className="p-button-success"
                    onClick={() => {
                        this.showDialog(direction.getId());
                    }}
                />
            </>
        );
    }
    @Bind()
    removeCostPerDirection(costPerDirection: CostPerDirection) {
        const updatedSelectedPricePerDirectionList = this.props.selectedDirections.filter((selectedPricePerDirection: CostPerDirection) => {
            return selectedPricePerDirection.getId() !== costPerDirection.getId();
        });

        this.props.changeSelectedDirectionHandler(updatedSelectedPricePerDirectionList);
    }
    @Bind()
    addCostPerDirection(directionId: string, directionCost: number) {
        const costPerDirection = new CostPerDirection(directionCost, directionId);
        this.props.selectedDirections.push(costPerDirection);
        this.props.changeSelectedDirectionHandler(
            this.props.selectedDirections
        );
    }
    @Bind()
    showDialog(directionId: string) {
        this.setState({
            dialogVisible: true,
            dialogCostValue: 0,
            dialogDirectionIdValue: directionId,
        })
    }
    @Bind()
    onHideDialog() {
        this.setState({
            dialogVisible: false,
            dialogCostValue: 0,
            dialogDirectionIdValue: "",
        })
    }
    @Bind()
    renderDialogFooter() {
        return (<div>
            <Button label="Добавить" icon="pi pi-check" disabled={!isFinite(this.state.dialogCostValue)}
                    onClick={() => {
                        this.addCostPerDirection(this.state.dialogDirectionIdValue, this.state.dialogCostValue);
                        this.onHideDialog()
                    }}/>
            <Button label="Отмена" icon="pi pi-times" onClick={() => this.onHideDialog()}
                    className="p-button-secondary"/>
        </div>)
    }

    static getDerivedStateFromProps(props: IDirectionCostSelectorComponentProps, state: IDirectionCostSelectorComponentState) {
        const allDirections = state.allDirections;
        const unselectedDirections = new Map(allDirections);
        props.selectedDirections.forEach((costPerDirection) => {
            unselectedDirections.delete(costPerDirection.getId());
        });
        state.unselectedDirections = unselectedDirections;
        return state;
    }

    render() {
        return <>
            <ContentLoaderComponent<ICountableResponse<Map<string, IDirection>>>
                contentRequest={this.directionsRequest}
                resultCallback={this.directionsRequestResult}
            >
                <Dialog header="" visible={this.state.dialogVisible} style={{width: '50vw'}}
                        onHide={() => this.onHideDialog()} footer={this.renderDialogFooter()}>
                    <span>Цена направления: <InputText
                        type={"number"}
                        value={this.state.dialogCostValue}
                        onChange={(e) => {
                            const newCost = Number.parseFloat(e.currentTarget.value);
                            if (isFinite(newCost)) {
                                this.setState({dialogCostValue: newCost});
                            } else {
                                this.setState({dialogCostValue: 0});
                            }
                        }}/>
                    </span>
                </Dialog>

                <div>Выбранные направления</div>
                <DataTable
                    className="main_list_table mt-2 mb-3"
                    value={this.props.selectedDirections}
                    paginator={true}
                    rows={10}
                >
                    <Column body={(costPerDirection: CostPerDirection) => {
                        let direction = this.state.allDirections.get(costPerDirection.getId());
                        return `${direction ? direction.getName().trim() === "" ? direction.getId() : direction.getName() : costPerDirection.getId()}`
                    }} header="Выбранные направления"/>
                    <Column
                        body={(costPerDirection: CostPerDirection) => {
                            return <InputText
                                type={"number"}
                                style={{width: "100%"}}
                                value={costPerDirection.getCost()}
                                onChange={(e) => {
                                    this.props.changeSelectedDirectionHandler(
                                        this.props.selectedDirections.map((item: CostPerDirection) => {
                                            if (item.getId() === costPerDirection.getId()) {
                                                item.setCost(Number.parseFloat(e.currentTarget.value));
                                            }
                                            return item
                                        })
                                    );
                                }}/>
                        }}
                        header="Цена"/>
                    <Column body={this.selectedDirectionAction} style={{width: "4em"}}/>
                </DataTable>
                <div>Все направления</div>
                <DataTable
                    className="main_list_table mt-2 mb-3"
                    value={Array.from(this.state.unselectedDirections.values())}
                    paginator={true}
                    rows={10}
                >
                    <Column body={(direction: IDirection) => {
                        return direction.getName().trim() === "" ? direction.getId() : direction.getName();
                    }} header="Все направления"/>
                    <Column body={this.unselectedDirectionAction} style={{width: "4em"}}/>
                </DataTable>
            </ContentLoaderComponent>
        </>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    }
};

export default connect(null, mapDispatchToProps)(DirectionCostSelectorComponent);
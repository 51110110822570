import React, { Component } from "react";
import "./MenuSidebarComponentStyle.scss";
import {
  NavLink,
  withRouter,
  RouteComponentProps,
  matchPath
} from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import RouteURLs from "../../actions/routesURL";

interface MenuComponentProps extends RouteComponentProps<any> {
  location: any;
}
interface MenuComponentState {
  isVisible: boolean;
  selectedItem: number;
  menuItems: any[];

  maxTopMargin: number;
  currentTopMargin: number;
}

class MenuComponent extends Component<MenuComponentProps, MenuComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isVisible: false,
      selectedItem: 1,
      menuItems: [
        {
          iconClass: "pi-shopping-cart",
          title: "Заказы",
          url: RouteURLs.orders,
          number: 1,
          isActive: this.matchUrl(RouteURLs.orders)
        },
        {
          iconClass: "pi-envelope",
          title: "Новости",
          url: RouteURLs.news,
          number: 2,
          isActive: this.matchUrl(RouteURLs.news)
        },
        {
          iconClass: "pi-star",
          title: "Скидки",
          url: RouteURLs.discounts,
          number: 3,
          isActive: this.matchUrl(RouteURLs.discounts)
        },
        {
          iconClass: "pi-users",
          title: "Пользователи",
          url: RouteURLs.users,
          number: 4,
          isActive: this.matchUrl(RouteURLs.users)
        },
        {
          iconClass: "pi-briefcase",
          title: "Услуги",
          url: RouteURLs.services,
          number: 5,
          isActive: this.matchUrl(RouteURLs.services)
        },
        {
          iconClass: "pi-home",
          title: "Города/Направления",
          url: RouteURLs.destinations,
          number: 6,
          isActive: this.matchUrl(RouteURLs.destinations)
        },
        {
          iconClass: "pi-th-large",
          title: "Пункты хранения",
          url: RouteURLs.storages,
          number: 7,
          isActive: this.matchUrl(RouteURLs.storages)
        },
        {
          iconClass: "pi-mobile",
          title: "Моб приложение",
          url: RouteURLs.mobileAppSettings,
          number: 8,
          isActive: this.matchUrl(RouteURLs.mobileAppSettings)
        }
      ],

      maxTopMargin: 70,
      currentTopMargin: 70
    };

    this.calcTopMargin = this.calcTopMargin.bind(this);
    this.setTopMargin = this.setTopMargin.bind(this);
  }

  matchUrl = (url: string) => {
    const currentLocation = this.props.location.pathname;
    const escapedPath = url && url.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
    return escapedPath
      ? matchPath(currentLocation, {
          path: escapedPath
        })
      : null;
  };

  openNav = () => {
    this.setState({ isVisible: true });
  };

  closeNav = () => {
    this.setState({ isVisible: false });
  };

  setActiveItem = (itemNumber: number) => {
    this.setState({ selectedItem: itemNumber });
  };

  calcTopMargin(pageYOffet: number): number {
    return pageYOffet > this.state.maxTopMargin
      ? 0
      : this.state.maxTopMargin - pageYOffet;
  }

  setTopMargin(){
    this.setState({
      currentTopMargin: this.calcTopMargin(window.pageYOffset)
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.setTopMargin);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.setTopMargin);
  }

  render() {
    return (
      <div
        className="menu-sidebar"
        onMouseEnter={this.openNav}
        onMouseLeave={this.closeNav}
      >
        <Sidebar
          visible={this.state.isVisible}
          baseZIndex={10}
          modal={false}
          onHide={() => this.closeNav()}
          showCloseIcon={false}
          style={{ marginTop: this.state.currentTopMargin + "px" }}
        >
          <div id="menu-sidebar__bar-field" className="menu-sidebar__bar-field">
            {this.state.menuItems.map((item: any) => {
              return item.visible === undefined || item.visible ? (
                <NavLink
                  to={item.url}
                  onClick={() => {
                    this.setActiveItem(item.number);
                  }}
                  key={item.number}
                >
                  <span>{item.title}</span>
                </NavLink>
              ) : (
                ""
              );
            })}
          </div>
        </Sidebar>

        <img
          className="menu-sidebar__menu-button"
          src={process.env.PUBLIC_URL + "/images/logo.svg"}
          alt="slideMenuIcons"
        />

        <div
          className="sidebar-panel d-none d-sm-block"
          style={{ marginTop: this.state.currentTopMargin + "px" }}
        >
          {this.state.menuItems.map((item: any) => {
            return item.visible === undefined || item.visible ? (
              <i
                className={
                  "pi " +
                  (item.iconClass ? item.iconClass : "pi-th-large") +
                  (item.isActive ? " active" : "")
                }
                key={item.number}
              />
            ) : (
              ""
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(MenuComponent);

import React from "react";
import {Growl} from "primereact/growl";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {
    AuthorizedRequestData,
    Bind, CountableResponse, ICountableResponse, IPartner,
    IUser, putUserRequest, requestPartners,
    requestUserById, resetAccountPass, UserRequestData,
} from "@shift-mono/common";
import BackButtonComponent from "../../../common/BackButtonComponent";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import {Button} from "primereact/button";
import PartnerPicker from "./partials/PartnerPicker";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";

interface IAccountDetailComponentProps {
    getToken: () => Promise<string>;
    match: any;
}

interface IAccountDetailComponentState {
    currentAccount: IUser | undefined;
    availablePartners: IPartner[];
    currentPartnerId: string | undefined;
    resetDialogVisible: boolean;

    newPass: string;
    confirmNewPass: string;
}


class AccountDetailComponent extends React.Component<IAccountDetailComponentProps, IAccountDetailComponentState> {
    private growl: any = undefined;
    private loaderRef: any = undefined;

    constructor(props: IAccountDetailComponentProps) {
        super(props);

        this.state = {
            currentAccount: undefined,
            availablePartners: [],
            currentPartnerId: undefined,
            resetDialogVisible: false,
            newPass: "",
            confirmNewPass: "",
        }
    }

    @Bind()
    async accountRequest(): Promise<IUser | undefined> {
        const token = await this.props.getToken();
        const id = this.props.match.params.id;
        const requestData = new AuthorizedRequestData(token);

        try {
            return (await requestUserById(id, requestData)).getData();
        } catch (err) {
        }
    }

    @Bind()
    accountRequestResult(account: IUser | undefined) {
        this.setState({
            currentAccount: account,
            currentPartnerId: account ? account.getPartnerId() : undefined
        })
    }

    @Bind()
    async partnersRequest(): Promise<ICountableResponse<IPartner[]>> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);

        try {
            return requestPartners(requestData);
        } catch (err) {
            return new CountableResponse([], 0);
        }
    }

    @Bind()
    partnersRequestResult(partners: ICountableResponse<IPartner[]>) {
        this.setState({
            availablePartners: partners.getData()
        })
    }

    @Bind()
    saveButtonHandler() {
        this.updateUserAgentStatusRequest();
    }

    @Bind()
    async updateUserAgentStatusRequest() {
        if (!this.state.currentAccount) {
            return
        }
        try {
            const token = await this.props.getToken();
            const requestData = new UserRequestData({
                accessToken: token,
                partnerId: this.state.currentPartnerId ? this.state.currentPartnerId : "",
                id: this.state.currentAccount!.getId()
            });
            if (await putUserRequest(requestData)) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Аккаунт обновлен"
                });
                this.reloadContent();
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка обновления аккаунта"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка обновления аккаунта"
            });
        }
    }

    @Bind()
    resetPassHandler() {
        this.showResetDialog()
    }

    @Bind()
    renderResetPassDialog() {
        return (<Dialog
            header="Введите новый пароль"
            footer={this.resetDialogFooterTemplate()}
            visible={this.state.resetDialogVisible}
            style={{width: "50vw"}}
            modal={true}
            onHide={() => {
                this.hideResetDialog();
            }}
        >
            <div className="row m-1">
                {/*<label className="col-lg-4">Новый пароль</label>*/}
                <InputText
                    value={this.state.newPass}
                    className="col-lg-4"
                    placeholder="Новый пароль"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({newPass: e.target.value});
                    }
                    }
                />
            </div>
            <div className="row m-1">
                {/*<label className="col-lg-4">Подтвеждение пароля</label>*/}
                <InputText
                    value={this.state.confirmNewPass}
                    className="col-lg-4"
                    placeholder="Подтвеждение пароля"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({confirmNewPass: e.target.value});
                    }
                    }
                />
            </div>
        </Dialog>)
    }

    @Bind()
    showResetDialog() {
        this.setState({resetDialogVisible: true})
    }

    @Bind()
    hideResetDialog() {
        this.setState({resetDialogVisible: false})
    }

    @Bind()
    isOkDialogButtonEnabled() {
        const newPass = this.state.newPass;
        const confirmNewPass = this.state.confirmNewPass;
        if (newPass.trim() === "" || confirmNewPass.trim() === "") {
            return false
        }

        return newPass.trim() === confirmNewPass.trim()
    }

    resetDialogFooterTemplate() {
        return (<div>
            <Button
                disabled={!this.isOkDialogButtonEnabled()}
                label="Да"
                icon="pi pi-check"
                onClick={() => {
                    this.resetPassRequest();
                    this.hideResetDialog();
                }}
            />
            <Button
                label="Нет"
                icon="pi pi-times"
                onClick={() => {
                    this.hideResetDialog();
                    this.resetNewPassState();
                }}
            />
        </div>)
    }

    @Bind()
    resetNewPassState() {
        this.setState({
            newPass: "",
            confirmNewPass: ""
        })
    }

    @Bind()
    reloadContent() {
        if (this.loaderRef && this.loaderRef.tryToLoading) {
            this.loaderRef.tryToLoading();
        }
    }

    @Bind()
    renderUserInfo() {
        return (<>
                <div className="row m-1">
                    <label className="col-md-4">Логин:</label>
                    <label className="col-md-4">
                        {this.state.currentAccount
                            ? this.getDefaultTitleIfEmpty(this.state.currentAccount.getUsername())
                            : ""}
                    </label>
                </div>
                <div className="row m-1">
                    <label className="col-md-4">ФИО:</label>
                    <label className="col-md-4">
                        {this.state.currentAccount
                            ? this.getDefaultTitleIfEmpty(this.state.currentAccount.getFullName())
                            : ""}
                    </label>
                </div>

                <div className="row m-1">
                    <label className="col-md-4">Телефон:</label>
                    <label className="col-md-4">
                        {this.state.currentAccount
                            ? this.getDefaultTitleIfEmpty(this.state.currentAccount.getPhone())
                            : ""}
                    </label>
                </div>

                <div className="row m-1">
                    <label className="col-md-4">Email:</label>
                    <label className="col-md-4">
                        {this.state.currentAccount
                            ? this.getDefaultTitleIfEmpty(this.state.currentAccount.getEmail())
                            : ""}
                    </label>
                </div>

            </>
        )
    }

    @Bind()
    async resetPassRequest() {
        if (!this.state.currentAccount
            || this.state.newPass.trim() === ""
            || this.state.confirmNewPass.trim() === "") {
            return
        }
        try {
            const token = await this.props.getToken();
            const requestData = new AuthorizedRequestData(token);
            const accountId = this.state.currentAccount.getId();
            const pass = this.state.newPass;

            if (await resetAccountPass(accountId, pass, requestData)) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Пароль обновлен"
                });
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка обновления пароля"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка обновления пароля"
            });
        }

        this.resetNewPassState();
    }

    getDefaultTitleIfEmpty(value: string | undefined) {
        if (!value) {
            return "Нет данных"
        }
        return value.trim() === "" ? "Нет данных" : value;
    }

    render() {
        return (<>
            <Growl ref={el => (this.growl = el)}/>
            <ContentLoaderComponent<IUser | undefined>
                contentRequest={this.accountRequest}
                resultCallback={this.accountRequestResult}
                ref={el => {
                    this.loaderRef = el;
                }}
            >
                <ContentLoaderComponent<ICountableResponse<IPartner[]>>
                    contentRequest={this.partnersRequest}
                    resultCallback={this.partnersRequestResult}
                >
                    {this.renderResetPassDialog()}
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>
                                <BackButtonComponent/> Информация об аккаунте
                            </h3>
                        </div>
                    </div>
                    <form
                        className="card col-12 mt-2 p-2"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                        }}
                    >
                        {this.renderUserInfo()}
                        <div className="row m-1">
                            <label className="col-md-4">Партнер:</label>
                            <label className="col-md-4">
                                <PartnerPicker
                                    availablePartners={this.state.availablePartners}
                                    currentPartnerId={this.state.currentPartnerId}
                                    changeHandler={(partner) => {
                                        this.setState({
                                            currentPartnerId: partner ? partner!.getId() : undefined
                                        })
                                    }}/>
                            </label>
                        </div>
                        <div className="row m-1 ml-2">
                            <Button
                                label={"Сохранить"}
                                onClick={() => this.saveButtonHandler()}
                            />
                            <Button
                                className="p-button-danger ml-2"
                                label={"Сбросить пароль"}
                                onClick={() => this.resetPassHandler()}
                            />
                        </div>
                    </form>
                </ContentLoaderComponent>
            </ContentLoaderComponent>
        </>)
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(AccountDetailComponent);
"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Profile = void 0;

var Model_1 = require("./Model");

var errors_1 = require("../errors");

var Profile =
/** @class */
function (_super) {
  __extends(Profile, _super);

  function Profile(args) {
    var _this = _super.call(this) || this;

    _this.arguments = args;
    return _this;
  }

  Profile.fromJson = function (data) {
    var requiredProps = ["id", "partnerId"];
    var verifyResult = Profile.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    return new Profile({
      id: data.id,
      partnerId: data.partnerId
    });
  };

  Profile.prototype.getId = function () {
    return this.arguments.id;
  };

  Profile.prototype.getPartnerId = function () {
    return this.arguments.partnerId;
  };

  return Profile;
}(Model_1.Model);

exports.Profile = Profile;
import React from "react";
import {connect} from "react-redux";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {
    AuthorizedRequestData, Bind,
    CountableResponse,
    ICountableResponse,
    IDestination,
    requestDestinations
} from "@shift-mono/common";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {getAccessToken} from "../../../helpers/RequestHelpers";

interface IDirectionsFilterComponentProps {
    getToken: () => Promise<string>;
    callbackHandler: (data: ICallbackData) => void;
}

interface IDirectionsFilterComponentState extends ICallbackData {
    destinations: IDestination[],
}

interface ICallbackData {
    firstDestination: IDestination | undefined,
    secondDestination: IDestination | undefined,
}

class DirectionsFilterComponent extends React.Component<IDirectionsFilterComponentProps,
    IDirectionsFilterComponentState> {
    private readonly firstDestName = "first";
    private readonly secondDestName = "second";

    constructor(props: IDirectionsFilterComponentProps) {
        super(props);

        this.state = {
            destinations: [],

            firstDestination: undefined,
            secondDestination: undefined
        }
    }
    @Bind()
    async destinationsRequest(): Promise<ICountableResponse<IDestination[]>> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token, {}, {limit: 0, skip: 0});

        try {
            return requestDestinations(request_data);
        } catch (err) {
            return new CountableResponse<IDestination[]>([], 0);
        }
    }
    @Bind()
    destinationRequestResult(destinations: ICountableResponse<IDestination[]>) {
        this.setState({destinations: destinations.getData()});
    }
    @Bind()
    destinationChanged(e: any) {
        switch (e.target.name) {
            case this.firstDestName:
                this.setState({firstDestination: e.value});
                break;
            case this.secondDestName:
                this.setState({secondDestination: e.value});
                break;
        }
    }

    renderClearButton(name: string) {
        const clear = (name: string) => {
            switch (name) {
                case this.firstDestName:
                    this.setState({firstDestination: undefined});
                    break;
                case this.secondDestName:
                    this.setState({secondDestination: undefined});
                    break;
            }
        };

        return (
            <div className="col-lg-1">
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={() => {
                        clear(name);
                    }}
                />
            </div>
        )
    }

    render() {
        return <>
            <ContentLoaderComponent<ICountableResponse<IDestination[]>>
                contentRequest={this.destinationsRequest}
                resultCallback={this.destinationRequestResult}
            >
                <form
                    className="card mt-2 p-2"
                    onSubmit={(e: any) => {
                        e.preventDefault();
                    }}
                >
                    <div className="row m-1">
                        <label className={"col-lg-5"}><b>Фильтр по городам</b></label>
                    </div>
                    <div className="row m-1">
                        <label className={"col-lg-5"}>Город откуда</label>
                        <Dropdown
                            className={"col-lg-5"}
                            value={this.state.firstDestination}
                            options={this.state.destinations}
                            onChange={this.destinationChanged}
                            placeholder="Место назначения"
                            dataKey="id"
                            optionLabel="title"
                            name={this.firstDestName}
                        />
                        {this.state.firstDestination ? this.renderClearButton(this.firstDestName) : <></>}
                    </div>
                    <div className="row m-1">
                        <label className={"col-lg-5"}>Город куда</label>
                        <Dropdown
                            className={"col-lg-5"}
                            value={this.state.secondDestination}
                            options={this.state.destinations}
                            onChange={this.destinationChanged}
                            placeholder="Место назначения"
                            dataKey="id"
                            optionLabel="title"
                            name={this.secondDestName}
                        />
                        {this.state.secondDestination ? this.renderClearButton(this.secondDestName) : <></>}
                    </div>

                    <div className="row m-1 ml-2">
                        <Button
                            label={"Отфильтровать"}
                            onClick={() => {
                                this.props.callbackHandler({
                                    firstDestination: this.state.firstDestination,
                                    secondDestination: this.state.secondDestination
                                });
                            }}
                        />
                    </div>
                </form>
            </ContentLoaderComponent>
        </>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(DirectionsFilterComponent);
import React, {Fragment} from "react";
import ServiceListComponent from "./ServiceListComponent";
import AddServiceFormComponent from "./AddServiceFormComponent";
import TabMenuComponent, {ITabItem} from "../../common/TabMenu/TabMenuComponent";

interface IServiceComponentProps {
}

interface IServiceComponentState {
    items: ITabItem[];
}

export default class ServiceComponent extends React.Component<IServiceComponentProps,
    IServiceComponentState> {
    constructor(props: IServiceComponentProps) {
        super(props);
        this.state = {
            items: [
                {
                    id: 1,
                    label: "Список услуг",
                    component: <ServiceListComponent/>,
                },
                {
                    id: 2,
                    label: "Добавить услугу",
                    component: <AddServiceFormComponent/>,
                },
            ],
        };
    }

    render() {
        return (
            <Fragment>
                <TabMenuComponent
                    items={this.state.items}
                />
            </Fragment>
        );
    }
}

import React from "react";
import NewsFormComponent, {
  INewsFormData
} from "./add_news_parts/NewsFormComponent";
import { connect } from "react-redux";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import { Growl } from "primereact/growl";
import {
  postImage,
  postNewsRequest,
  NewsRequestData, Bind
} from "@shift-mono/common";

interface IAddNewsComponentProps {
  getToken: () => Promise<string>;
}
interface IAddNewsComponentState {}

class AddNewsComponent extends React.Component<
  IAddNewsComponentProps,
  IAddNewsComponentState
> {
  private growl: any = undefined;
  private formRef: any = undefined;

  validateFormData(data: INewsFormData): boolean {
    if (data.newsTitle.trim() === "" || data.newsContent.trim() === "") {
      return false;
    }

    return true;
  }
  @Bind()
  async sendNews(data: INewsFormData) {
    const token = await this.props.getToken();
    if (!this.validateFormData(data)) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не все поля заполнены"
      });
      return;
    }
    try {
      let imageUrl = "";
      if (data.imageFile !== null) {
        const imageResult = await postImage(token, data.imageFile);
        if (imageResult === null) {
          this.growl.show({
            severity: "error",
            summary: "Ошибка",
            detail: "Не удалось загрузить изображение"
          });
          return;
        }
        imageUrl = imageResult.url;
      }

      const newsAdded = await postNewsRequest(
        new NewsRequestData(
          {
            accessToken: token,
            newsTitle: data.newsTitle,
            newsContent: data.newsContent,
            tags: data.tags,
            imageUrl: imageUrl,
          }
        )
      );

      if (newsAdded) {
        this.growl.show({
          severity: "success",
          summary: "Успешно",
          detail: "Новость добавлен"
        });
        if (this.formRef) {
          this.formRef.clearForm();
        }
      } else {
        this.growl.show({
          severity: "error",
          summary: "Ошибка",
          detail: "Ошибка добавления"
        });
      }
    } catch (err) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка добавления"
      });
      return;
    }
  }

  render() {
    return (
      <>
        <Growl ref={el => (this.growl = el)} />
        <NewsFormComponent
          ref={el => {
            this.formRef = el;
          }}
          saveCallback={(formData: INewsFormData) => {
            this.sendNews(formData);
          }}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => dispatch(getAccessToken())
  };
};

export default connect(null, mapDispatchToProps)(AddNewsComponent);

import C from "../actionsTypes";

//PAGE STATE ### START
export const setRequestEntitiesStatusToComponent = (
  component_title: string
) => {
  return {
    type: C.COMPONENT_STATE.REQUEST_ENTITIES,
    page_title: component_title
  };
};

export const setFailureRequestStatusToComponent = (
  component_title: string,
  message: string = ""
) => {
  return {
    type: C.COMPONENT_STATE.REQUEST_ENTITIES_FAILURE,
    page_title: component_title,
    error_message: message
  };
};

export const setSuccessRequestStatusToComponent = (component_title: string) => {
  return {
    type: C.COMPONENT_STATE.REQUEST_ENTITIES_SUCCESS,
    page_title: component_title,
    error_message: ""
  };
};

export const setReceiveEntitiesToComponent = (
  component_title: string,
  entitie_ids: string[]
) => {
  return {
    type: C.COMPONENT_STATE.RECEIVE_ENTITIES,
    page_title: component_title,
    entities: entitie_ids
  };
};

export const clearComponentState = (component_title: string) => {
  return {
    type: C.COMPONENT_STATE.CLEAR,
    page_title: component_title
  };
};

export const COMPONENT_STATE_STATUS = {
  ERROR: "error",
  SUCCESS: "success"
};

//PAGE STATE ### END

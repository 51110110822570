import React from "react";
import PageTemplate from "./PageTemplate";
import RouteURLs from "../actions/routesURL";
import { Route } from "react-router-dom";
import NotificationComponent from "../components/content/notification/NotificationComponent";


const NotificationsPage = () => {
    return (
        <PageTemplate>
            <Route exact path={RouteURLs.notifications} component={NotificationComponent} />
        </PageTemplate>
    );
};

export default NotificationsPage;
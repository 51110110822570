"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationObjectType = void 0;
var NotificationObjectType;

(function (NotificationObjectType) {
  NotificationObjectType["Order"] = "order";
  NotificationObjectType["Shipment"] = "shipment";
  NotificationObjectType["Payment"] = "payment";
})(NotificationObjectType = exports.NotificationObjectType || (exports.NotificationObjectType = {}));
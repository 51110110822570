import React from "react";
import LoadingComponent from "./LoadingComponent";
import MessageComponent from "./MessageComponent";

interface IContentLoaderComponentProps<T> {
  contentRequest: () => Promise<T>;
  children: any;
  resultCallback?: ((result: T) => void) | undefined;
  errorCallback?: ((error: T) => void) | undefined;
}
interface IContentLoaderComponentState {
  loading: boolean;
  failed: boolean;
}

export default class ContentLoaderComponent<T> extends React.Component<
  IContentLoaderComponentProps<T>,
  IContentLoaderComponentState
> {
  private _isMounted = false;

  constructor(props: IContentLoaderComponentProps<T>) {
    super(props);
    this.state = {
      loading: true,
      failed: false
    };

    this.tryToLoading = this.tryToLoading.bind(this);
  }

  tryToLoading() {
    this.setState({ loading: true, failed: false });
    if (this.props.contentRequest) {
      this.props
        .contentRequest()
        .then(result => {
          if (this._isMounted) {
            this.setState({ loading: false, failed: false });
            if (this.props.resultCallback) {
              this.props.resultCallback(result);
            }
          }
        })
        .catch(err => {
          if (this._isMounted) {
            this.setState({ loading: false, failed: true });
            if (this.props.errorCallback) {
              this.props.errorCallback(err);
            }
          }
        });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.tryToLoading();
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  render() {
    if (!this.state.loading && this.state.failed) {
      return <MessageComponent message="Ошибка загрузки" />;
    }
    if (!this.state.loading) {
      return this.props.children;
    }
    return (
      <div className="mt-5 d-flex justify-content-center w-100">
        <LoadingComponent />
      </div>
    );
  }
}

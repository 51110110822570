import React from "react";
import {connect} from "react-redux";
import {Growl} from "primereact/growl";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import DirectionFormComponent, {
    IDirectionFormData
} from "./form_partials/DirectionFormComponent";
import {
    postDirectionRequest,
    DirectionRequestData, Bind
} from "@shift-mono/common";

interface IAddDirectionFormComponentProps {
    getToken: () => Promise<string>;
    directionAddedCallback?: () => void;
}

interface IAddDirectionFormComponentState {
    formInvalid: boolean;
}

class AddDirectionFormComponent extends React.Component<IAddDirectionFormComponentProps,
    IAddDirectionFormComponentState> {
    private growl: any = undefined;
    private formRef: any = undefined;

    constructor(props: IAddDirectionFormComponentProps) {
        super(props);
        this.state = {
            formInvalid: false
        };
    }
    @Bind()
    async sendDirection(formData: IDirectionFormData) {
        try {
            const token = await this.props.getToken();
            const name = formData.name;
            const localizedName = formData.localizedName;
            const firstDirection = formData.firstDestination;
            const secondDirection = formData.secondDestination;
            const deliveryDuration = formData.deliveryDuration;

            if (!this.validateForm(formData)) {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Не все поля заполнены!"
                });
                return;
            }

            const requst_data = new DirectionRequestData({
                accessToken: token,
                name: name,
                localizedName: localizedName,
                vertices: [
                    firstDirection!.getId(),
                    secondDirection!.getId()
                ],
                deliveryDuration: deliveryDuration
            });
            const direction_added = await postDirectionRequest(requst_data);
            if (direction_added) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Направление добавлено"
                });
                if (this.formRef) {
                    this.formRef.clearForm();
                }
                if (this.props.directionAddedCallback) {
                    this.props.directionAddedCallback();
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка добавления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
        }
    }

    validateForm(formData: IDirectionFormData): boolean {
        if (formData.name.trim() === "") {
            return false;
        }
        if (formData.firstDestination === undefined) {
            return false;
        }
        if (formData.secondDestination === undefined) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <>
                <Growl ref={el => (this.growl = el)}/>
                <DirectionFormComponent
                    ref={el => {
                        this.formRef = el;
                    }}
                    saveCallback={formData => {
                        this.sendDirection(formData);
                    }}
                />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(AddDirectionFormComponent);

"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderPayment = void 0;

var Model_1 = require("./Model");

var OrderPayment =
/** @class */
function (_super) {
  __extends(OrderPayment, _super);

  function OrderPayment(status, yandexKassaId) {
    var _this = _super.call(this) || this;

    _this.status = status;
    _this.yandexKassaId = yandexKassaId;

    _this.getStatus = function () {
      return _this.status;
    };

    _this.getYandexKassaId = function () {
      return _this.yandexKassaId;
    };

    return _this;
  }

  OrderPayment.fromJson = function (data) {
    return new OrderPayment(data.status ? data.status : "", data.yaKassaId ? data.yaKassaId : "");
  };

  OrderPayment.prototype.toJson = function () {
    return {
      status: this.getStatus(),
      yandexKassaId: this.getYandexKassaId()
    };
  };

  return OrderPayment;
}(Model_1.Model);

exports.OrderPayment = OrderPayment;
"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServiceRequestData = void 0;

var AuthorizedRequestData_1 = require("./AuthorizedRequestData");

var models_1 = require("../../models");

var ServiceRequestData =
/** @class */
function (_super) {
  __extends(ServiceRequestData, _super);

  function ServiceRequestData(args) {
    var _this = _super.call(this, args.accessToken) || this;

    if (!args.currency) {
      args.currency = models_1.Currency.Rub;
    }

    if (!args.paymentType) {
      args.paymentType = models_1.ServicePaymentType.OneTime;
    }

    _this.arguments = args;
    return _this;
  } //TODO Переименновать поле localizedNames на localizedName при изменении его в api


  ServiceRequestData.prototype.getData = function () {
    var result = Object.assign({}, _super.prototype.getData.call(this), {});

    switch (this.arguments.serviceType) {
      case models_1.ServiceType.Store:
        if (this.arguments.monthsCost) {
          result = Object.assign({}, result, {
            serviceType: this.arguments.serviceType,
            name: this.arguments.name,
            localizedNames: models_1.convertToObject(this.arguments.localizedName),
            currency: this.arguments.currency,
            paymentType: this.arguments.paymentType,
            showInCoursel: this.arguments.showInCarousel,
            discountApplicable: this.arguments.discountApplicable,
            monthCost: this.arguments.monthsCost.reduce(function (prev_item, currentItem) {
              var _a;

              return Object.assign({}, prev_item, (_a = {}, _a[currentItem.getMonthCount()] = currentItem.getCost(), _a));
            }, {})
          });
        }

        break;

      case models_1.ServiceType.Luggage:
      case models_1.ServiceType.Move:
        if (this.arguments.directionsCost) {
          result = Object.assign({}, result, {
            serviceType: this.arguments.serviceType,
            name: this.arguments.name,
            localizedNames: models_1.convertToObject(this.arguments.localizedName),
            currency: this.arguments.currency,
            paymentType: this.arguments.paymentType,
            showInCoursel: this.arguments.showInCarousel,
            discountApplicable: this.arguments.discountApplicable,
            directionCost: this.arguments.directionsCost.reduce(function (prev_item, currentItem) {
              var _a;

              return Object.assign({}, prev_item, (_a = {}, _a[currentItem.getId()] = currentItem.getCost(), _a));
            }, {})
          });
        }

        break;

      case models_1.ServiceType.Other:
        if (this.arguments.cost) {
          result = Object.assign({}, result, {
            serviceType: this.arguments.serviceType,
            name: this.arguments.name,
            localizedNames: models_1.convertToObject(this.arguments.localizedName),
            cost: this.arguments.cost,
            currency: this.arguments.currency,
            paymentType: this.arguments.paymentType,
            showInCoursel: this.arguments.showInCarousel,
            discountApplicable: this.arguments.discountApplicable
          });
        }

        break;

      default:
        return {};
    }

    if (this.arguments.serviceId) {
      result = Object.assign({}, result, {
        id: this.arguments.serviceId
      });
    }

    return result;
  };

  ServiceRequestData.prototype.getId = function () {
    return this.arguments.serviceId;
  };

  return ServiceRequestData;
}(AuthorizedRequestData_1.AuthorizedRequestData);

exports.ServiceRequestData = ServiceRequestData;
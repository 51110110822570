import React, {useState} from "react";
import PropTypes from "prop-types";
import LoadingComponent from "../../common/LoadingComponent";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {receiveUserData} from "../../../actions/actionCreators";

const page_name = "auth_page";

const LoginComponent = props => {
    const [loginVal, setLoginVal] = useState("");
    const [passVal, setPassVal] = useState("");

    const [loginHighlighted, setLoginHighlighted] = useState(false);
    const [passHighlighted, setPassHighlighted] = useState(false);

    const handleLoginChange = event => {
        setLoginVal(event.target.value);
    };

    const handlePasswordChange = event => {
        setPassVal(event.target.value);
    };

    let loginRef = React.createRef();
    let passwordRef = React.createRef();

    const tryToAuth = () => {
        if (loginVal !== "" && passVal !== "") {
            setLoginHighlighted(false);
            setPassHighlighted(false);
            props.receiveUserDataAction(loginVal, passVal);
        } else {
            loginVal === "" ? setLoginHighlighted(true) : setLoginHighlighted(false);
            passVal === "" ? setPassHighlighted(true) : setPassHighlighted(false);
        }
    };

    const buttonKeyHandle = (event) => {
        if (event) {
            if (event.key === "Enter") {
                tryToAuth()
            }
        }
    };

    if (props.component_state !== undefined && props.component_state.isFetching === true) {
        return (
            <div className="card col-6 text-center p-5 mt-5">
                <div className="mt-4 d-flex justify-content-center w-100">
                    <LoadingComponent/>
                </div>
            </div>
        );
    } else {
        return (
            <form className="card col-md-6 text-center p-5 mt-5">
                {props.user.is_authorized ? <Redirect to="/"/> : ""}

                <div className={"mb-3"}>
                    <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt={""} style={{width: "125px"}}/>
                </div>

                {props.component_state !== undefined &&
                props.component_state.error_message !== undefined ? (
                    <h5 className="text-danger">{props.component_state.error_message}</h5>
                ) : (
                    ""
                )}

                <div className="form-group">
                    <label>Логин</label>
                    <input
                        type="text"
                        ref={loginRef}
                        className={
                            "form-control " + (loginHighlighted === true ? "is-invalid" : "")
                        }
                        id="auth__login"
                        placeholder="Логин"
                        onChange={handleLoginChange}
                        onKeyPress={buttonKeyHandle}
                    />
                </div>
                <div className="form-group">
                    <label>Пароль</label>
                    <input
                        type="password"
                        ref={passwordRef}
                        className={
                            "form-control " + (passHighlighted === true ? "is-invalid" : "")
                        }
                        id="auth__pass"
                        placeholder="Пароль"
                        onChange={handlePasswordChange}
                        onKeyPress={buttonKeyHandle}
                    />
                </div>
                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn btn-primary col-md-4 mt-2"
                        onClick={tryToAuth}
                    >
                        Вход
                    </button>
                </div>
            </form>
        );
    }
};

LoginComponent.propTypes = {
    receiveUserDataAction: PropTypes.func.isRequired,
    component_state: PropTypes.object,
    user: PropTypes.object
};

const mapStateToProps = state => {
    return {
        user: state.user,
        component_state: state.component_state[page_name]
    };
};

const mapDispatchToProps = dispatch => {
    return {
        receiveUserDataAction: (login, password) => {
            dispatch(receiveUserData(login, password, page_name));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

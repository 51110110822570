import React from "react";
import BackButtonComponent from "../../common/BackButtonComponent";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import ServiceFormComponent, {
  IServiceFormData
} from "./service_form_parts/ServiceFormComponent";
import { connect } from "react-redux";
import { getAccessToken } from "../../../helpers/RequestHelpers";
import { Growl } from "primereact/growl";
import {
  AuthorizedRequestData,
  requestServiceById,
  ServiceRequestData,
  putServiceRequest,
  IService,
  ServiceType, Bind
} from "@shift-mono/common";

interface IServiceDetailComponentProps {
  getToken: () => Promise<string>;
  match: any;
}
interface IServiceDetailComponentState {
  currentService: IService | undefined;
}

class ServiceDetailComponent extends React.Component<
  IServiceDetailComponentProps,
  IServiceDetailComponentState
> {
  growl: any = undefined;

  constructor(props: IServiceDetailComponentProps) {
    super(props);

    this.state = {
      currentService: undefined
    };
  }
  @Bind()
  async serviceRequest(): Promise<IService | undefined> {
    const token = await this.props.getToken();
    const request_data = new AuthorizedRequestData(token);
    const id = this.props.match.params.id;

    return (await requestServiceById(id, request_data)).getData();
  }
  @Bind()
  serviceRequestResult(service: IService | undefined) {
    this.setState({ currentService: service });
  }
  @Bind()
  async sendService(data: IServiceFormData) {
    const token = await this.props.getToken();

    const current_service = this.state.currentService;
    const name = data.serviceName;
    const localizedName = data.localizedName;
    const currency = data.currency;
    const payment_type = data.paymentType;
    const service_type = data.serviceType;
    const cost = data.cost;
    const cost_per_direction_list = data.costPerDirectionList;
    const cost_per_storage_months = data.costPerStorageMonths;
    const showInCarousel = data.showInCarousel;
    const discountApplicable = data.discountApplicable;

    let service_added = false;

    if (
      current_service === undefined ||
      name.trim() === "" ||
      payment_type === null ||
      service_type === null ||
      (service_type === ServiceType.Other && cost <= 0) ||
      ((service_type === ServiceType.Move ||
        service_type === ServiceType.Luggage) &&
        cost_per_direction_list.length <= 0) ||
      (service_type === ServiceType.Store &&
        cost_per_storage_months.length <= 0)
    ) {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Не все поля заполнены"
      });
      return false;
    }

    const service_id = current_service.getId();

    if (payment_type) {
      const request_data = new ServiceRequestData(
          {
            accessToken: token,
            serviceType: service_type,
            name: name,
            localizedName: localizedName,

            serviceId: service_id,

            currency: currency,
            paymentType: payment_type,
            cost: cost,
            directionsCost: cost_per_direction_list,
            monthsCost: cost_per_storage_months,
            showInCarousel: showInCarousel,
            discountApplicable: discountApplicable,
          });

      service_added = await putServiceRequest(request_data);
    }

    if (service_added) {
      this.growl.show({
        severity: "success",
        summary: "Успешно",
        detail: "Услуга обновленна"
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Ошибка",
        detail: "Ошибка обновления"
      });
    }

    return service_added;
  }

  render() {
    return (
      <>
        <Growl ref={el => (this.growl = el)} />
        <ContentLoaderComponent<IService | undefined>
          contentRequest={this.serviceRequest}
          resultCallback={this.serviceRequestResult}
        >
          <div className="row">
            <div className="col-lg-12">
              <h3>
                <BackButtonComponent /> Информация о услуге
              </h3>
            </div>
          </div>
          <ServiceFormComponent
            currentService={this.state.currentService}
            saveCallback={form_data => {
              this.sendService(form_data);
            }}
          />
        </ContentLoaderComponent>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getToken: () => {
      return dispatch(getAccessToken());
    }
  };
};

export default connect(null, mapDispatchToProps)(ServiceDetailComponent);

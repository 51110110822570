const RouteURLs = {
    home: "/",
    services: "/services",
    clients: "/clients",
    orders: "/orders",
    reports: "/reports",
    storages: "/spoints",
    bagtemps: "/bagtemps",
    discounts: "/discounts",
    units: "/units",
    qr: "/qr",
    staff: "/staff",
    staffChanged: "/staffChanged",
    orderDetail: "/orderDetail",
    adminRegistration: "/adminRegistration",
    auth: "/auth",
    couriers: "/couriers",
    courierDetail: "/courierDetail",
    mobileAppSettings: "/mobilesettings",
    news: "/news",
    notifications: "/notifications",
    users: "/users",
    destinations: "/destinations",
    directions: "/directions",
    partner: "/partners",
    accounts: "/accounts"
};

export default RouteURLs;

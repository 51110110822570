import React from "react";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import "./MobileAppSettingsComponentStyle.scss";
import {Growl} from "primereact/growl";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {connect} from "react-redux";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {
    AuthorizedRequestData,
    IMobileAppSettings,
    MobileAppSettings,
    IService,
    DeliveryService,
    StorageService,
    OtherService,
    LuggageService,
    requestMobileAppSettings,
    MobileAppSettingsRequestData,
    putMobileAppSettingsRequest,
    requestServices, Bind
} from "@shift-mono/common";


enum DialogType {
    Warning,
    Delivery,
    Store,
    SkiBags,
    Bicycle,
    StableVersion
}

interface IMobileAppSettingsComponentProps {
    getToken: () => Promise<string>;
}

interface IMobileAppSettingsComponentState {
    current_mobile_settings: IMobileAppSettings | null;
    warning_dialog_visible: boolean;

    stable_version_dialog_visible: boolean;
    store_service_dialog_visible: boolean;
    delivery_service_dialog_visible: boolean;
    ski_bags_service_dialog_visible: boolean;
    bicycle_service_dialog_visible: boolean;

    changes_is_available: boolean;

    tmp_stable_version: string;
    tmp_delivery_service: IService | null;
    tmp_store_service: IService | null;
    tmp_ski_bags_service: IService | null;
    tmp_bicycle_service: IService | null;

    services: IService[];
}

class MobileAppSettingsComponent extends React.Component<IMobileAppSettingsComponentProps,
    IMobileAppSettingsComponentState> {
    private no_data_message = "Нет данных";
    private growl: any = undefined;

    constructor(props: IMobileAppSettingsComponentProps) {
        super(props);
        this.state = {
            current_mobile_settings: null,
            warning_dialog_visible: false,

            stable_version_dialog_visible: false,
            store_service_dialog_visible: false,
            delivery_service_dialog_visible: false,
            ski_bags_service_dialog_visible: false,
            bicycle_service_dialog_visible: false,

            changes_is_available: false,

            tmp_stable_version: "",
            tmp_delivery_service: null,
            tmp_store_service: null,
            tmp_ski_bags_service: null,
            tmp_bicycle_service: null,

            services: []
        };
    }
    @Bind()
    async sendSettings() {
        const token = await this.props.getToken();
        const mobile_app_settings = this.state.current_mobile_settings;
        if (mobile_app_settings) {
            const request_data = new MobileAppSettingsRequestData(token, mobile_app_settings);
            const success = await putMobileAppSettingsRequest(request_data);

            if (success) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Услуга добавлена"
                });
                this.setState({changes_is_available: false})
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка добавления"
                });
            }
        } else {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
        }

    }
    @Bind()
    async getSettingsRequest(): Promise<IMobileAppSettings | null> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        try {
            const mobileAppSettings = (await requestMobileAppSettings(request_data)).getData();
            return mobileAppSettings ? mobileAppSettings : null;
        } catch (err) {
            return null;
        }
    }
    @Bind()
    settingsRequestResult(result: IMobileAppSettings | null) {
        if (result) {
            this.setState({
                current_mobile_settings: result
            });
        }
    }
    @Bind()
    hideDialog(type: DialogType) {
        switch (type) {
            case DialogType.Warning:
                this.setState({warning_dialog_visible: false});
                break;
            case DialogType.Delivery:
                this.setState({delivery_service_dialog_visible: false});
                break;
            case DialogType.Store:
                this.setState({store_service_dialog_visible: false});
                break;
            case DialogType.SkiBags:
                this.setState({ski_bags_service_dialog_visible: false});
                break;
            case DialogType.Bicycle:
                this.setState({bicycle_service_dialog_visible: false});
                break;
            case DialogType.StableVersion:
                this.setState({stable_version_dialog_visible: false});
                break;
        }
    }
    @Bind()
    showDialog(type: DialogType) {
        switch (type) {
            case DialogType.Warning:
                this.setState({warning_dialog_visible: true});
                break;
            case DialogType.Delivery:
                this.setState({delivery_service_dialog_visible: true});
                break;
            case DialogType.Store:
                this.setState({store_service_dialog_visible: true});
                break;
            case DialogType.SkiBags:
                this.setState({ski_bags_service_dialog_visible: true});
                break;
            case DialogType.Bicycle:
                this.setState({bicycle_service_dialog_visible: true});
                break;
            case DialogType.StableVersion:
                this.setState({stable_version_dialog_visible: true});
                break;
        }
    }
    @Bind()
    async getServicesRequest() {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        try {
            return (await requestServices(request_data)).getData();
        } catch (err) {
            return [];
        }
    }
    @Bind()
    servicesRequestResult(result: IService[]) {
        this.setState({services: result});
    }

    render() {
        const dialogFooter = (
            <div>
                <Button
                    label="Нет спасибо"
                    onClick={() => {
                        this.hideDialog(DialogType.Warning);
                    }}
                />
                <Button
                    label="Понял"
                    onClick={() => {
                        this.setState({changes_is_available: true});
                        this.hideDialog(DialogType.Warning);
                    }}
                />
            </div>
        );

        const alertMessageDialog = (
            <Dialog
                header="Внимание!"
                footer={dialogFooter}
                visible={this.state.warning_dialog_visible}
                style={{width: "50vw"}}
                modal={true}
                onHide={() => {
                    this.hideDialog(DialogType.Warning);
                }}
            >
                Изменение этих настроек может повлиять на работу мобильного приложения,
                вплоть до его полностью не рабочего состояния!
            </Dialog>
        );

        let changeStableVersionDialog = (
            <Dialog
                header="Изменене стабильной версии приложения"
                footer={
                    <div>
                        <Button
                            label="Отмена"
                            onClick={() => {
                                this.hideDialog(DialogType.StableVersion);
                            }}
                        />
                        <Button
                            label="Ок"
                            onClick={() => {
                                const new_version = this.state.tmp_stable_version;

                                if (new_version.trim() !== "") {
                                    let current_mobile_settings = this.state
                                        .current_mobile_settings;
                                    if (current_mobile_settings) {
                                        current_mobile_settings!.setLastStableVersion(new_version);
                                    } else {
                                        current_mobile_settings = new MobileAppSettings(
                                            "",
                                            "",
                                            "",
                                            "",
                                            new_version
                                        );
                                    }
                                    this.setState({
                                        current_mobile_settings: current_mobile_settings,
                                        tmp_stable_version: ""
                                    });
                                }
                                this.hideDialog(DialogType.StableVersion);
                            }}
                        />
                    </div>
                }
                visible={this.state.stable_version_dialog_visible}
                style={{width: "50vw"}}
                modal={true}
                onHide={() => {
                    this.hideDialog(DialogType.StableVersion);
                }}
            >
                <label className="col-4">Новая стабильная версия</label>
                <InputText
                    className="setting-field"
                    value={this.state.tmp_stable_version}
                    placeholder=""
                    onChange={(e: any) => {
                        this.setState({tmp_stable_version: e.target.value});
                    }}
                />
            </Dialog>
        );

        let changeDeliveryServiceDialog = (
            <Dialog
                header="Изменене ID сервиса доставки по умолчанию"
                footer={
                    <div>
                        <Button
                            label="Отмена"
                            onClick={() => {
                                this.hideDialog(DialogType.Delivery);
                            }}
                        />
                        <Button
                            label="Ок"
                            onClick={() => {
                                const new_service = this.state.tmp_delivery_service;

                                if (new_service !== null) {
                                    let current_mobile_settings = this.state
                                        .current_mobile_settings;
                                    if (current_mobile_settings) {
                                        current_mobile_settings!.setDefaultDeliveryServiceID(
                                            new_service.getId()
                                        );
                                    } else {
                                        current_mobile_settings = new MobileAppSettings(
                                            new_service.getId(),
                                            "",
                                            "",
                                            "",
                                            ""
                                        );
                                    }
                                    this.setState({
                                        current_mobile_settings: current_mobile_settings,
                                        tmp_delivery_service: null
                                    });
                                }
                                this.hideDialog(DialogType.Delivery);
                            }}
                        />
                    </div>
                }
                visible={this.state.delivery_service_dialog_visible}
                style={{width: "50vw"}}
                modal={true}
                onHide={() => {
                    this.hideDialog(DialogType.Delivery);
                }}
            >
                <label className="col-4">Новый сервис</label>
                <Dropdown
                    value={this.state.tmp_delivery_service}
                    options={this.state.services.filter((item: IService) => {
                        return item instanceof DeliveryService;
                    })}
                    onChange={(e: any) => {
                        this.setState({tmp_delivery_service: e.value});
                    }}
                    placeholder="Услуга"
                    optionLabel="name"
                    className="setting-filed"
                />
            </Dialog>
        );

        let changeStoreServiceDialog = (
            <Dialog
                header="Изменене ID сервиса хранения по умолчанию"
                footer={
                    <div>
                        <Button
                            label="Отмена"
                            onClick={() => {
                                this.hideDialog(DialogType.Store);
                            }}
                        />
                        <Button
                            label="Ок"
                            onClick={() => {
                                const new_service = this.state.tmp_store_service;

                                if (new_service !== null) {
                                    let current_mobile_settings = this.state
                                        .current_mobile_settings;
                                    if (current_mobile_settings) {
                                        current_mobile_settings!.setDefaultStoreServiceID(
                                            new_service.getId()
                                        );
                                    } else {
                                        current_mobile_settings = new MobileAppSettings(
                                            "",
                                            new_service.getId(),
                                            "",
                                            "",
                                            ""
                                        );
                                    }
                                    this.setState({
                                        current_mobile_settings: current_mobile_settings,
                                        tmp_store_service: null
                                    });
                                }
                                this.hideDialog(DialogType.Store);
                            }}
                        />
                    </div>
                }
                visible={this.state.store_service_dialog_visible}
                style={{width: "50vw"}}
                modal={true}
                onHide={() => {
                    this.hideDialog(DialogType.Store);
                }}
            >
                <label className="col-4">Новый сервис</label>
                <Dropdown
                    value={this.state.tmp_store_service}
                    options={this.state.services.filter((item: IService) => {
                        return item instanceof StorageService;
                    })}
                    onChange={(e: any) => {
                        this.setState({tmp_store_service: e.value});
                    }}
                    placeholder="Услуга"
                    optionLabel="name"
                    className="setting-filed"
                />
            </Dialog>
        );

        let changeSkiBagsServiceDialog = (
            <Dialog
                header="Изменение ID сервиса сумок skishift по умолчанию"
                footer={
                    <div>
                        <Button
                            label="Отмена"
                            onClick={() => {
                                this.hideDialog(DialogType.SkiBags);
                            }}
                        />
                        <Button
                            label="Ок"
                            onClick={() => {
                                const new_service = this.state.tmp_ski_bags_service;

                                if (new_service !== null) {
                                    let current_mobile_settings = this.state
                                        .current_mobile_settings;
                                    if (current_mobile_settings) {
                                        current_mobile_settings!.setDefaultSkiBagsServiceID(
                                            new_service.getId()
                                        );
                                    } else {
                                        current_mobile_settings = new MobileAppSettings(
                                            "",
                                            "",
                                            new_service.getId(),
                                            "",
                                            ""
                                        );
                                    }
                                    this.setState({
                                        current_mobile_settings: current_mobile_settings,
                                        tmp_ski_bags_service: null
                                    });
                                }
                                this.hideDialog(DialogType.SkiBags);
                            }}
                        />
                    </div>
                }
                visible={this.state.ski_bags_service_dialog_visible}
                style={{width: "50vw"}}
                modal={true}
                onHide={() => {
                    this.hideDialog(DialogType.SkiBags);
                }}
            >
                <label className="col-4">Новый сервис</label>
                <Dropdown
                    value={this.state.tmp_ski_bags_service}
                    options={this.state.services.filter((item: IService) => {
                        return item instanceof OtherService;
                    })}
                    onChange={(e: any) => {
                        this.setState({tmp_ski_bags_service: e.value});
                    }}
                    placeholder="Услуга"
                    optionLabel="name"
                    className="setting-filed"
                />
            </Dialog>
        );
        let changeBicycleServiceDialog = (
            <Dialog
                header="Изменение ID сервиса транспортировки велосипедов skishift по умолчанию"
                footer={
                    <div>
                        <Button
                            label="Отмена"
                            onClick={() => {
                                this.hideDialog(DialogType.Bicycle);
                            }}
                        />
                        <Button
                            label="Ок"
                            onClick={() => {
                                const new_service = this.state.tmp_bicycle_service;

                                if (new_service !== null) {
                                    let current_mobile_settings = this.state
                                        .current_mobile_settings;
                                    if (current_mobile_settings) {
                                        current_mobile_settings!.setDefaultBicycleServiceID(
                                            new_service.getId()
                                        );
                                    } else {
                                        current_mobile_settings = new MobileAppSettings(
                                            "",
                                            "",
                                            "",
                                            new_service.getId(),
                                            ""
                                        );
                                    }
                                    this.setState({
                                        current_mobile_settings: current_mobile_settings,
                                        tmp_bicycle_service: null
                                    });
                                }
                                this.hideDialog(DialogType.Bicycle);
                            }}
                        />
                    </div>
                }
                visible={this.state.bicycle_service_dialog_visible}
                style={{width: "50vw"}}
                modal={true}
                onHide={() => {
                    this.hideDialog(DialogType.SkiBags);
                }}
            >
                <label className="col-4">Новый сервис</label>
                <Dropdown
                    value={this.state.tmp_bicycle_service}
                    options={this.state.services.filter((item: IService) => {
                        return item instanceof LuggageService;
                    })}
                    onChange={(e: any) => {
                        this.setState({tmp_bicycle_service: e.value});
                    }}
                    placeholder="Услуга"
                    optionLabel="name"
                    className="setting-filed"
                />
            </Dialog>
        );
        return (
            <>
                <ContentLoaderComponent<IMobileAppSettings | null>
                    contentRequest={this.getSettingsRequest}
                    resultCallback={this.settingsRequestResult}
                >
                    <ContentLoaderComponent<IService[]>
                        contentRequest={this.getServicesRequest}
                        resultCallback={this.servicesRequestResult}
                    >
                        <Growl ref={el => (this.growl = el)}/>
                        {alertMessageDialog}
                        {changeStableVersionDialog}
                        {changeDeliveryServiceDialog}
                        {changeStoreServiceDialog}
                        {changeSkiBagsServiceDialog}
                        {changeBicycleServiceDialog}
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>Настройки мобильного приложения</h3>
                            </div>
                            <form
                                className="card col-12 mt-2 p-2"
                                onSubmit={(e: any) => {
                                    e.preventDefault();
                                }}
                            >
                                <div
                                    className="row m-1"
                                    onClick={() => {
                                        if (!this.state.changes_is_available) {
                                            this.showDialog(DialogType.Warning);
                                        }
                                    }}
                                >
                                    <label className="col-4">
                                        ID сервиса доставки по умолчанию
                                    </label>
                                    <InputText
                                        className="setting-field"
                                        value={
                                            this.state.current_mobile_settings
                                                ? this.state.current_mobile_settings.getDefaultDeliveryServiceID()
                                                : this.no_data_message
                                        }
                                        placeholder="ID сервиса доставки по умолчанию"
                                        disabled={!this.state.changes_is_available}
                                        onClick={() => {
                                            this.showDialog(DialogType.Delivery);
                                        }}
                                    />
                                </div>
                                <div
                                    className="row m-1"
                                    onClick={() => {
                                        if (!this.state.changes_is_available) {
                                            this.showDialog(DialogType.Warning);
                                        }
                                    }}
                                >
                                    <label className="col-4">
                                        ID сервиса хранения по умолчанию
                                    </label>
                                    <InputText
                                        className="setting-field"
                                        value={
                                            this.state.current_mobile_settings
                                                ? this.state.current_mobile_settings.getDefaultStoreServiceID()
                                                : this.no_data_message
                                        }
                                        placeholder="ID сервиса хранения по умолчанию"
                                        disabled={!this.state.changes_is_available}
                                        onClick={() => {
                                            this.showDialog(DialogType.Store);
                                        }}
                                    />
                                </div>
                                <div
                                    className="row m-1"
                                    onClick={() => {
                                        if (!this.state.changes_is_available) {
                                            this.showDialog(DialogType.Warning);
                                        }
                                    }}
                                >
                                    <label className="col-4">
                                        ID сервиса сумок skishift по умолчанию
                                    </label>
                                    <InputText
                                        className="setting-field"
                                        value={
                                            this.state.current_mobile_settings
                                                ? this.state.current_mobile_settings.getDefaultSkiBagsServiceID()
                                                : this.no_data_message
                                        }
                                        placeholder="ID сервиса сумок skishift по умолчанию"
                                        disabled={!this.state.changes_is_available}
                                        onClick={() => {
                                            this.showDialog(DialogType.SkiBags);
                                        }}
                                    />
                                </div>
                                <div
                                    className="row m-1"
                                    onClick={() => {
                                        if (!this.state.changes_is_available) {
                                            this.showDialog(DialogType.Warning);
                                        }
                                    }}
                                >
                                    <label className="col-4">
                                        ID сервиса транспортировки велосипедов skishift по умолчанию
                                    </label>
                                    <InputText
                                        className="setting-field"
                                        value={
                                            this.state.current_mobile_settings
                                                ? this.state.current_mobile_settings.getDefaultBicycleServiceID()
                                                : this.no_data_message
                                        }
                                        placeholder="ID сервиса транспортировки велосипедов skishift по умолчанию"
                                        disabled={!this.state.changes_is_available}
                                        onClick={() => {
                                            this.showDialog(DialogType.Bicycle);
                                        }}
                                    />
                                </div>
                                <div
                                    className="row m-1"
                                    onClick={() => {
                                        if (!this.state.changes_is_available) {
                                            this.showDialog(DialogType.Warning);
                                        }
                                    }}
                                >
                                    <label className="col-4">
                                        Последняя стабильная версия приложения
                                    </label>
                                    <InputText
                                        className="setting-field"
                                        value={
                                            this.state.current_mobile_settings
                                                ? this.state.current_mobile_settings.getLastStableVersion()
                                                : this.no_data_message
                                        }
                                        placeholder="Последняя стабильная версия приложения"
                                        disabled={!this.state.changes_is_available}
                                        onClick={() => {
                                            this.showDialog(DialogType.StableVersion);
                                        }}
                                    />
                                </div>
                                <div className="row m-1">
                                    <Button
                                        label="Сохранить"
                                        onClick={() => {
                                            this.sendSettings()
                                        }}
                                        disabled={!this.state.changes_is_available}
                                    />
                                </div>
                            </form>
                        </div>
                    </ContentLoaderComponent>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(MobileAppSettingsComponent);

import React from "react";
import PropTypes from "prop-types";
import { ReactReduxContext } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import RouteURLs from "../../actions/routesURL";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ReactReduxContext.Consumer>
          {({ store }) => {
            const state = store.getState();
            const user = state["user"];
            return user.is_authorized === true ? (
              <Component {...props} />
            ) : (
              <Redirect to={RouteURLs.auth} />
            );
          }}
        </ReactReduxContext.Consumer>
      );
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default PrivateRoute;

"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Direction = void 0;

var LocalizedField_1 = require("./LocalizedField");

var Model_1 = require("./Model");

var errors_1 = require("../errors");

var Direction =
/** @class */
function (_super) {
  __extends(Direction, _super);

  function Direction(args) {
    var _this = _super.call(this) || this;

    _this.arguments = args;
    return _this;
  }

  Direction.fromJson = function (data) {
    var requiredProps = ["id"];
    var verifyResult = Direction.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    var localizedNameArray = [];

    if (data.localizedName) {
      localizedNameArray = LocalizedField_1.convertFromObject(data.localizedName);
    }

    return new Direction({
      id: data.id ? data.id : "",
      name: data.name ? data.name : "",
      localizedName: localizedNameArray,
      vertices: data.vertices ? data.vertices : [],
      deliveryDuration: data.deliveryDuration ? data.deliveryDuration : 0
    });
  };

  Direction.prototype.getId = function () {
    return this.arguments.id;
  };

  Direction.prototype.getName = function () {
    return this.arguments.name;
  };

  Direction.prototype.getLocalizedName = function () {
    return this.arguments.localizedName;
  };

  Direction.prototype.getVertices = function () {
    return this.arguments.vertices;
  };

  Direction.prototype.getDeliveryDuration = function () {
    return this.arguments.deliveryDuration;
  };

  return Direction;
}(Model_1.Model);

exports.Direction = Direction;
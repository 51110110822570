"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Notification = void 0;

var errors_1 = require("../errors");

var NotificationObjectType_1 = require("./NotificationObjectType");

var NotificationType_1 = require("./NotificationType"); //TODO Наследовать от Model
//TODO Добавить конвертацию из json


var Notification =
/** @class */
function () {
  function Notification(arg) {
    this._description = null;
    this._objectId = null;
    this._objectType = null;
    this._notificationType = null;

    if (!arg.hasOwnProperty("id")) {
      throw new errors_1.MissingPropertiesError("Missing id argument");
    }

    this._id = arg.id;

    if (!arg.hasOwnProperty("title")) {
      throw new errors_1.MissingPropertiesError("Missing title argument");
    }

    this._title = arg.title;

    if (!arg.hasOwnProperty("date")) {
      throw new errors_1.MissingPropertiesError("Missing date argument");
    }

    this._date = arg.date;

    if (!arg.hasOwnProperty("status")) {
      throw new errors_1.MissingPropertiesError("Missing status argument");
    }

    this._status = arg.status;

    if (arg.hasOwnProperty("description") && arg.description !== undefined) {
      this._description = arg.description;
    }

    if (arg.hasOwnProperty("objectId") && arg.objectId !== undefined) {
      this._objectId = arg.objectId;
    }

    if (arg.hasOwnProperty("objectType") && arg.objectType !== undefined && arg.objectType !== null) {
      switch (arg.objectType.toLowerCase()) {
        case "order":
          this._objectType = NotificationObjectType_1.NotificationObjectType.Order;
          break;

        case "shipment":
          this._objectType = NotificationObjectType_1.NotificationObjectType.Shipment;
          break;

        case "payment":
          this._objectType = NotificationObjectType_1.NotificationObjectType.Payment;
          break;
      }
    }

    if (arg.hasOwnProperty("notificationType") && arg.notificationType !== undefined) {
      switch (arg.notificationType) {
        case 0:
          this._notificationType = NotificationType_1.NotificationType.Info;
          break;

        case 1:
          this._notificationType = NotificationType_1.NotificationType.Warning;
          break;
      }
    }
  }

  Notification.from = function (jsonObj) {
    try {
      return new Notification({
        id: jsonObj._id,
        title: jsonObj._title,
        date: new Date(jsonObj._date),
        status: jsonObj._status,
        description: jsonObj._description,
        objectId: jsonObj._objectId,
        objectType: jsonObj._objectType,
        notificationType: jsonObj._notificationType
      });
    } catch (err) {
      return null;
    }
  };

  Notification.prototype.getId = function () {
    return this._id;
  };

  Notification.prototype.getTitle = function () {
    return this._title;
  };

  Notification.prototype.getDate = function () {
    return this._date;
  };

  Notification.prototype.getStatus = function () {
    return this._status;
  };

  Notification.prototype.getDescription = function () {
    return this._description;
  };

  Notification.prototype.getObjectId = function () {
    return this._objectId;
  };

  Notification.prototype.getObjectType = function () {
    return this._objectType;
  };

  Notification.prototype.getType = function () {
    return this._notificationType;
  };

  return Notification;
}();

exports.Notification = Notification;
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import OrdersPage from "./pages/OrdersPage";
import StoragePointsPage from "./pages/StoragePointsPage";
import PrivateRoute from "./components/common/PrivateRoute";
import ServicesPage from "./pages/ServicesPage";
import DiscountPage from "./pages/DiscountPage";
import MobileAppSettingsPage from "./pages/MobileAppSettingsPage";
import UsersPage from "./pages/UsersPage";
import RouteURLs from "./actions/routesURL";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./styles/bootstrap/bootstrap.scss";
import "./App.scss";
import "./pages/PageTemplate";
import EventMessagesComponent from "./components/events/EventMessagesComponent";
import NewsPage from "./pages/NewsPage";
import NotificationsPage from "./pages/NotificationsPage";
import DestinationPage from "./pages/DestinationPage";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <EventMessagesComponent />
        <BrowserRouter>
          <Route path={RouteURLs.auth} component={AuthPage} />
          <PrivateRoute exact path={RouteURLs.home} component={HomePage} />

          <PrivateRoute path={RouteURLs.news} component={NewsPage} />
          <PrivateRoute path={RouteURLs.orders} component={OrdersPage} />
          <PrivateRoute path={RouteURLs.services} component={ServicesPage} />
          <PrivateRoute path={RouteURLs.discounts} component={DiscountPage} />
          <PrivateRoute path={RouteURLs.storages} component={StoragePointsPage} />
          <PrivateRoute path={RouteURLs.notifications} component={NotificationsPage}/>
          <PrivateRoute path={RouteURLs.users} component={UsersPage}/>
          <PrivateRoute path={RouteURLs.destinations} component={DestinationPage}/>
          <PrivateRoute
            path={RouteURLs.mobileAppSettings}
            component={MobileAppSettingsPage}
          />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthorizedRequestData = void 0;

var RequestData_1 = require("./RequestData");

var AuthorizedRequestData =
/** @class */
function (_super) {
  __extends(AuthorizedRequestData, _super);

  function AuthorizedRequestData(access_token, headers, params, data, axios_options) {
    if (headers === void 0) {
      headers = {};
    }

    if (params === void 0) {
      params = {};
    }

    if (data === void 0) {
      data = {};
    }

    if (axios_options === void 0) {
      axios_options = {};
    }

    var _this = _super.call(this, headers, params, data, axios_options) || this;

    _this.access_token = access_token;
    return _this;
  }

  AuthorizedRequestData.prototype.getHeaders = function () {
    return __assign(__assign({}, this.headers), {
      Authorization: "Bearer " + this.getAccessToken()
    });
  };

  AuthorizedRequestData.prototype.getAccessToken = function () {
    return this.access_token;
  };

  return AuthorizedRequestData;
}(RequestData_1.RequestData);

exports.AuthorizedRequestData = AuthorizedRequestData;
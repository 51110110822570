import { ServiceType } from "@shift-mono/common";

export function getServiceTypeTitle(type: ServiceType): string {
  switch (type) {
    case ServiceType.Common:
      return "Общий";
    case ServiceType.Other:
      return "Прочее";
    case ServiceType.Store:
      return "Хранение";
    case ServiceType.Luggage:
      return "Перемещение багажа";
    case ServiceType.Move:
      return "Перемещение горнолыжного снаряжения";
  }
  
  return "";
}

"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LuggageService = exports.OtherService = exports.StorageService = exports.DeliveryService = exports.Service = void 0;

var Currency_1 = require("./Currency");

var ServicePaymentType_1 = require("./ServicePaymentType");

var ServiceType_1 = require("./ServiceType");

var Model_1 = require("./Model");

var LocalizedField_1 = require("./LocalizedField");

var DirectionCost_1 = require("./DirectionCost");

var MonthStorageCost_1 = require("./MonthStorageCost");

var errors_1 = require("../errors");

var Service =
/** @class */
function (_super) {
  __extends(Service, _super);

  function Service(id, name, localizedName, currency, paymentType, showInCarousel, discountApplicable) {
    var _this = _super.call(this) || this;

    _this.id = id;
    _this.name = name;
    _this.localizedName = localizedName;
    _this.currency = currency;
    _this.paymentType = paymentType;
    _this.showInCarousel = showInCarousel;
    _this.discountApplicable = discountApplicable;
    return _this;
  }

  Service.fromJson = function (data) {
    var requiredProps = ["id"];
    var verifyResult = Service.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    var currentCurrency = Currency_1.getCurrency(data.currency.toLowerCase());
    var currentPaymentType = ServicePaymentType_1.ServicePaymentType.OneTime;

    switch (data.paymentType.toLowerCase()) {
      case "onetime":
        currentPaymentType = ServicePaymentType_1.ServicePaymentType.OneTime;
        break;

      case "monthly":
        currentPaymentType = ServicePaymentType_1.ServicePaymentType.Monthly;
        break;
    }

    var localizedNameArray = []; //TODO Когда переименнуют поле в api, изменить на localizedName

    if (data.localizedNames) {
      localizedNameArray = LocalizedField_1.convertFromObject(data.localizedNames);
    }

    switch (data.serviceType) {
      case ServiceType_1.ServiceType.Other:
        return new OtherService(data.id ? data.id : "", data.name ? data.name : "", localizedNameArray, currentCurrency, currentPaymentType, data.cost ? data.cost : 0, data.showInCoursel ? data.showInCoursel : false, data.discountApplicable ? data.discountApplicable : false);

      case ServiceType_1.ServiceType.Move:
        var ddirectionCost = [];

        if (data.directionCost) {
          ddirectionCost = Object.keys(data.directionCost).map(function (key) {
            return new DirectionCost_1.DirectionCost(key, data.directionCost[key]);
          });
        }

        return new DeliveryService(data.id ? data.id : "", data.name ? data.name : "", localizedNameArray, currentCurrency, currentPaymentType, ddirectionCost, data.showInCoursel ? data.showInCoursel : false, data.discountApplicable ? data.discountApplicable : false);

      case ServiceType_1.ServiceType.Luggage:
        var ldirectionCost = [];

        if (data.directionCost) {
          ldirectionCost = Object.keys(data.directionCost).map(function (key) {
            return new DirectionCost_1.DirectionCost(key, data.directionCost[key]);
          });
        }

        return new LuggageService(data.id ? data.id : "", data.name ? data.name : "", localizedNameArray, currentCurrency, currentPaymentType, ldirectionCost, data.showInCoursel ? data.showInCoursel : false, data.discountApplicable ? data.discountApplicable : false);

      case ServiceType_1.ServiceType.Store:
        var monthStoreCost = [];

        if (data.monthCost) {
          monthStoreCost = Object.keys(data.monthCost).map(function (monthCount) {
            return new MonthStorageCost_1.MonthStorageCost(Number.parseInt(monthCount), data.monthCost[monthCount]);
          });
        }

        return new StorageService(data.id ? data.id : "", data.name ? data.name : "", localizedNameArray, currentCurrency, currentPaymentType, monthStoreCost, data.showInCoursel ? data.showInCoursel : false, data.discountApplicable ? data.discountApplicable : false);
    }

    return undefined;
  };

  Service.prototype.getId = function () {
    return this.id;
  };

  Service.prototype.getName = function () {
    return this.name;
  };

  Service.prototype.getLocalizedName = function () {
    return this.localizedName;
  };

  Service.prototype.getCurrency = function () {
    return this.currency;
  };

  Service.prototype.getPaymentType = function () {
    return this.paymentType;
  };

  Service.prototype.getType = function () {
    return ServiceType_1.ServiceType.Common;
  };

  Service.prototype.isShowingInCarousel = function () {
    return this.showInCarousel;
  };

  Service.prototype.isDiscountApplicable = function () {
    return this.discountApplicable;
  };

  return Service;
}(Model_1.Model);

exports.Service = Service;

var DeliveryService =
/** @class */
function (_super) {
  __extends(DeliveryService, _super);

  function DeliveryService(id, name, localizedName, currency, paymentType, directionCost, showInCarousel, discountApplicable) {
    var _this = _super.call(this, id, name, localizedName, currency, paymentType, showInCarousel, discountApplicable) || this;

    _this.id = id;
    _this.name = name;
    _this.localizedName = localizedName;
    _this.currency = currency;
    _this.paymentType = paymentType;
    _this.directionCost = directionCost;
    _this.showInCarousel = showInCarousel;
    _this.discountApplicable = discountApplicable;
    return _this;
  }

  DeliveryService.prototype.getType = function () {
    return ServiceType_1.ServiceType.Move;
  };

  DeliveryService.prototype.getDirectionCost = function () {
    return this.directionCost;
  };

  return DeliveryService;
}(Service);

exports.DeliveryService = DeliveryService;

var StorageService =
/** @class */
function (_super) {
  __extends(StorageService, _super);

  function StorageService(id, name, localizedName, currency, paymentType, monthStoreCost, showInCarousel, discountApplicable) {
    var _this = _super.call(this, id, name, localizedName, currency, paymentType, showInCarousel, discountApplicable) || this;

    _this.id = id;
    _this.name = name;
    _this.localizedName = localizedName;
    _this.currency = currency;
    _this.paymentType = paymentType;
    _this.monthStoreCost = monthStoreCost;
    _this.showInCarousel = showInCarousel;
    _this.discountApplicable = discountApplicable;
    return _this;
  }

  StorageService.prototype.getMonthStorageCost = function () {
    return this.monthStoreCost;
  };

  StorageService.prototype.getType = function () {
    return ServiceType_1.ServiceType.Store;
  };

  return StorageService;
}(Service);

exports.StorageService = StorageService;

var OtherService =
/** @class */
function (_super) {
  __extends(OtherService, _super);

  function OtherService(id, name, localizedName, currency, paymentType, cost, showInCarousel, discountApplicable) {
    var _this = _super.call(this, id, name, localizedName, currency, paymentType, showInCarousel, discountApplicable) || this;

    _this.id = id;
    _this.name = name;
    _this.localizedName = localizedName;
    _this.currency = currency;
    _this.paymentType = paymentType;
    _this.cost = cost;
    _this.showInCarousel = showInCarousel;
    _this.discountApplicable = discountApplicable;
    return _this;
  }

  OtherService.prototype.getType = function () {
    return ServiceType_1.ServiceType.Other;
  };

  OtherService.prototype.getCost = function () {
    return this.cost;
  };

  return OtherService;
}(Service);

exports.OtherService = OtherService;

var LuggageService =
/** @class */
function (_super) {
  __extends(LuggageService, _super);

  function LuggageService(id, name, localizedName, currency, paymentType, directionCost, showInCarousel, discountApplicable) {
    var _this = _super.call(this, id, name, localizedName, currency, paymentType, showInCarousel, discountApplicable) || this;

    _this.id = id;
    _this.name = name;
    _this.localizedName = localizedName;
    _this.currency = currency;
    _this.paymentType = paymentType;
    _this.directionCost = directionCost;
    _this.showInCarousel = showInCarousel;
    _this.discountApplicable = discountApplicable;
    return _this;
  }

  LuggageService.prototype.getType = function () {
    return ServiceType_1.ServiceType.Luggage;
  };

  LuggageService.prototype.getDirectionCost = function () {
    return this.directionCost;
  };

  return LuggageService;
}(Service);

exports.LuggageService = LuggageService;
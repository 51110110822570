import React from "react";
import RouteURLs from "../../../actions/routesURL";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";
import {connect} from "react-redux";
import {DataView} from "primereact/dataview";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import "./NewsListComponentStyle.scss";
import {
    requestNews,
    INews,
    CountableResponse,
    ICountableResponse,
    AuthorizedRequestData, Bind
} from "@shift-mono/common";

interface INewsListComponentProps {
    getToken: () => Promise<string>;
}

interface INewsListComponentState {
    news: INews[];
    newsCount: number;
    paginationListRows: number;
    firstPaginationIndex: number;
    loading: boolean;
    dataViewLayout: string;
    expandedRows: any;
}

class NewsListComponent extends React.Component<INewsListComponentProps,
    INewsListComponentState> {
    constructor(props: INewsListComponentProps) {
        super(props);

        this.state = {
            news: [],
            newsCount: 0,
            paginationListRows: 10,
            firstPaginationIndex: 0,
            loading: true,
            dataViewLayout: "list",
            expandedRows: null
        };
    }

    @Bind()
    async getNewsRequest(): Promise<ICountableResponse<INews[]>> {
        const token = await this.props.getToken();

        try {
            const requestData = new AuthorizedRequestData(token);
            return await requestNews(requestData);
        } catch (err) {
            return new CountableResponse<INews[]>([], 0);
        }
    }

    @Bind()
    newsRequestResult(news: ICountableResponse<INews[]>) {
        this.setState({
            news: news.getData(),
            newsCount: news.getObjectsCount(),
            loading: false,
        });
    }

    @Bind()
    itemTemplate(newsData: INews, layout: string) {
        if (!newsData) {
            return <></>;
        }
        if (layout === "list") {
            const maxTitleLength = 200;
            const maxContentLength = 800;
            return (
                <div className="row m-1 news-list-item">
                    <div className="d-lg-block col-lg-3 d-flex justify-content-center">
                        <img
                            src={newsData.getImageUrl()}
                            alt=""
                            className={"news-list-item__image"}
                        />
                    </div>
                    <div className="col-lg-9">
                        <p>
                            <b>Заглавие: </b>
                            {newsData.getTitle().substring(0, maxTitleLength)}
                        </p>
                        <p>
                            <b>Описание: </b>
                            {newsData.getContent().substring(0, maxContentLength)}
                        </p>
                        <p>
                            <b>Теги: </b>
                            {newsData.getTags().reduce((result, currentItem) => {
                                return (result += currentItem + ", ");
                            }, "")}
                        </p>
                        <Link to={RouteURLs.news + "/" + newsData.getId()}>
                            <Button
                                className="news-list-item__detail-button"
                                label="Подробнее"
                            />
                        </Link>
                    </div>
                </div>
            );
        }
        if (layout === "grid") {
            return (
                <div className="p-col-12 p-md-3">
                    <div>{newsData.getTitle()}</div>
                </div>
            );
        }
    }

    @Bind()
    async onPage(event: any) {
        this.setState({
            loading: true
        });

        const startIndex = event.first;
        const endIndex = this.state.paginationListRows;

        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token, {}, {
            skip: startIndex,
            limit: endIndex
        });
        try {
            const newsResponse = await requestNews(request_data);
            this.setState({
                firstPaginationIndex: startIndex,
                news: newsResponse.getData(),
                loading: false,
                newsCount: newsResponse.getObjectsCount()
            })
        } catch (err) {
            return new CountableResponse<INews[]>([], 0);
        }
    }

    render() {
        return (
            <>
                <ContentLoaderComponent<ICountableResponse<INews[]>>
                    contentRequest={this.getNewsRequest}
                    resultCallback={this.newsRequestResult}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Новости</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <DataView
                                value={this.state.news}
                                layout={this.state.dataViewLayout}
                                itemTemplate={this.itemTemplate}
                                paginator={true}
                                totalRecords={this.state.newsCount}
                                rows={this.state.paginationListRows}
                                first={this.state.firstPaginationIndex}
                                lazy={true}
                                // loading={this.state.loading}
                                onPage={this.onPage}
                            />
                        </div>
                    </div>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => {
            return dispatch(getAccessToken());
        }
    };
};

export default connect(null, mapDispatchToProps)(NewsListComponent);

"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderService = void 0;

var errors_1 = require("../errors");

var Address_1 = require("./Address");

var Model_1 = require("./Model");

var OrderService =
/** @class */
function (_super) {
  __extends(OrderService, _super);

  function OrderService(serviceId, count, cost, total, totalWithDiscount, photoUrls, duration, storagePointId, directionId, addressFrom, addressTo) {
    var _this = _super.call(this) || this;

    _this.serviceId = serviceId;
    _this.count = count;
    _this.cost = cost;
    _this.total = total;
    _this.totalWithDiscount = totalWithDiscount;
    _this.photoUrls = photoUrls;
    _this.duration = duration;
    _this.storagePointId = storagePointId;
    _this.directionId = directionId;
    _this.addressFrom = addressFrom;
    _this.addressTo = addressTo;

    _this.getServiceId = function () {
      return _this.serviceId;
    };

    _this.getDirectionId = function () {
      return _this.directionId;
    };

    _this.getStoragePointId = function () {
      return _this.storagePointId;
    };

    _this.getCount = function () {
      return _this.count;
    };

    _this.getCost = function () {
      return _this.cost;
    };

    _this.getTotal = function () {
      return _this.total;
    };

    _this.getTotalWithDiscount = function () {
      return _this.totalWithDiscount;
    };

    _this.getAddressFrom = function () {
      return _this.addressFrom;
    };

    _this.getAddressTo = function () {
      return _this.addressTo;
    };

    _this.getDuration = function () {
      return _this.duration;
    };

    _this.getPhotoUrls = function () {
      return _this.photoUrls;
    };

    return _this;
  }

  OrderService.fromJson = function (data) {
    var addressFrom = Address_1.Address.fromJson(data.hasOwnProperty("addressFrom") ? data.addressFrom : {});
    var addressTo = Address_1.Address.fromJson(data.hasOwnProperty("addressTo") ? data.addressTo : {});
    var requiredProps = ["serviceId", "count", "total", "totalWithDiscount", "cost", "costWithDiscount"];
    var verifyResult = OrderService.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    return new OrderService(data.serviceId ? data.serviceId : "", data.count ? data.count : 0, data.cost ? data.cost : 0, data.total ? data.total : 0, data.totalWithDiscount ? data.totalWithDiscount : 0, data.photoUrls ? data.photoUrls : [], data.duration ? data.duration : 0, data.storagePointId ? data.storagePointId : "", data.directionId ? data.directionId : "", addressFrom, addressTo);
  };

  OrderService.prototype.toJson = function () {
    var result = {
      serviceId: this.getServiceId(),
      count: this.getCount(),
      cost: this.getCost(),
      total: this.getTotal(),
      totalWithDiscount: this.getTotalWithDiscount()
    };

    if (this.getDuration()) {
      result = Object.assign({}, result, {
        duration: this.getDuration()
      });
    }

    if (this.getStoragePointId()) {
      result = Object.assign({}, result, {
        storagePointId: this.getStoragePointId()
      });
    }

    if (this.getDirectionId()) {
      result = Object.assign({}, result, {
        directionId: this.getDirectionId()
      });
    }

    if (this.getAddressFrom()) {
      result = Object.assign({}, result, {
        addressFrom: this.getAddressFrom().toJson()
      });
    }

    if (this.getAddressTo()) {
      result = Object.assign({}, result, {
        addressTo: this.getAddressTo().toJson()
      });
    }

    return result;
  };

  return OrderService;
}(Model_1.Model);

exports.OrderService = OrderService;
const constants = {
    ENTITIES: {
        entity_type: "ENTITIES",
        BAGS: {
            entity_type: "ENTITIES_BAGS",
            ADD: "ENTITIES_BAGS_ADD",
            REMOVE: "ENTITIES_BAGS_REMOVE",
            CHANGE: "ENTITIES_BAGS_CHANGE"
        },
        BAG_TEMPS: {
            entity_type: "ENTITIES_BAG_TEMPS",
            ADD: "ENTITIES_BAG_TEMPS_ADD"
        },
        ORDERS: {
            entity_type: "ENTITIES_ORDERS",
            ADD: "ENTITIES_ORDERS_ADD"
        },
        CLIENTS: {
            entity_type: "ENTITIES_CLIENTS",
            ADD: "ENTITIES_CLIENTS_ADD"
        },
        SERVICES: {
            entity_type: "ENTITIES_SERVICES",
            ADD: "ENTITIES_SERVICES_ADD"
        },
        EVENTS: {
            entity_type: "ENTITIES_EVENTS",
            ADD: "ENTITIES_EVENTS_ADD"
        },
        UNITS: {
            entity_type: "ENTITIES_UNITS",
            ADD: "ENTITIES_UNITS_ADD"
        },
        PLACES: {
            entity_type: "ENTITIES_PLACES",
            ADD: "ENTITIES_PLACES_ADD"
        },
        DISCOUNTS: {
            entity_type: "ENTITIES_DISCOUNTS",
            ADD: "ENTITIES_DISCOUNTS_ADD"
        },
        QRBAGCODES: {
            entity_type: "ENTITIES_QRBAGCODES",
            ADD: "ENTITIES_QRBAGCODES_ADD"
        },
        QRPLACECODES: {
            entity_type: "ENTITIES_QRPLACECODES",
            ADD: "ENTITIES_QRPLACECODES_ADD"
        },
        STAFF: {
            entity_type: "ENTITIES_STAFF",
            ADD: "ENTITIES_STAFF_ADD"
        },
        COURIERS: {
            entity_type: "ENTITIES_COURIERS",
            ADD: "ENTITIES_COURIERS_ADD"
        }
    },
    USER: {
        LOGIN: "LOGIN",
        LOGOUT: "LOGOUT"
    },
    COMPONENT_STATE: {
        REQUEST_ENTITIES: "REQUEST_ENTITIES",
        REQUEST_ENTITIES_FAILURE: "REQUEST_ENTITIES_FAILURE",
        REQUEST_ENTITIES_SUCCESS: "REQUEST_ENTITIES_SUCCESS",
        RECEIVE_ENTITIES: "RECEIVE_ENTITIES",
        CLEAR: "CLEAR"
    },
    NOTIFICATION: {
        ADD: "ADD",
        REMOVE: "REMOVE",
        REMOVE_ALL: "REMOVE_ALL",
        CHANGE_STATUS: "CHANGE_STATUS",
    }
};
export default constants;

import React from "react";
import PageTemplate from "./PageTemplate";
import { Redirect } from "react-router-dom";
import RouteURLs from "../actions/routesURL";

const HomePage = () => {
  return (
    <PageTemplate>
      <Redirect to={RouteURLs.orders}/>
      {/* <TestComp></TestComp> */}
    </PageTemplate>
  );
};

export default HomePage;

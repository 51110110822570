import React from "react";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import {connect} from "react-redux";
import {Growl} from "primereact/growl";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import StoragePointFormComponent, {
    IStoragePointFormData
} from "./form_parts/StoragePointFormComponent";

import {
    requestServices,
    StoragePointRequestData,
    postStoragePointRequest,
    postImage,
    requestDestinations,
    CountableResponse,
    ICountableResponse,
    IDestination,
    AuthorizedRequestData,
    StoragePointLocation,
    IService, Bind, IPartner, requestPartners
} from "@shift-mono/common";

interface IAddStoragePointComponentProps {
    getToken: () => Promise<string>;
}

interface IAddStoragePointComponentState {
    services: IService[];
    destinations: IDestination[];
    partners: IPartner[];
}

class AddStoragePointComponent extends React.Component<IAddStoragePointComponentProps,
    IAddStoragePointComponentState> {
    private growl: any = undefined;
    private formRef: any = undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            services: [],
            destinations: [],
            partners: [],
        };
    }

    @Bind()
    validateForm(formData: IStoragePointFormData): boolean {
        if (formData.name.trim() === "" ||
            formData.reservedCellCount === 0 ||
            formData.imageFile === null ||
            formData.destinationId.trim() === ""
        ) {
            return false;
        }

        return true;
    }

    @Bind()
    async sendStoragePoint(formData: IStoragePointFormData) {
        try {
            if (this.validateForm(formData)) {
                const token = await this.props.getToken();

                const imageResult = await postImage(token, formData.imageFile!);
                if (imageResult === null) {
                    this.growl.show({
                        severity: "error",
                        summary: "Ошибка",
                        detail: "Не удалось загрузить изображение"
                    });
                    return;
                }

                const requestData = new StoragePointRequestData(
                    {
                        accessToken: token,
                        active: formData.active,
                        name: formData.name,
                        localizedName: formData.localizedName,
                        address: formData.address,
                        imagesURL: [imageResult.url],
                        reservedCellCount: formData.reservedCellCount,
                        serviceIds: formData.selectedAvailableServiceIds,
                        location: new StoragePointLocation(formData.latitude, formData.longitude),
                        selfService: formData.selfServiceAvailable,
                        canStore: formData.canStore,
                        couriers: formData.couriersAvailable,
                        destinationId: formData.destinationId,
                        storagePointType: formData.type,
                        partnerId: formData.partnerId
                    }
                );

                const result = await postStoragePointRequest(requestData);

                if (result) {
                    this.growl.show({
                        severity: "success",
                        summary: "Успешно",
                        detail: "Пункт хранения добавлен"
                    });
                    if (this.formRef) {
                        this.formRef.clearForm();
                    }
                } else {
                    this.growl.show({
                        severity: "error",
                        summary: "Ошибка",
                        detail: "Ошибка добавления"
                    });
                }
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Не все поля заполнены"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка добавления"
            });
        }
    }

    @Bind()
    async getServicesRequest(): Promise<IService[]> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        try {
            return (await requestServices(request_data)).getData();
        } catch (err) {
            return [];
        }
    }

    @Bind()
    getServiceRequestResult(result: IService[]) {
        this.setState({services: result});
    }

    @Bind()
    async getDestinationsRequest(): Promise<ICountableResponse<IDestination[]>> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token, {}, {skip: 0, limit: 0});
        try {
            return await requestDestinations(requestData);
        } catch (err) {
            return new CountableResponse([], 0);
        }
    }

    @Bind()
    getDestinationsRequestResult(destinationsResponse: ICountableResponse<IDestination[]>) {
        this.setState({destinations: destinationsResponse.getData()})
    }

    @Bind()
    async partnersRequest(): Promise<IPartner[]> {
        const token = await this.props.getToken();
        const requestData = new AuthorizedRequestData(token);
        try {
            return (await requestPartners(requestData)).getData();
        } catch (err) {
            return [];
        }
    }

    @Bind()
    partnersRequestResult(partners: IPartner[]) {
        this.setState({partners})
    }

    render() {
        return (
            <>
                <Growl ref={el => (this.growl = el)}/>
                <ContentLoaderComponent<IService[]>
                    contentRequest={this.getServicesRequest}
                    resultCallback={this.getServiceRequestResult}
                    errorCallback={err => console.log(err)}
                >
                    <ContentLoaderComponent<ICountableResponse<IDestination[]>>
                        contentRequest={this.getDestinationsRequest}
                        resultCallback={this.getDestinationsRequestResult}
                        errorCallback={err => console.log(err)}
                    >
                        <ContentLoaderComponent<IPartner[]>
                            contentRequest={this.partnersRequest}
                            resultCallback={this.partnersRequestResult}
                        >
                            <StoragePointFormComponent
                                ref={el => (this.formRef = el)}
                                saveCallback={(formData: IStoragePointFormData) => {
                                    this.sendStoragePoint(formData);
                                }}
                                availableSevices={this.state.services}
                                availableDestinations={this.state.destinations}
                                availablePartners={this.state.partners}
                            />
                        </ContentLoaderComponent>
                    </ContentLoaderComponent>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps)(AddStoragePointComponent);

import React from "react";
import ReactDOM from "react-dom";
import storage from "redux-persist/lib/storage";
import storeFactory from "./store/storeFactory";
import RootReduser from "./reducers/reducers";
import App from "./App";
import stateData from "./store/initState";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user", "notification"]
};
const persistedReducer = persistReducer(persistConfig, RootReduser);
const store = storeFactory(persistedReducer, stateData);
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Bind} from "@shift-mono/common";
import {WithdrawalFilterOptions} from "./partials/WithdrawalFilterOptions";

interface IWithdrawalFilterComponentProps {
    callbackHandler: (currentValue: WithdrawalFilterOptions) => void,
    defaultFilterOption: WithdrawalFilterOptions
}
interface IWithdrawalFilterComponentState {
    filterValue: DropdownItem,
}

interface DropdownItem {
    title: string;
    value: WithdrawalFilterOptions
}

export default class WithdrawalFilterComponent extends React.Component<IWithdrawalFilterComponentProps, IWithdrawalFilterComponentState>{

    constructor(props: IWithdrawalFilterComponentProps) {
        super(props);

        this.state = {
            filterValue: this.getOptionForOrDefault(props.defaultFilterOption)
        }
    }

    getOptions(): DropdownItem[]{
        return [
            {title: "Все", value: WithdrawalFilterOptions.NONE},
            {title: "Нужно вывести", value: WithdrawalFilterOptions.IN_PROGRESS},
            {title: "Завершенно", value: WithdrawalFilterOptions.COMPLETED}
        ]
    }
    @Bind()
    changeOptionHandler(e: any){
        this.setState({
            filterValue: e.value
        })
    }

    getOptionForOrDefault(filterOption: WithdrawalFilterOptions): DropdownItem {
        const allOptions = this.getOptions();
        return allOptions.reduce((currentOption, item) => {
            if(item.value === filterOption){
                currentOption = item;
            }
            return currentOption;
        }, {title: "Все", value: WithdrawalFilterOptions.NONE});
    }

    render(){
        return <>
            <form
                className="card mt-2 p-2"
                onSubmit={(e: any) => {
                    e.preventDefault();
                }}
            >
                <div className="row m-1">
                    <label className={"col-lg-5"}><b>Фильтр</b></label>
                </div>
                <div className="row m-1">
                    <label className={"col-lg-5"}>Статус заявки</label>
                    <Dropdown
                        className={"col-lg-5"}
                        value={this.state.filterValue}
                        options={this.getOptions()}
                        dataKey="value"
                        onChange={this.changeOptionHandler}
                        optionLabel="title"
                    />
                </div>
                <div className="row m-1 ml-2">
                    <Button
                        label={"Отфильтровать"}
                        onClick={() => {
                            this.props.callbackHandler(this.state.filterValue.value);
                        }}
                    />
                </div>
            </form>
        </>;
    }
}
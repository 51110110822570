import React from "react";
import TagInput from "./TagInput";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Bind, INews} from "@shift-mono/common";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import "./NewsFormComponentStyle.scss";

export interface INewsFormData {
    newsTitle: string;
    newsContent: string;
    imageFile: File | null;
    tags: string[];
}

interface INewsFormComponentProps {
    currentNews?: INews;
    saveCallback?: (data: INewsFormData) => void;
    deleteCallback?: () => void;
}

interface INewsFormComponentState {
    firstInit: boolean;
    delete_dialog_visible: boolean;

    max_description_length: number;

    news_title: string;
    news_content: string;
    filename: string;
    image_file: File | null;
    tags: string[];
}

class NewsFormComponent extends React.Component<INewsFormComponentProps,
    INewsFormComponentState> {
    private tagInputRef: any = undefined;

    constructor(props: INewsFormComponentProps) {
        super(props);

        this.state = {
            firstInit: true,
            delete_dialog_visible: false,

            max_description_length: 1000,

            news_title: this.props.currentNews
                ? this.props.currentNews.getTitle()
                : "",
            news_content: this.props.currentNews
                ? this.props.currentNews.getContent()
                : "",
            filename: this.props.currentNews
                ? this.props.currentNews.getImageUrl()
                : "",
            image_file: null,
            tags: this.props.currentNews ? this.props.currentNews.getTags() : []
        };
    }

    fillNewsState(currentNews: INews, nextState: INewsFormComponentState) {
        nextState.news_title = currentNews.getTitle();
        nextState.news_content = currentNews.getContent();
        nextState.tags = currentNews.getTags();
    }

    shouldComponentUpdate(
        nextProps: INewsFormComponentProps,
        nextState: INewsFormComponentState
    ) {
        if (nextProps.currentNews !== undefined && this.state.firstInit) {
            this.fillNewsState(nextProps.currentNews, nextState);
            nextState.firstInit = false;
        }

        return true;
    }
    @Bind()
    clearForm() {
        this.setState({
            news_title: "",
            news_content: "",
            filename: "",
            image_file: null,
            tags: []
        });
        if (this.tagInputRef) {
            this.tagInputRef.clearAllTags();
        }
    }
    @Bind()
    uploadHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (files) {
            const file = files[0];
            if (file) {
                this.setState({image_file: file});
                this.setState({filename: file.name});
            }
        }
    }
    @Bind()
    saveButtonHandle() {
        if (this.props.saveCallback) {
            this.props.saveCallback({
                newsTitle: this.state.news_title,
                newsContent: this.state.news_content,
                imageFile: this.state.image_file,
                tags: this.state.tags
            });
        }
    }
    @Bind()
    deleteButtonHandle() {
        if (this.props.deleteCallback) {
            this.props.deleteCallback();
        }
    }

    showDeleteDialog() {
        this.setState({
            delete_dialog_visible: true
        });
    }

    hideDeleteDialog() {
        this.setState({delete_dialog_visible: false});
    }

    deleteDialogFooterTemplate = (
        <div>
            <Button
                label="Да"
                icon="pi pi-check"
                onClick={async () => {
                    if (this.props.deleteCallback) {
                        this.props.deleteCallback();
                    }

                    this.hideDeleteDialog();
                }}
            />
            <Button
                label="Нет"
                icon="pi pi-times"
                onClick={() => this.hideDeleteDialog()}
            />
        </div>
    );

    render() {
        const imageComponent = this.props.currentNews ? (
            <img
                src={this.props.currentNews.getImageUrl()}
                className="news__image"
                alt=""
            />
        ) : (
            <></>
        );

        return (
            <>
                <Dialog
                    header="Внимание!"
                    footer={this.deleteDialogFooterTemplate}
                    visible={this.state.delete_dialog_visible}
                    style={{width: "50vw"}}
                    modal={true}
                    onHide={() => {
                        this.hideDeleteDialog();
                    }}
                >
                    Вы уверенны, что хотите удалить новость?
                </Dialog>
                <form
                    className="card col-12 mt-2 p-2"
                    onSubmit={(e: any) => {
                        e.preventDefault();
                    }}
                >
                    <div className="row m-1">
                        <label className="col-lg-3">Заглавие новости</label>
                        <InputText
                            className="col-lg-4"
                            value={this.state.news_title}
                            placeholder="Заглавие"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({news_title: e.target.value});
                            }}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-3">Изображение</label>
                        <div className="col-lg-4 p-0">
                            <div style={{width: "100%"}}>{imageComponent}</div>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={this.uploadHandler}
                                    accept="image/*"
                                />
                                <label className="custom-file-label">
                                    {this.state.filename}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-3">Описание</label>
                        <InputTextarea
                            className="col-lg-8"
                            maxLength={this.state.max_description_length}
                            rows={5}
                            cols={30}
                            autoResize={true}
                            style={{maxHeight: "200px"}}
                            value={this.state.news_content}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                this.setState({news_content: e.target.value});
                            }}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-3">Теги</label>
                        <div className="col-lg-9" style={{paddingLeft: 0}}>
                            <TagInput
                                ref={e => {
                                    this.tagInputRef = e;
                                }}
                                initTags={this.state.tags}
                                changeHandler={(tags: string[]) => {
                                    this.setState({tags: tags});
                                }}
                            />
                        </div>
                    </div>
                    <div className="row m-1 ml-2">
                        <Button
                            label={this.props.currentNews ? "Сохранить" : "Добавить новость"}
                            onClick={() => {
                                this.saveButtonHandle();
                            }}
                        />
                        {this.props.currentNews ? (
                            <Button
                                label="Удалить"
                                className="p-button-danger ml-3"
                                onClick={() => {
                                    this.showDeleteDialog();
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </form>
            </>
        );
    }
}

export default NewsFormComponent;

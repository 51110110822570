import React from "react";
import GitCommitInfo from "../../_git_commit_info";
import "./AppVersionComponentStyle.scss"

export default class AppVersionComponent extends React.Component {
  render() {
    return (
      <span className="app-version-field">
        Version:{" "}
        {process.env.REACT_APP_NAME +
          " " +
          process.env.REACT_APP_VERSION +
          "-" +
          GitCommitInfo.lastCommitId}
      </span>
    );
  }
}

import React from "react";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {Growl} from "primereact/growl";
import {isDevMode} from "../../../../helpers/AppHelpers";
import {
    Bind,
    Currency,
    getCurrency,
    IDiscount, IUser,
    WrongArgumentError
} from "@shift-mono/common";
import AgentsDropdown from "./AgentsDropdown";

export interface IDiscountFormData {
    promoCode: string,
    currencyValue: number,
    currency: Currency,

    isLimited: boolean,
    limitPerAccount: number,

    isFinite: boolean,
    startDate: Date | undefined,
    endDate: Date | undefined,

    referalId: string | undefined,
}

interface IDiscountFormComponentProps {
    saveHandler: (discount: IDiscountFormData) => void;
    currentDiscount?: IDiscount;
    availableAgents: IUser[];
}

interface IDiscountFormComponentState extends IDiscountFormData {
    currentDiscountInit: boolean
}

export class DiscountFormComponent extends React.Component<IDiscountFormComponentProps, IDiscountFormComponentState> {
    growl: any = undefined;

    constructor(props: IDiscountFormComponentProps) {
        super(props);

        this.state = {
            promoCode: "",
            currencyValue: 0,
            currency: Currency.Percent,

            isLimited: false,
            limitPerAccount: 0,

            isFinite: false,
            startDate: undefined,
            endDate: undefined,

            currentDiscountInit: false,
            referalId: undefined
        };
    }
    @Bind()
    renderLimitedPerAccountBlock() {
        return <>
            <div className="row m-1">
                <label htmlFor="cb1" className="col-lg-4">
                    Ограничен по количеству на аккаунт
                </label>
                <div className="d-flex align-items-center">
                    <Checkbox
                        className="col-1"
                        inputId="cb1"
                        onChange={(e: any) => this.setState({isLimited: e.checked})}
                        checked={this.state.isLimited}
                    />
                </div>
            </div>
            {this.state.isLimited ?
                <div className="row m-1">
                    <label className="col-lg-4">Количество на аккаунт</label>
                    <InputText
                        className="col-lg-4 ml-3 mr-3"
                        value={this.state.limitPerAccount}
                        placeholder="Количество на аккаунт"
                        keyfilter="int"
                        onChange={(e: any) =>
                            this.setState({limitPerAccount: e.target.value})
                        }
                    />
                </div>
                : <></>}
        </>
    }
    @Bind()
    renderFiniteDateBlock() {
        return <>
            <div className="row m-1">
                <label htmlFor="cb1" className="col-lg-4">
                    Конечная скидка
                </label>
                <div className="d-flex align-items-center">
                    <Checkbox
                        className="col-1"
                        inputId="cb1"
                        onChange={(e: any) => this.setState({isFinite: e.checked})}
                        checked={this.state.isFinite}
                    />
                </div>
            </div>
            {this.state.isFinite ? <>
                    <div className="row m-1">
                        <label className="col-lg-4">Дата начала действия скидки</label>
                        <Calendar
                            className="col-lg-4"
                            value={this.state.startDate}
                            placeholder="Дата начала"
                            maxDate={this.state.endDate}
                            onChange={(e: any) => {
                                if (e.value instanceof Date) {
                                    let newDate = e.value;
                                    if (newDate) {
                                        newDate.setHours(3, 0, 0, 0);
                                    }
                                    this.setState({startDate: newDate});
                                }
                            }}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Дата окончания действия скидки</label>
                        <Calendar
                            className="col-lg-4"
                            value={this.state.endDate}
                            placeholder="Дата окончания"
                            minDate={this.state.startDate}
                            onChange={(e: any) => {
                                let newDate = e.value;
                                if (newDate) {
                                    newDate.setHours(3, 0, 0, 0);
                                }
                                this.setState({endDate: newDate});
                            }}
                        />
                    </div>
                </>
                : <></>}
        </>;
    }
    @Bind()
    renderAgentsDropdown() {

        return <>
            <div className="row m-1">
                <label className="col-lg-4">Связанный агент</label>
                <AgentsDropdown
                    className="col-lg-4 ml-3 mr-3"
                    availableAgents={this.props.availableAgents}
                    currentAgentId={this.state.referalId}
                    changeHandler={(selectedAgent) => {
                        this.setState({referalId: selectedAgent? selectedAgent.getId(): undefined})
                    }}
                />
            </div>
        </>
    }
    @Bind()
    validateForm(): boolean {
        if (this.state.promoCode === "" ||
            this.state.currencyValue.toString() === "" ||
            !isFinite(this.state.currencyValue)) {
            return false;
        }

        if (this.state.isFinite && (!this.state.startDate || !this.state.endDate)) {
            return false;
        }

        return !(this.state.isLimited &&
            (!isFinite(this.state.limitPerAccount) ||
                this.state.limitPerAccount.toString() === ""
            ));
    }
    @Bind()
    saveButtonHandler() {
        if (!this.validateForm()) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Не все поля заполнены"
            });
            return
        }
        this.props.saveHandler({
            promoCode: this.state.promoCode,
            currencyValue: this.state.currencyValue,
            currency: this.state.currency,

            isLimited: this.state.isLimited,
            limitPerAccount: this.state.limitPerAccount,

            isFinite: this.state.isFinite,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            referalId: this.state.referalId,
        });
    }
    @Bind()
    clearForm() {
        this.setState({
            promoCode: "",
            currencyValue: 0,
            currency: Currency.Percent,

            isLimited: false,
            limitPerAccount: 0,

            isFinite: false,
            startDate: undefined,
            endDate: undefined,
            referalId: undefined,
        });
    }

    fillStateFromDiscount(discount: IDiscount, state: IDiscountFormComponentState) {
        try {
            state.currency = getCurrency(discount.getCurrency());
            state.promoCode = discount.getCode();
            state.currencyValue = discount.getValue();
            if (discount.getStartDt() && discount.getEndDt()) {
                state.isFinite = true;
                state.startDate = discount.getStartDt();
                state.endDate = discount.getEndDt();
            }

            if (discount.getLimited()) {
                state.isLimited = discount.getLimited()
                state.limitPerAccount = discount.getCountPerAccount();
            }
            state.currentDiscountInit = true;
            state.referalId = discount.getReferalId();
        } catch (err) {
            if (err instanceof WrongArgumentError) {
                if (isDevMode()) {
                    console.log(err.message);
                }
            } else {
                throw err;
            }
        }
    }

    shouldComponentUpdate(
        nextProps: Readonly<IDiscountFormComponentProps>,
        nextState: Readonly<IDiscountFormComponentState>,
        nextContext: any): boolean {
        if (!this.state.currentDiscountInit &&
            nextProps.currentDiscount !== undefined) {
            this.fillStateFromDiscount(nextProps.currentDiscount, nextState)
        }
        return true;
    }

    render() {
        return <>
            <Growl ref={el => (this.growl = el)}/>
            <form
                className="card col-12 mt-2 p-2"
                onSubmit={(e: any) => {
                    e.preventDefault();
                }}
            >
                <div className="row m-1">
                    <label className="col-lg-4">Код</label>
                    <InputText
                        className="col-lg-4 ml-3 mr-3"
                        value={this.state.promoCode}
                        placeholder="Код"
                        onChange={(e: any) => this.setState({promoCode: e.target.value})}
                        name="code"
                    />
                </div>
                <div className="row m-1">
                    <label className="col-lg-4">Процент скидки</label>
                    <InputText
                        className="col-lg-4 ml-3 mr-3"
                        value={this.state.currencyValue}
                        placeholder="Процент скидки"
                        keyfilter="int"
                        onChange={(e: any) =>
                            this.setState({currencyValue: e.target.value})
                        }
                    />
                </div>
                {this.renderAgentsDropdown()}
                {this.renderLimitedPerAccountBlock()}
                {this.renderFiniteDateBlock()}

                <div className="row m-1 ml-2">
                    <Button
                        label={this.props.currentDiscount? "Сохранить" :"Добавить"}
                        onClick={() => this.saveButtonHandler()}
                    />
                </div>
            </form>
        </>
    }
}
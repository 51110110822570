import React from "react";
import DirectionListComponent, {IListFilterParams} from "./DirectionListComponent";
import AddDirectionFormComponent from "./AddDirectionFormComponent";
import DirectionsFilterComponent from "./DirectionsFilterComponent";
import {Bind} from "@shift-mono/common";

interface IDirectionComponentProps {
}

interface IDirectionComponentState {
    directionListFilterParams: IListFilterParams | undefined
}

export default class DirectionComponent extends React.Component<IDirectionComponentProps,
    IDirectionComponentState> {
    private directionListRef: any = undefined;

    constructor(props: IDirectionComponentProps) {
        super(props);

        this.state = {
            directionListFilterParams: undefined
        }
    }
    @Bind()
    reloadListContent() {
        if (this.directionListRef) {
            this.directionListRef.reloadContent();
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-lg-6">
                        <DirectionListComponent
                            filterParams={this.state.directionListFilterParams}
                            ref={ref => {
                                this.directionListRef = ref;
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-12">
                                <DirectionsFilterComponent
                                    callbackHandler={(data) => {
                                        this.setState({
                                            directionListFilterParams: {
                                                firstDestinationId: data.firstDestination ? data.firstDestination.getId() : undefined,
                                                secondDestinationId: data.secondDestination ? data.secondDestination.getId() : undefined
                                            }
                                        });
                                        if (
                                            this.directionListRef &&
                                            this.directionListRef.reloadContent
                                        ) {
                                            this.directionListRef.reloadContent();
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-lg-12">
                                <AddDirectionFormComponent
                                    directionAddedCallback={() => {
                                        if (
                                            this.directionListRef &&
                                            this.directionListRef.reloadContent
                                        ) {
                                            this.directionListRef.reloadContent();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

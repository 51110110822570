import React from "react";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import "./StoragePointFormComponentStyle.scss";
import MultiLanguageTextInputComponent from "../../../common/MultiLanguageTextInput/MultiLanguageTextInputComponent";
import DestinationPickerComponent from "./DestinationPickerComponent";
import {
    IService,
    IStoragePoint,
    StoragePointType,
    ILocalizedField,
    Address,
    IAddress,
    IDestination, Bind, IPartner
} from "@shift-mono/common";
import PartnerPickerComponent from "./PartnerPickerComponent";

interface ICommonData {
    type: StoragePointType;
    destinationId: string;
    couriersAvailable: boolean;
    canStore: boolean;
    selfServiceAvailable: boolean;
    latitude: number;
    longitude: number;
    reservedCellCount: number;
    address: IAddress;
    localizedName: ILocalizedField[];
    name: string;
    active: boolean;
    imageFile: File | null;
    partnerId: string | undefined;
}

export interface IStoragePointFormData extends ICommonData {
    selectedAvailableServiceIds: string[];
}

interface IStoragePointFormComponentProps {
    currentStoragePoint?: IStoragePoint;
    availableSevices?: IService[];
    availableDestinations?: IDestination[];
    availablePartners?: IPartner[];
    saveCallback?: (data: IStoragePointFormData) => void;
}

interface IStoragePointFormComponentState extends ICommonData{
    firstInit: boolean;
    currentSelectedService: IService | null;
    selectedAvailableServicesList: IServiceListItem[];
    filename: string;
}

interface IServiceListItem {
    id: string;
    service: IService | undefined;
}

class StoragePointFormComponent extends React.Component<IStoragePointFormComponentProps,
    IStoragePointFormComponentState> {
    private multiLangInputRef: any = undefined;

    private defaultState = {
        currentSelectedService: null,
        selectedAvailableServicesList: [],

        filename: "",
        imageFile: null,

        active: true,
        name: "",
        localizedName: [],
        address: new Address(),
        reservedCellCount: 0,
        latitude: 0,
        longitude: 0,
        selfServiceAvailable: false,
        canStore: false,
        couriersAvailable: false,
        destinationId: "",
        type: StoragePointType.StoragePoint,
        partnerId: undefined,
    }

    constructor(props: IStoragePointFormComponentProps) {
        super(props);

        this.state = {
            firstInit: true,
            ...this.defaultState,
        };
    }
    @Bind()
    clearForm() {
        this.setState(this.defaultState);

        if (this.multiLangInputRef) {
            this.multiLangInputRef.clear();
        }
    }
    @Bind()
    removeServiceFromList(serviceId: string) {
        this.setState({
            selectedAvailableServicesList: this.state.selectedAvailableServicesList.filter(
                (service: IServiceListItem) => {
                    return service.id !== serviceId;
                }
            )
        });
    }
    @Bind()
    uploadHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (files) {
            const file = files[0];
            if (file) {
                this.setState({imageFile: file});
                this.setState({filename: file.name});
            }
        }
    }
    @Bind()
    serviceChanged(e: any) {
        this.setState({currentSelectedService: e.value});
    }
    @Bind()
    saveButtonHandle() {
        if (this.props.saveCallback) {
            this.props.saveCallback({
                active: this.state.active,
                name: this.state.name,
                localizedName: this.state.localizedName,
                address: this.state.address,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                imageFile: this.state.imageFile,
                reservedCellCount: this.state.reservedCellCount,
                selectedAvailableServiceIds: this.state.selectedAvailableServicesList.map(
                    (serviceItem: IServiceListItem) => {
                        return serviceItem.id;
                    }
                ),
                selfServiceAvailable: this.state.selfServiceAvailable,
                canStore: this.state.canStore,
                couriersAvailable: this.state.couriersAvailable,
                destinationId: this.state.destinationId,
                type: this.state.type,
                partnerId: this.state.partnerId
            });
        }
    }

    fillStateOfStoragePoint(
        storagePoint: IStoragePoint,
        existState: IStoragePointFormComponentState
    ) {
        existState.active = storagePoint.isActive();
        existState.name = storagePoint.getName();
        existState.localizedName = storagePoint.getLocalizedName();
        existState.address = storagePoint.getAddress();
        //TODO Подумать как правильно запросить и не перепутать широту и долготу
        existState.latitude = storagePoint.getLocation().getCoord()[0];
        existState.longitude = storagePoint.getLocation().getCoord()[1];
        existState.reservedCellCount = storagePoint.getCells();
        existState.selfServiceAvailable = storagePoint.isSelfServiceAvailable();
        existState.couriersAvailable = storagePoint.isCouriersAvailable();
        existState.destinationId = storagePoint.getDestinationId();
        existState.type = storagePoint.getType();
        existState.canStore = storagePoint.canStore();
        existState.partnerId = storagePoint.getPartnerId();

        existState.selectedAvailableServicesList = storagePoint
            .getServices()
            .map((serviceId: string) => {
                if (this.props.availableSevices) {
                    const services = this.props.availableSevices!.filter(
                        (service: IService) => {
                            return service.getId() === serviceId;
                        }
                    );

                    if (services.length > 0) {
                        return {id: serviceId, service: services[0]};
                    }
                }

                return {id: serviceId, service: undefined};
            });
    }

    shouldComponentUpdate(
        nextProps: IStoragePointFormComponentProps,
        nextState: IStoragePointFormComponentState
    ) {
        if (nextProps.currentStoragePoint !== undefined && this.state.firstInit) {
            this.fillStateOfStoragePoint(nextProps.currentStoragePoint, nextState);
            nextState.firstInit = false;
        }

        return true;
    }

    getTypeDropdownItem(type: StoragePointType): {type: StoragePointType, title: string} {
        switch (type) {
            case StoragePointType.StoragePoint:
                return {type: StoragePointType.StoragePoint, title: "Пункт хранения"};
            case StoragePointType.Hotel:
                return {type: StoragePointType.Hotel, title: "Отель"};
        }
        return {type: StoragePointType.None, title: "Нет данных"};
    }

    render() {
        const imageComponent = this.props.currentStoragePoint ? (
            <img
                src={this.props.currentStoragePoint.getImages()[0]}
                className="storage-point__image"
                alt=""
            />
        ) : (
            <></>
        );

        return (
            <>
                <form
                    className="card col-12 mt-2 p-2"
                    onSubmit={(e: any) => {
                        e.preventDefault();
                    }}
                >
                    <div className="row m-1">
                        <label className="col-lg-4">Активен</label>
                        <Checkbox
                            onChange={e => this.setState({active: e.checked})}
                            checked={this.state.active}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Имя пункта хранения</label>
                        <InputText
                            value={this.state.name}
                            className="col-lg-4"
                            placeholder="Имя пункта хранения"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.setState({name: e.target.value})
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4"/>
                        <MultiLanguageTextInputComponent
                            className="col-lg-4 p-0"
                            ref={el => {
                                this.multiLangInputRef = el;
                            }}
                            initLocalizedFields={this.state.localizedName}
                            changeLangValueCallback={localizedFields => {
                                this.setState({localizedName: localizedFields});
                            }}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Страна</label>
                        <InputText
                            value={this.state.address.getCountry()}
                            className="col-lg-4"
                            placeholder="Страна"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newAddress = this.state.address;
                                newAddress.setCountry(e.target.value);
                                this.setState({address: newAddress});
                            }
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Город</label>
                        <InputText
                            value={this.state.address.getCity()}
                            className="col-lg-4"
                            placeholder="Город"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                const newAddress = this.state.address;
                                newAddress.setCity(e.target.value);
                                this.setState({address: newAddress});
                            }
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Улица</label>
                        <InputText
                            value={this.state.address.getStreet()}
                            className="col-lg-4"
                            placeholder="Улица"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newAddress = this.state.address;
                                newAddress.setStreet(e.target.value);
                                this.setState({address: newAddress});
                            }
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Дом</label>
                        <InputText
                            value={this.state.address.getHouse()}
                            className="col-lg-4"
                            placeholder="Дом"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newAddress = this.state.address;
                                newAddress.setHouse(e.target.value);
                                this.setState({address: newAddress});
                            }
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Квартира</label>
                        <InputText
                            value={this.state.address.getFlat()}
                            className="col-lg-4"
                            placeholder="Квартира"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newAddress = this.state.address;
                                newAddress.setFlat(e.target.value);
                                this.setState({address: newAddress});
                            }
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Индекс</label>
                        <InputText
                            value={this.state.address.getZipCode()}
                            className="col-lg-4"
                            placeholder="Индекс"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newAddress = this.state.address;
                                newAddress.setZipCode(e.target.value);
                                this.setState({address: newAddress});
                            }
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Дополнительно</label>
                        <InputText
                            value={this.state.address.getAdditional()}
                            className="col-lg-4"
                            placeholder="Дополнительно"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newAddress = this.state.address;
                                newAddress.setAdditional(e.target.value);
                                this.setState({address: newAddress});
                            }
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Есть самообслуживание</label>
                        <Checkbox
                            onChange={e => this.setState({selfServiceAvailable: e.checked})}
                            checked={this.state.selfServiceAvailable}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Есть хранение</label>
                        <Checkbox
                            onChange={e => this.setState({canStore: e.checked})}
                            checked={this.state.canStore}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Есть доставка курьером</label>
                        <Checkbox
                            onChange={e => this.setState({couriersAvailable: e.checked})}
                            checked={this.state.couriersAvailable}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">
                            Количество ячеек под оборудование
                        </label>
                        <InputText
                            value={this.state.reservedCellCount}
                            className="col-lg-4"
                            placeholder="Кол-во ячеек"
                            keyfilter="int"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.setState({
                                    reservedCellCount: Number.parseInt(e.target.value)
                                })
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Широта</label>
                        <InputText
                            value={this.state.latitude}
                            className="col-lg-4"
                            placeholder="Широта"
                            keyfilter="num"
                            onChange={(e: any) => this.setState({latitude: e.target.value})}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Долгота</label>
                        <InputText
                            value={this.state.longitude}
                            className="col-lg-4"
                            placeholder="Долгота"
                            keyfilter="num"
                            onChange={(e: any) =>
                                this.setState({longitude: e.target.value})
                            }
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Связанный город</label>
                        <DestinationPickerComponent
                            className="col-lg-4"
                            currentDestinationId={this.state.destinationId}
                            availableDestinations={this.props.availableDestinations ? this.props.availableDestinations : []}
                            changeHandler={(selectedDestination) => {
                                this.setState({destinationId: selectedDestination.getId()})
                            }}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Тип точки хранения</label>
                        <Dropdown
                            value={this.getTypeDropdownItem(this.state.type)}
                            className="col-lg-4"
                            optionLabel={"title"}
                            options={[
                                this.getTypeDropdownItem(StoragePointType.StoragePoint),
                                this.getTypeDropdownItem(StoragePointType.Hotel)
                            ]}
                            onChange={(e) => {
                                this.setState({type: e.value.type})
                            }}
                            placeholder="Тип"
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Связанный партнер</label>
                        <PartnerPickerComponent
                            className="col-lg-4"
                            currentPartnerId={this.state.partnerId}
                            availablePartners={this.props.availablePartners ? this.props.availablePartners : []}
                            changeHandler={(selectedPartner) => {
                                this.setState({partnerId: selectedPartner? selectedPartner.getId(): undefined})
                            }}
                        />
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Изображение</label>
                        <div className="col-lg-4 p-0">
                            <div style={{width: "100%"}}>{imageComponent}</div>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={this.uploadHandler}
                                    accept="image/*"
                                />
                                <label className="custom-file-label">
                                    {this.state.filename}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <label className="col-lg-4">Доступные услуги</label>
                        <div className="col-lg-4 p-0">
                            <div className="storage-point-form__service-row">
                                <Dropdown
                                    value={this.state.currentSelectedService}
                                    className="storage-point-form__dropdown"
                                    options={
                                        this.props.availableSevices
                                            ? this.props.availableSevices
                                            : []
                                    }
                                    onChange={this.serviceChanged}
                                    placeholder="Услуга"
                                    optionLabel="name"
                                />
                                <Button
                                    className="storage-point-form__button ml-2"
                                    label="+"
                                    onClick={() => {
                                        if (this.state.currentSelectedService) {
                                            this.setState({
                                                selectedAvailableServicesList: [
                                                    ...this.state.selectedAvailableServicesList,
                                                    {
                                                        id: this.state.currentSelectedService!.getId(),
                                                        service: this.state.currentSelectedService
                                                    }
                                                ],
                                                currentSelectedService: null
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row m-1 ml-2">
                        <DataTable
                            className="main_list_table"
                            value={this.state.selectedAvailableServicesList}
                        >
                            <Column
                                body={(rowData: IServiceListItem) => {
                                    return rowData.service
                                        ? rowData.service.getName()
                                        : rowData.id;
                                }}
                                header="Наименование услуги"
                            />
                            <Column
                                body={(rowData: IServiceListItem) => {
                                    return (
                                        <>
                                            <Button
                                                type="button"
                                                icon="pi pi-times"
                                                className="p-button-danger"
                                                onClick={() => {
                                                    this.removeServiceFromList(rowData.id);
                                                }}
                                            />
                                        </>
                                    );
                                }}
                                style={{width: "4em"}}
                            />
                        </DataTable>
                    </div>
                    <div className="row m-1 ml-2">
                        <Button
                            label={
                                this.props.currentStoragePoint
                                    ? "Сохранить"
                                    : "Добавить пункт хранения"
                            }
                            onClick={() => {
                                this.saveButtonHandle();
                            }}
                        />
                    </div>
                </form>
            </>
        );
    }
}

export default StoragePointFormComponent;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoragePointLocation = void 0;

var StoragePointLocationType_1 = require("./StoragePointLocationType");

var StoragePointLocation =
/** @class */
function () {
  function StoragePointLocation(latitude, longitude, type) {
    if (type === void 0) {
      type = StoragePointLocationType_1.StoragePointLocationType.Point;
    }

    this.latitude = latitude;
    this.longitude = longitude;
    this.type = type;
  }

  StoragePointLocation.getStoragePointLocationType = function (type) {
    switch (type.toLowerCase()) {
      case StoragePointLocationType_1.StoragePointLocationType.Point:
        return StoragePointLocationType_1.StoragePointLocationType.Point;

      default:
        return StoragePointLocationType_1.StoragePointLocationType.None;
    }
  };

  StoragePointLocation.fromJson = function (data) {
    var currentLocationType = StoragePointLocation.getStoragePointLocationType(data.type);
    return new StoragePointLocation(data.coordinates[0] ? data.coordinates[0] : 0, data.coordinates[1] ? data.coordinates[1] : 0, currentLocationType);
  };

  StoragePointLocation.prototype.getType = function () {
    return this.type;
  };

  StoragePointLocation.prototype.getCoord = function () {
    return [this.latitude, this.longitude];
  };

  return StoragePointLocation;
}();

exports.StoragePointLocation = StoragePointLocation;
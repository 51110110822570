"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToObject = exports.convertFromObject = void 0; //Create localized field array from object looks like { en : english test, ru : russian text }

exports.convertFromObject = function (languagesObject) {
  var localizedFieldsArray = [];

  for (var lang in languagesObject) {
    if (languagesObject.hasOwnProperty(lang)) {
      localizedFieldsArray.push({
        lang: lang,
        value: languagesObject[lang]
      });
    }
  }

  return localizedFieldsArray;
};

exports.convertToObject = function (localizedFields) {
  return localizedFields.reduce(function (currentValue, localizedNameItem) {
    currentValue[localizedNameItem.lang] = localizedNameItem.value;
    return currentValue;
  }, {});
};
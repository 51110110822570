"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Model = void 0;

var Model =
/** @class */
function () {
  function Model() {}

  Model.verifyRequiredProperties = function (obj, requiredProps) {
    var objKeys = Object.keys(obj);
    return requiredProps.reduce(function (verifyVal, propKey) {
      if (!objKeys.includes(propKey)) {
        verifyVal.isAllPropsExist = false;
        verifyVal.missingProps.push(propKey);
      }

      return verifyVal;
    }, {
      isAllPropsExist: true,
      missingProps: []
    });
  };

  return Model;
}();

exports.Model = Model;
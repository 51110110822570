import C from "../../actions/actionsTypes";
import { COMPONENT_STATE_STATUS } from "../../actions/actionCreators";

export const component_state = (state : any = {}, action : any) => {
    switch (action.type) {
      case C.COMPONENT_STATE.REQUEST_ENTITIES: {
        return Object.assign({}, state, {
          [action.page_title]: {
            ...state[action.page_title],
            isFetching: true
          }
        });
      }
      case C.COMPONENT_STATE.REQUEST_ENTITIES_FAILURE: {
        return Object.assign({}, state, {
          [action.page_title]: {
            ...state[action.page_title],
            isFetching: false,
            error_message: action.error_message,
            status: COMPONENT_STATE_STATUS.ERROR
          }
        });
      }
      case C.COMPONENT_STATE.REQUEST_ENTITIES_SUCCESS: {
        return Object.assign({}, state, {
          [action.page_title]: {
            ...state[action.page_title],
            isFetching: false,
            error_message: action.error_message,
            status: COMPONENT_STATE_STATUS.SUCCESS
          }
        });
      }
      case C.COMPONENT_STATE.RECEIVE_ENTITIES: {
        return Object.assign({}, state, {
          [action.page_title]: {
            ...state[action.page_title],
            lastUpdate: Date.now(),
            items: action.entities
          }
        });
      }
      case C.COMPONENT_STATE.CLEAR: {
        return Object.assign({}, state, {
          [action.page_title]: {}
        });
      }
      default:
        return state;
    }
  };
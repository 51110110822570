"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoragePoint = void 0;

var errors_1 = require("../errors");

var StoragePointType_1 = require("./StoragePointType");

var Model_1 = require("./Model");

var Address_1 = require("./Address");

var StoragePointLocation_1 = require("./StoragePointLocation");

var LocalizedField_1 = require("./LocalizedField");

var StoragePoint =
/** @class */
function (_super) {
  __extends(StoragePoint, _super);

  function StoragePoint(args) {
    var _this = _super.call(this) || this;

    _this.args = args;
    return _this;
  }

  StoragePoint.fromJson = function (data) {
    var requiredProps = ["id", "name"];
    var verifyResult = StoragePoint.verifyRequiredProperties(data, requiredProps);

    if (!verifyResult.isAllPropsExist) {
      throw new errors_1.MissingPropertiesError("Missing props: " + verifyResult.missingProps.toString() + " in " + this.constructor.name + " json data.");
    }

    var SPLocation = StoragePointLocation_1.StoragePointLocation.fromJson(data.location);
    var localizedNameArray = [];

    if (data.localizedName) {
      localizedNameArray = LocalizedField_1.convertFromObject(data.localizedName);
    }

    var address = Address_1.Address.fromJson(data.hasOwnProperty("address") ? data.address : {});
    var currentStoragePointType = StoragePointType_1.StoragePointType.None;

    if (data.type) {
      switch (data.type.toLowerCase()) {
        case StoragePointType_1.StoragePointType.StoragePoint:
          currentStoragePointType = StoragePointType_1.StoragePointType.StoragePoint;
          break;

        case StoragePointType_1.StoragePointType.Hotel:
          currentStoragePointType = StoragePointType_1.StoragePointType.Hotel;
          break;
      }
    }

    return new StoragePoint({
      id: data.id ? data.id : "",
      active: data.active ? data.active : false,
      location: SPLocation,
      address: address,
      images: data.images ? data.images : [],
      name: data.name ? data.name : "",
      localizedName: localizedNameArray,
      services: data.services ? data.services : [],
      cells: data.cells ? data.cells : 0,
      freeCells: data.freeCells ? data.freeCells : 0,
      selfServiceAvailable: data.selfService ? data.selfService : false,
      couriersAvailable: data.couriers ? data.couriers : false,
      destinationId: data.destinationId ? data.destinationId : "",
      type: currentStoragePointType,
      canStore: data.canStore ? data.canStore : false,
      partnerId: data.partnerId ? data.partnerId : undefined
    });
  };

  StoragePoint.prototype.getId = function () {
    return this.args.id;
  };

  StoragePoint.prototype.getType = function () {
    return this.args.type;
  };

  StoragePoint.prototype.getLocation = function () {
    return this.args.location;
  };

  StoragePoint.prototype.getAddress = function () {
    return this.args.address;
  };

  StoragePoint.prototype.getImages = function () {
    return this.args.images;
  };

  StoragePoint.prototype.getName = function () {
    return this.args.name;
  };

  StoragePoint.prototype.getLocalizedName = function () {
    return this.args.localizedName;
  };

  StoragePoint.prototype.getServices = function () {
    return this.args.services;
  };

  StoragePoint.prototype.getCells = function () {
    return this.args.cells;
  };

  StoragePoint.prototype.getFreeCells = function () {
    return this.args.freeCells;
  };

  StoragePoint.prototype.isActive = function () {
    return this.args.active;
  };

  StoragePoint.prototype.isSelfServiceAvailable = function () {
    return this.args.selfServiceAvailable;
  };

  StoragePoint.prototype.isCouriersAvailable = function () {
    return this.args.couriersAvailable;
  };

  StoragePoint.prototype.canStore = function () {
    return this.args.canStore;
  };

  StoragePoint.prototype.getDestinationId = function () {
    return this.args.destinationId;
  };

  StoragePoint.prototype.getPartnerId = function () {
    return this.args.partnerId;
  };

  return StoragePoint;
}(Model_1.Model);

exports.StoragePoint = StoragePoint;
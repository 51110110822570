import React from "react";
import "./MultiLanguageTextInputComponentStyle.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ILocalizedField } from "@shift-mono/common";

interface IMultiLanguageTextInputComponentProps {
  className?: string;
  availableLanguages?: string[];
  initLocalizedFields?: ILocalizedField[];
  changeLangValueCallback?: (localizedFields: ILocalizedField[]) => void;
}
interface IMultiLanguageTextInputComponentState {
  firstInit: boolean;
  availableLang: string[];
  selectedLang: ILocalizedField[];
}

class MultiLanguageTextInputComponent extends React.Component<
  IMultiLanguageTextInputComponentProps,
  IMultiLanguageTextInputComponentState
> {
  private defaultLanguages = ["en", "ru"];
  constructor(props: IMultiLanguageTextInputComponentProps) {
    super(props);

    this.state = {
      firstInit: true,
      availableLang: this.props.availableLanguages
        ? this.props.availableLanguages
        : this.defaultLanguages,
      selectedLang: this.props.initLocalizedFields
        ? this.props.initLocalizedFields
        : []
    };

    this.clear = this.clear.bind(this);
    this.selectLang = this.selectLang.bind(this);
    this.unselectLang = this.unselectLang.bind(this);
    this.changeSelectedLangValue = this.changeSelectedLangValue.bind(this);
  }

  selectLang(lang: string, value: string = "") {
    const newSelectedLang = [...this.state.selectedLang, { lang, value }];
    this.setState({
      selectedLang: newSelectedLang,
      availableLang: this.state.availableLang.filter(
        (availableLang: string) => {
          return lang !== availableLang;
        }
      )
    });

    if (this.props.changeLangValueCallback) {
      this.props.changeLangValueCallback(newSelectedLang);
    }
  }

  unselectLang(lang: string) {
    const newSelectedLang = this.state.selectedLang.filter(
      (selectedLang: ILocalizedField) => {
        return selectedLang.lang !== lang;
      }
    );

    this.setState({
      selectedLang: newSelectedLang,
      availableLang: [...this.state.availableLang, lang]
    });

    if (this.props.changeLangValueCallback) {
      this.props.changeLangValueCallback(newSelectedLang);
    }
  }

  changeSelectedLangValue(lang: string, value: string) {
    const newSelectedLangs = this.state.selectedLang.map(
      (selectLang: ILocalizedField) => {
        if (selectLang.lang === lang) {
          selectLang.value = value;
        }
        return selectLang;
      }
    );

    this.setState({
      selectedLang: newSelectedLangs
    });

    if (this.props.changeLangValueCallback) {
      this.props.changeLangValueCallback(newSelectedLangs);
    }
  }

  clear() {
    this.setState({
      availableLang: this.props.availableLanguages
        ? this.props.availableLanguages
        : this.defaultLanguages,
      selectedLang: []
    });
  }

  shouldComponentUpdate(
    nextProps: IMultiLanguageTextInputComponentProps,
    nextState: IMultiLanguageTextInputComponentState
  ) {
    if (this.state.firstInit && nextProps.initLocalizedFields) {
      nextState.firstInit = false;
      nextState.selectedLang = nextProps.initLocalizedFields;
      nextProps.initLocalizedFields.map((localizedField: ILocalizedField) => {
        nextState.availableLang = nextState.availableLang.filter(
          (availableLang: string) => {
            return localizedField.lang !== availableLang;
          }
        );
        return localizedField;
      });
    }

    return true;
  }

  render() {
    return (
      <div className={this.props.className ? this.props.className : ""}>
        {this.state.selectedLang.map((selectedLang: ILocalizedField) => {
          return (
            <div key={selectedLang.lang} className="multi-input-row mb-2">
              <InputText
                className="multi-input-row__field"
                value={selectedLang.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.changeSelectedLangValue(
                    selectedLang.lang,
                    e.target.value
                  );
                }}
              />
              <Button
                label={"- " + selectedLang.lang}
                className="multi-input-row__button ml-2"
                onClick={() => {
                  this.unselectLang(selectedLang.lang);
                }}
              />
            </div>
          );
        })}
        <div>
          {this.state.availableLang.map((lang: string) => {
            return (
              <Button
                label={"+ " + lang}
                key={lang}
                className="mr-2"
                onClick={() => {
                  this.selectLang(lang);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default MultiLanguageTextInputComponent;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DataResponse = void 0;

var DataResponse =
/** @class */
function () {
  function DataResponse(data) {
    this.data = data;
  }

  DataResponse.prototype.getData = function () {
    return this.data;
  };

  return DataResponse;
}();

exports.DataResponse = DataResponse;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAuthDomain = exports.getApiDomain = void 0;

exports.getApiDomain = function () {
  return process.env.REACT_APP_API_DOMAIN ? process.env.REACT_APP_API_DOMAIN : "";
};

exports.getAuthDomain = function () {
  return process.env.REACT_APP_AUTH_DOMAIN ? process.env.REACT_APP_AUTH_DOMAIN : "";
};
import React from "react";
import {Dropdown} from "primereact/dropdown";
import {Bind, IUser} from "@shift-mono/common";

interface IAgentsDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    availableAgents: IUser[];
    changeHandler: (selectedAgent: IUser | undefined) => void;
    currentAgentId?: string;
}
interface IAgentsDropdownState {}

interface DropdownItem {
    title: string,
    value: IUser | undefined,
    id: string,
}

export default class AgentsDropdown extends React.Component<IAgentsDropdownProps, IAgentsDropdownState> {

    @Bind()
    getOptions(): DropdownItem[] {
        return [...this.props.availableAgents, undefined]
            .map((item) => {
                return this.getOptionItemFor(item)
            })
    }

    getOptionItemFor(agent: IUser | undefined) {
        return agent
            ? {title: this.getFullnameOrPhone(agent), value: agent, id: agent.getId()}
            : {title: "Нет Агента", value: agent, id: "-1"}
    }

    getFullnameOrPhone(agent: IUser): string {
        return agent.getFullName() !== "" ? agent.getFullName() : agent.getPhone();
    }

    render() {
        const currentAgent = this.props.availableAgents
            .filter((agent) => {
                return agent.getId() === this.props.currentAgentId
            })
            .pop()

        const current = this.getOptionItemFor(currentAgent);

        return <Dropdown
            className={this.props.className ? this.props.className : ""}
            optionLabel={"title"}
            value={current}
            options={this.getOptions()}
            onChange={(e) => {
                this.props.changeHandler(e.value.value);
            }}
            dataKey={"id"}
            placeholder="Выберите партнера"/>
    }
}
import React from "react";
import {Dropdown} from "primereact/dropdown";
import {Bind} from "@shift-mono/common";

interface IDropDownItem {
    title: string;
    value: boolean | undefined;
}

interface IClientStatusDropdownProps {
    statusValue: boolean | undefined;
    changeStatusHandler: (status: boolean | undefined) => void;
    strictMode?: boolean;
}

interface IClientStatusDropdownState {
}

export default class ClientStatusDropdown extends React.Component<IClientStatusDropdownProps, IClientStatusDropdownState> {

    @Bind()
    renderClientStatusDropdown() {
        return <Dropdown
            className={"col-lg-5"}
            value={this.getClientStatusDropdownOptionForOrDefault(this.props.statusValue)}
            options={this.getClientStatusDropdownOptions()}
            dataKey="title"
            onChange={this.changeClientStatusHandler}
            optionLabel="title"
        />
    }

    getClientStatusDropdownOptions(): IDropDownItem[] {
        const result: IDropDownItem[] = [
            {title: "Агент", value: true},
            {title: "Пользователь", value: false},
        ];

        if(!this.props.strictMode){
            result.push({title: "Все", value: undefined});
        }

        return result;
    }

    getClientStatusDropdownOptionForOrDefault(isAgent: boolean | undefined) {
        const options = this.getClientStatusDropdownOptions();
        const defaultValue = this.props.strictMode? {title: "Пользователь", value: false}: {title: "Все", value: undefined};

        return options.reduce((currentOption, item) => {
            if (item.value === isAgent) {
                currentOption = item;
            }
            return currentOption;
        }, defaultValue)
    }

    @Bind()
    changeClientStatusHandler(e: any) {
        this.props.changeStatusHandler(e.value.value);
    }

    render() {
        return <>{this.renderClientStatusDropdown()}</>
    }
}
import React from "react";
import {connect} from "react-redux";
import {InputText} from "primereact/inputtext";
import MultiLanguageTextInputComponent from "../../../common/MultiLanguageTextInput/MultiLanguageTextInputComponent";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import ContentLoaderComponent from "../../../common/ContentLoaderComponent";
import {getAccessToken} from "../../../../helpers/RequestHelpers";
import {
    ILocalizedField,
    IDestination,
    IDirection,
    ICountableResponse,
    AuthorizedRequestData,
    requestDestinations, Bind
} from "@shift-mono/common";

interface IDirectionFormComponentProps {
    currentDirection?: IDirection;
    getToken: () => Promise<string>;
    ref?: (item: any) => any;
    saveCallback?: (formData: IDirectionFormData) => void;
}

interface IDirectionFormComponentState extends IDirectionFormData {
    currentDirectionFirstInited: boolean;
    destinationInitited: boolean;

    destinations: IDestination[];
}

export interface IDirectionFormData {
    name: string;
    localizedName: ILocalizedField[];
    firstDestination: IDestination | undefined;
    secondDestination: IDestination | undefined;
    deliveryDuration: number;
}

class DirectionFormComponent extends React.Component<IDirectionFormComponentProps,
    IDirectionFormComponentState> {
    private multiLangInputRef: any = undefined;
    private defaultFormData = {
        name: "",
        localizedName: [],
        firstDestination: undefined,
        secondDestination: undefined,
        deliveryDuration: 5,
    }

    constructor(props: IDirectionFormComponentProps) {
        super(props);

        this.state = {
            currentDirectionFirstInited: false,
            destinationInitited: false,
            destinations: [],

            ...this.defaultFormData
        };
    }
    @Bind()
    clearForm() {
        this.setState(this.defaultFormData);

        if (this.multiLangInputRef) {
            this.multiLangInputRef.clear();
        }
    }
    @Bind()
    async destinationsRequest() {
        //TODO Добавить try catch
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token, {}, {limit: 0, skip: 0});

        return requestDestinations(request_data);
    }
    @Bind()
    destinationRequestResult(destinations: ICountableResponse<IDestination[]>) {
        this.setState({destinations: destinations.getData(), destinationInitited: true});
    }
    @Bind()
    firstDestinationChanged(e: any) {
        this.setState({firstDestination: e.value});
    }
    @Bind()
    secondDestinationChanged(e: any) {
        this.setState({secondDestination: e.value});
    }
    @Bind()
    saveButtonHandle() {
        if (this.props.saveCallback) {
            this.props.saveCallback({
                name: this.state.name,
                localizedName: this.state.localizedName,
                firstDestination: this.state.firstDestination,
                secondDestination: this.state.secondDestination,
                deliveryDuration: this.state.deliveryDuration,
            });
        }
    }
    @Bind()
    fillStateOfDirection(
        direction: IDirection,
        existState: IDirectionFormComponentState
    ) {
        existState.name = direction.getName();
        existState.localizedName = direction.getLocalizedName();
        existState.deliveryDuration = direction.getDeliveryDuration();

        const getDestination = (
            id: string,
            destinations: IDestination[]
        ): IDestination | undefined => {
            const result = destinations.filter((item: IDestination) => {
                return item.getId() === id;
            });

            return result[0];
        };

        if (direction.getVertices()[0] !== undefined) {
            existState.firstDestination = getDestination(
                direction.getVertices()[0],
                existState.destinations
            );
        }

        if (direction.getVertices()[1] !== undefined) {
            existState.secondDestination = getDestination(
                direction.getVertices()[1],
                existState.destinations
            );
        }
    }

    shouldComponentUpdate(
        nextProps: IDirectionFormComponentProps,
        nextState: IDirectionFormComponentState
    ) {
        if (
            !nextState.currentDirectionFirstInited &&
            nextProps.currentDirection !== undefined &&
            nextState.destinationInitited
        ) {
            this.fillStateOfDirection(nextProps.currentDirection, nextState);
            nextState.currentDirectionFirstInited = true;
        }

        return true;
    }

    render() {
        const isDetailPage = this.props.currentDirection ? true : false;

        return (
            <>
                <ContentLoaderComponent<ICountableResponse<IDestination[]>>
                    contentRequest={this.destinationsRequest}
                    resultCallback={this.destinationRequestResult}
                >
                    <form
                        className="card mt-2 p-2"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                        }}
                    >
                        {isDetailPage
                            ? <></>
                            : (<div className="row m-1"><label className={"col-lg-5"}><b>Добавить
                                направление</b></label></div>)
                        }

                        <div className="row m-1">
                            <label className={isDetailPage ? "col-lg-4" : "col-lg-5"}>Название направления</label>
                            <InputText
                                className={isDetailPage ? "col-lg-4" : "col-lg-6"}
                                value={this.state.name}
                                placeholder=""
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({name: e.target.value})
                                }
                            />
                        </div>
                        <div className="row m-1">
                            <label className={isDetailPage ? "col-lg-4" : "col-lg-5"}/>
                            <MultiLanguageTextInputComponent
                                className={`p-0 ${isDetailPage ? "col-lg-4" : "col-lg-6"}`}
                                initLocalizedFields={this.state.localizedName}
                                ref={el => {
                                    this.multiLangInputRef = el;
                                }}
                                changeLangValueCallback={localizedFields => {
                                    this.setState({localizedName: localizedFields});
                                }}
                            />
                        </div>
                        <div className="row m-1">
                            <label className={isDetailPage ? "col-lg-4" : "col-lg-5"}>Время доставки</label>
                            <InputText
                                className={isDetailPage ? "col-lg-4" : "col-lg-6"}
                                value={this.state.deliveryDuration}
                                keyfilter="int"
                                placeholder=""
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({deliveryDuration: Number.parseInt(e.target.value)})
                                }
                            />
                        </div>
                        <div className="row m-1">
                            <label className={isDetailPage ? "col-lg-4" : "col-lg-5"}>Первая точка</label>
                            <Dropdown
                                className={isDetailPage ? "col-lg-4" : "col-lg-6"}
                                value={this.state.firstDestination}
                                options={this.state.destinations}
                                onChange={this.firstDestinationChanged}
                                placeholder="Место назначения"
                                dataKey="id"
                                optionLabel="title"
                            />
                        </div>
                        <div className="row m-1">
                            <label className={isDetailPage ? "col-lg-4" : "col-lg-5"}>Вторая точка</label>
                            <Dropdown
                                className={isDetailPage ? "col-lg-4" : "col-lg-6"}
                                value={this.state.secondDestination}
                                options={this.state.destinations}
                                onChange={this.secondDestinationChanged}
                                placeholder="Место назначения"
                                dataKey="id"
                                optionLabel="title"
                            />
                        </div>

                        <div className="row m-1 ml-2">
                            <Button
                                label={isDetailPage ? "Сохранить" : "Добавить"}
                                onClick={() => {
                                    this.saveButtonHandle();
                                }}
                            />
                        </div>
                    </form>
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
    DirectionFormComponent
);

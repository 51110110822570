import React from "react";
import WithdrawalListComponent from "./WithdrawalListComponent";
import WithdrawalFilterComponent from "./WithdrawalFilterComponent";
import {WithdrawalFilterOptions} from "./partials/WithdrawalFilterOptions";
import {Bind} from "@shift-mono/common";

interface IWithdrawalComponentProps {
}

interface IWithdrawalComponentState {
    currentFilterParam: WithdrawalFilterOptions
}

export default class WithdrawalComponent extends React.Component<IWithdrawalComponentProps, IWithdrawalComponentState> {
    private listRef: any = undefined;
    private defaultFilterParam = WithdrawalFilterOptions.IN_PROGRESS;

    constructor(props: IWithdrawalComponentProps) {
        super(props);

        this.state = {
            currentFilterParam: this.defaultFilterParam,
        }
    }

    @Bind()
    filterComponentHandler(filterParam: WithdrawalFilterOptions) {
        this.setState({
            currentFilterParam: filterParam
        });
        this.reloadListContent();
    }

    @Bind()
    reloadListContent() {
        if (this.listRef && this.listRef.reloadContent) {
            this.listRef.reloadContent();
        }
    }

    render() {
        return (<div className="row">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-12">
                        <h3>Заявки на списание средств</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <WithdrawalListComponent
                            filterParams={this.state.currentFilterParam}
                            ref={(e) => {
                                this.listRef = e;
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <WithdrawalFilterComponent
                            callbackHandler={this.filterComponentHandler}
                            defaultFilterOption={this.state.currentFilterParam}
                        />
                    </div>
                </div>

            </div>
        </div>)
    }
}
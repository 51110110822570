"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOrderStatusTitle = exports.OrderStatusEnv = void 0;

var models_1 = require("../models");

var OrderStatusEnv;

(function (OrderStatusEnv) {
  OrderStatusEnv[OrderStatusEnv["ADMIN"] = 0] = "ADMIN";
  OrderStatusEnv[OrderStatusEnv["B2B"] = 1] = "B2B";
})(OrderStatusEnv = exports.OrderStatusEnv || (exports.OrderStatusEnv = {}));

exports.getOrderStatusTitle = function (status, statusEnv) {
  if (statusEnv === void 0) {
    statusEnv = OrderStatusEnv.ADMIN;
  }

  switch (statusEnv) {
    case OrderStatusEnv.ADMIN:
      return adminStatusTitle(status);

    case OrderStatusEnv.B2B:
      return b2bStatusTitle(status);
  }
};

var adminStatusTitle = function adminStatusTitle(status) {
  switch (status) {
    case models_1.OrderStatus.New:
      return "Новый";

    case models_1.OrderStatus.Pending:
      return "Ожидание";

    case models_1.OrderStatus.Processing:
      return "В процессе";

    case models_1.OrderStatus.Payed:
      return "Оплачен";

    case models_1.OrderStatus.OnAgreement:
      return "На рассмотрении";

    case models_1.OrderStatus.Confirmed:
      return "Подтвержден";

    case models_1.OrderStatus.InProgress:
      return "В пути";

    case models_1.OrderStatus.Complete:
      return "Завершен";

    case models_1.OrderStatus.Cancelled:
      return "Отменён";

    case models_1.OrderStatus.WaitingDelivery:
      return "Ожидает вручения";

    case models_1.OrderStatus.CourierDelivery:
      return "Ожидает передачи в транспортную компанию";

    case models_1.OrderStatus.TransferredDelivery:
      return "Ожидает поступления";

    case models_1.OrderStatus.Issued:
      return "Багаж выдан";

    case models_1.OrderStatus.None:
      return "Нет данных";
  }
};

var b2bStatusTitle = function b2bStatusTitle(status) {
  switch (status) {
    case models_1.OrderStatus.New:
      return "Не оплачен";

    case models_1.OrderStatus.Pending:
      return "Ожидание";

    case models_1.OrderStatus.Processing:
      return "Багаж в пути";

    case models_1.OrderStatus.Payed:
      return "Оплачен";

    case models_1.OrderStatus.OnAgreement:
      return "На рассмотрении";

    case models_1.OrderStatus.Confirmed:
      return "Подтвержден";

    case models_1.OrderStatus.InProgress:
      return "В пути";

    case models_1.OrderStatus.Complete:
      return "Завершен";

    case models_1.OrderStatus.Cancelled:
      return "Отменён";

    case models_1.OrderStatus.WaitingDelivery:
      return "Багаж принят";

    case models_1.OrderStatus.CourierDelivery:
      return "Ожидает передачи в транспортную компанию";

    case models_1.OrderStatus.TransferredDelivery:
      return "Ожидает поступления";

    case models_1.OrderStatus.Issued:
      return "Багаж выдан";

    case models_1.OrderStatus.None:
      return "Нет данных";
  }
};
import React from "react";
import {getAccessToken} from "../../../helpers/RequestHelpers";
import {connect} from "react-redux";
import {Growl} from "primereact/growl";
import ContentLoaderComponent from "../../common/ContentLoaderComponent";
import BackButtonComponent from "../../common/BackButtonComponent";
import DirectionFormComponent, {
    IDirectionFormData
} from "./form_partials/DirectionFormComponent";
import {
    AuthorizedRequestData,
    requestDirectionById,
    DirectionRequestData,
    putDirectionRequest,
    IDirection, Bind
} from "@shift-mono/common";

interface DirectionDetailComponentProps {
    getToken: () => Promise<string>;
    match: any;
}

interface DirectionDetailComponentState {
    currentDirection: IDirection | undefined;
}

class DirectionDetailComponent extends React.Component<DirectionDetailComponentProps,
    DirectionDetailComponentState> {
    private growl: any = undefined;

    constructor(props: DirectionDetailComponentProps) {
        super(props);

        this.state = {
            currentDirection: undefined
        };
    }
    @Bind()
    async directionRequest(): Promise<IDirection | undefined> {
        const token = await this.props.getToken();
        const request_data = new AuthorizedRequestData(token);
        const id = this.props.match.params.id;

        return (await requestDirectionById(id, request_data)).getData();
    }
    @Bind()
    directionRequestResult(direction: IDirection | undefined) {
        this.setState({
            currentDirection: direction
        });
    }

    validateForm(formData: IDirectionFormData): boolean {
        if (formData.name.trim() === "") {
            return false;
        }
        if (formData.firstDestination === undefined) {
            return false;
        }
        if (formData.secondDestination === undefined) {
            return false;
        }
        return true;
    }
    @Bind()
    async sendDirection(formData: IDirectionFormData) {
        try {
            const token = await this.props.getToken();
            const name = formData.name;
            const localizedName = formData.localizedName;
            const firstDirection = formData.firstDestination;
            const secondDirection = formData.secondDestination;
            const deliveryDuration = formData.deliveryDuration;
            const currentDirection = this.state.currentDirection;

            if (!this.validateForm(formData) || !currentDirection) {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка обновления"
                });
                return;
            }

            const requst_data = new DirectionRequestData(
                {
                    accessToken: token,
                    name: name,
                    localizedName: localizedName,
                    vertices: [firstDirection!.getId(), secondDirection!.getId()],
                    deliveryDuration: deliveryDuration,

                    directionId: currentDirection.getId()
                });
            const direction_added = await putDirectionRequest(requst_data);
            if (direction_added) {
                this.growl.show({
                    severity: "success",
                    summary: "Успешно",
                    detail: "Направление обновлено"
                });
            } else {
                this.growl.show({
                    severity: "error",
                    summary: "Ошибка",
                    detail: "Ошибка обновления"
                });
            }
        } catch (err) {
            this.growl.show({
                severity: "error",
                summary: "Ошибка",
                detail: "Ошибка обновления"
            });
        }
    }

    render() {
        return (
            <>
                <Growl ref={el => (this.growl = el)}/>

                <ContentLoaderComponent<IDirection | undefined>
                    contentRequest={this.directionRequest}
                    resultCallback={this.directionRequestResult}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>
                                <BackButtonComponent/> Информация о направлении
                            </h3>
                        </div>
                    </div>

                    <DirectionFormComponent
                        currentDirection={this.state.currentDirection}
                        saveCallback={(formData: IDirectionFormData) => {
                            this.sendDirection(formData);
                        }}
                    />
                </ContentLoaderComponent>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getToken: () => dispatch(getAccessToken())
    };
};

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
    DirectionDetailComponent
);
